import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['descriptionBannerEmployeeAdministration', 'actionType']

  connect () {
    if (this.hasActionTypeTarget) {
      this.#changeDescriptionBannerEmployeeAdministration()
    }
  }

  changeActionType (_event) {
    this.#changeDescriptionBannerEmployeeAdministration()
  }

  #changeDescriptionBannerEmployeeAdministration () {
    const actionTypeValue = this.actionTypeTargetSelect.value

    if (actionTypeValue === 'employee') {
      this.#showDescriptionBannerEmployeeAdministration()
    } else {
      this.#hiddenDescriptionBannerEmployeeAdministration()
    }
  }

  #showDescriptionBannerEmployeeAdministration () {
    this.descriptionBannerEmployeeAdministrationTarget.classList.remove('d-none')
    this.descriptionBannerEmployeeAdministrationTextarea.removeAttribute('disabled')
  }

  #hiddenDescriptionBannerEmployeeAdministration () {
    this.descriptionBannerEmployeeAdministrationTarget.classList.add('d-none')
    this.descriptionBannerEmployeeAdministrationTextarea.setAttribute('disabled', 'true')
  }

  get descriptionBannerEmployeeAdministrationTextarea () {
    return this.descriptionBannerEmployeeAdministrationTarget.querySelector('textarea')
  }

  get actionTypeTargetSelect () {
    return this.actionTypeTarget.querySelector('select')
  }
}
