import jsxElem from 'jsx-no-react' // eslint-disable-line
import tizySpeaker from 'images/illustrations/tizy_speaker.svg'

function modalTitle () {
  return (
    <img src={tizySpeaker} />
  )
}

function modalBody (employeeEmail, employeeId, startDate) {
  return (
    <div>
      <div className='row'>
        <div className='col-12 text-center'>
          Le mail &quot;{employeeEmail}&quot; correspond à un ancien salarié de l&apos;entreprise.<br/>
          Vous pouvez directement mettre à jour ses informations et le réintégrer à vos effectifs.
        </div>
      </div>
      <div className="row align-items-center justify-content-center mt-20px">
        <a href={`/espace-entreprise/salaries/reintegrer-un-employe-etape-2?employee_id=${employeeId}&employment_start_date=${startDate}`} className='btn btn-primary btn-lg me-3'>Réintégrer le salarié</a>
        <a href='/espace-entreprise/salaries/new' className='btn btn-outline-cyan btn-lg'>Annuler</a>
      </div>
    </div>
  )
}

export { modalTitle, modalBody }
