import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'collapse']

  static values = {
    alwaysOpen: Boolean
  }

  closeOtherCollpase (e) {
    const ariaControls = e.currentTarget.getAttribute('aria-controls')
    const elements = this.collapseTargets.filter(item => item.getAttribute('id') !== ariaControls)

    if (!this.alwaysOpenValue) {
      elements.forEach(item => {
        $(item).collapse('hide')
      })
    }
  }
}
