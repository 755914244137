import { Controller } from 'stimulus'
import { errorElement, modalBody, modalTitle } from 'src/js/templates/subscription_steps/form.jsx'
import { renderAfterEnd } from 'helpers/jsx'

export default class extends Controller {
  static targets = ['dataTreatmentAgreementCheckbox', 'form', 'dataTreatmentAgreementSection', 'errorMessage', 'button']

  checkDataTreatmentAgreement (e) {
    if (!this.dataTreatmentAgreementCheckboxTarget.checked) {
      e.preventDefault()
      if (!this.hasErrorMessageTarget) {
        renderAfterEnd(errorElement(), this.dataTreatmentAgreementSectionTarget)
      }
    }
  }

  displayModal () {
    $('#universal-modal-lg-company-administration').find('.custom-title').html(modalTitle(this.dataTreatmentAgreementSectionTarget.dataset.contractTypeValue))
    $('#universal-modal-lg-company-administration').find('.custom-body').html(modalBody(this.dataTreatmentAgreementSectionTarget.dataset.contractTypeValue, this.dataTreatmentAgreementSectionTarget.dataset.agreementTypeDueValue))
    const modalElement = document.querySelector('#universal-modal-lg-company-administration')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.show()
  }
}
