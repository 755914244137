import { Controller } from 'stimulus'
import { deleteQuery } from 'helpers/requests'
export default class extends Controller {
  static targets = ['trigger']

  destroyRessource (event) {
    const url = event.params.url
    this.deleteRequest(url)
  }

  async deleteRequest (url) {
    try {
      const data = await deleteQuery(url)
      if (data.status === 'ok') {
        this.element.remove()
        toastr.success('Image supprimée')
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') { toastr.error('La suppression a échoué') }
    }
  }
}
