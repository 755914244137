import { Controller } from 'stimulus'
import { moment } from 'moment'

export default class extends Controller {
  connect () {
    this.form = document.querySelector('.new-company-administration-form')
    this.initSelect()
    this.initDatePicker()
  }

  initDatePicker () {
    $('.daterangepickerjs').daterangepicker({
      autoUpdateInput: false,
      autoApply: true,
      locale: {
        format: 'DD/MM/YYYY',
        cancelLabel: 'Clear'
      }
    })

    $('.daterangepickerjs').on(
      'apply.daterangepicker',
      function (ev, picker) {
        $(this).val(
          picker.startDate.format('DD/MM/YYYY') +
          ' - ' +
          picker.endDate.format('DD/MM/YYYY')
        )
        this.form.submit()
      }
    )

    $('.daterangepickerjs').on('focusout', function (e) {
      if (this.verifyDaterange(this.value)) {
        this.form.submit()
      } else {
        if (!($('#daterange-warning').length)) {
          $("<p class='text-danger mb-2' id='daterange-warning'><i class='fa fa-warning'></i> Le format de la date n'est pas valide.</p>").insertAfter('.new-company-administration-form')
        }
        return false
      }
    })

    $('.daterangepickerjs').on('keydown', function (e) {
      if (e.key === 'Enter') {
        if (this.verifyDaterange(this.value)) {
          this.form.submit()
        } else {
          if (!($('#daterange-warning').length)) {
            $("<p class='text-danger mb-2' id='daterange-warning'><i class='fa fa-warning'></i> Le format de la date n'est pas valide.</p>").insertAfter('.new-company-administration-form')
          }
          return false
        }
      }
    })

    $('.daterangepickerjs').on(
      'cancel.daterangepicker',
      function (ev, picker) {
        $(this).val('')
      }
    )
  }

  initSelect () {
    $('[data-select="select2-insuree"]').select2({
      minimumResultsForSearch: Infinity,
      width: '100%'
    })
    $('[data-select="select2-insuree"]').on('select2:select', function (e) {
      this.form.submit()
    })
  }

  verifyDaterange (daterange) {
    if (daterange.trim() === '') {
      return true
    }
    const dates = daterange.split(' - ')
    const verifyDate = (date) => {
      const splitDate = date.split('/')
      const dateToVerify = `${splitDate[2]}${splitDate[1]}${splitDate[0]}`
      return moment(dateToVerify, 'YYYYMMDD').isValid()
    }
    return dates.every(verifyDate)
  }
}
