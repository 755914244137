import { Controller } from 'stimulus'
import { patchQuery } from 'helpers/requests'
import { truncateString } from 'helpers/string'
import { renderBeforeEnd } from 'helpers/jsx'
import { collegeErrorMessage } from 'src/js/templates/company_administration/employees/professional_information.jsx'

export default class extends Controller {
  static targets = ['managerNameSection', 'managerSelectSection', 'collegeSelect', 'collegeSection', 'errorMessage', 'collegeForm']

  static values = {
    url: String,
    college: String
  }

  connect () {
    $('[data-select="search"]').on('select2:select', function (_) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
    $('[data-select="select2-college"]').on('select2:select', function (_) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  async updateManager (e) {
    try {
      const data = await patchQuery(this.urlValue, JSON.stringify({
        employee: {
          manager_ids: e.currentTarget.value
        }
      }))

      if (data.id) {
        this.displayManagerName(document.getElementById('select2-manager_ids-container').title)
        this.closeSelect()
        toastr.success('Mise à jour effectuée')
      } else {
        throw new Error('update failed')
      }
    } catch (e) {
      toastr.error('Mise à jour impossible')
      throw new Error(e)
    }
  }

  displayManagerName (name) {
    this.managerNameSectionTarget.classList.remove('d-none')
    this.managerNameSectionTarget.innerHTML = truncateString(name, 28)
  }

  closeSelect () {
    this.managerSelectSectionTarget.classList.add('d-none')
  }

  displaySelect () {
    if (this.managerSelectSectionTarget.classList.contains('d-none')) {
      this.managerSelectSectionTarget.classList.remove('d-none')
      this.managerNameSectionTarget.classList.add('d-none')
    }
  }

  checkCollegeBeforeSubmit (e) {
    e.preventDefault()
    if (this.collegeValue === this.collegeSelectTarget.value) {
      this.displayErrorMessage()
    } else if (this.collegeValue !== this.collegeSelectTarget.value) {
      this.collegeFormTarget.submit()
    }
  }

  checkSelectedCollege (e) {
    if (this.collegeValue === e.currentTarget.value) {
      this.displayErrorMessage()
    } else {
      this.removeErrorMessage()
    }
  }

  displayErrorMessage () {
    if (!this.hasErrorMessageTarget) {
      renderBeforeEnd(collegeErrorMessage(), this.collegeSectionTarget)
    }
  }

  removeErrorMessage () {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.remove()
    }
  }
}
