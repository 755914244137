import jsxElem from 'jsx-no-react' // eslint-disable-line
import deleteIcon from 'images/icons/icone_supprimer.svg'

function fileInfos (fileName, index) {
  return (
    <li className='me-3' id={`file_${index}`} data-shared--form--files-list-target='fileName'>
      <i className='far fa-file-alt me-2'></i>
      {fileName}
      <img className='mb-1 ms-2 cursor-pointer' src={deleteIcon} height='12'></img>
    </li>
  )
}

export { fileInfos }
