import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['sidebar', 'content']
  static values = {
    sidebarId: String
  }

  connect () {
    if (this.sidebarIdValue.length > 0) this.activateLinkInSidebar()
  }

  toggleSidebar (_e) {
    this.sidebarTarget.classList.toggle('Sidebar--close')
    this.contentTarget.classList.toggle('Content--full-width')
  }

  activateLinkInSidebar () {
    if (this.sidebarIdValue.length === 0) return

    $('#' + this.sidebarIdValue + '.Sidebar__Link').addClass('Sidebar__Link--active')
    $('#' + this.sidebarIdValue + '.Sidebar__Link').parents('li').addClass('open')
    $('#' + this.sidebarIdValue + '.Sidebar__Link').closest('.collapse').addClass('show')
    $('#' + this.sidebarIdValue + '.Sidebar__Link .Sidebar__Link__Text.Sidebar__Link__SubMenu').removeClass('Sidebar__Link__Text').addClass('fw-bold')
  }
}
