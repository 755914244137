import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.select2()
    this.highlight()
  }

  select2 () {
    $('[data-select="select2-highlight"]').select2({
      placeholder: '',
      width: '100%',
      allowClear: true,
      language: {
        noResults: function (params) {
          return 'Aucun résultat'
        }
      }
    })
  }

  highlight () {
    const inputs = document.querySelectorAll('.form-control')
    const select2single = document.querySelectorAll('.select2-selection.select2-selection--single')
    const select2multiple = document.querySelectorAll('.select2-selection.select2-selection--multiple')
    inputs.forEach(input => {
      if (input.value) {
        input.classList.add('highlight_filter_input')
      }
    })
    select2single.forEach(input => {
      if (input.querySelector('.select2-selection__rendered').title) {
        input.classList.add('highlight_filter_input')
      }
    })
    select2multiple.forEach(input => {
      if (input.querySelector('.select2-selection__choice')?.title) {
        input.classList.add('highlight_filter_input')
      }
    })
  }
}
