import { Controller } from 'stimulus'
import { postQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['file', 'description', 'fileRequiredError', 'iban', 'ibanRequiredError', 'bic', 'bicRequiredError', 'bankAccountList', 'account', 'accountContainer', 'collapse', 'submitErrors']
  static values = {
    creationUrl: String,
    type: String
  }

  checkRib () {
    const file = this.fileTarget.files[0]
    if (file) {
      this.fileRequiredErrorTarget.classList.add('d-none')
    } else {
      this.fileRequiredErrorTarget.classList.remove('d-none')
    }
  }

  checkIban () {
    const iban = this.ibanTarget.value
    if (iban) {
      this.ibanRequiredErrorTarget.classList.add('d-none')
    } else {
      this.ibanRequiredErrorTarget.classList.remove('d-none')
    }
  }

  checkBic () {
    const bic = this.bicTarget.value
    if (bic) {
      this.bicRequiredErrorTarget.classList.add('d-none')
    } else {
      this.bicRequiredErrorTarget.classList.remove('d-none')
    }
  }

  async submit (e) {
    e.preventDefault()
    const file = this.fileTarget.files[0]
    if (!file) { return this.fileRequiredErrorTarget.classList.remove('d-none') }
    const iban = this.ibanTarget.value
    if (!iban) { return this.ibanRequiredErrorTarget.classList.remove('d-none') }
    const bic = this.bicTarget.value
    if (!bic) { return this.bicRequiredErrorTarget.classList.remove('d-none') }

    if (!this.fileTarget.checkValidity() || !this.ibanTarget.checkValidity() || !this.bicTarget.checkValidity()) {
      return
    }

    const formData = new FormData()
    formData.append('bank_account[rib]', file)
    formData.append('bank_account[description]', this.descriptionTarget.value)
    formData.append('bank_account[iban]', iban)
    formData.append('bank_account[bic_swift]', bic)
    formData.append('bank_account[type]', this.typeValue)

    try {
      const data = await postQuery(this.creationUrlValue, formData, true)
      if (data.status === 201) {
        const response = await data.json()

        this.updateComponentView(response)
        this.clearTargets()
      } else {
        const errors = await data.json()
        this.submitErrorsTarget.innerHTML = ''
        Object.keys(errors).forEach(key => {
          this.submitErrorsTarget.innerHTML += errors[key].map(el => `${el} `)
          this.submitErrorsTarget.innerHTML += '<br>'
        })
      }
    } catch (e) {
    }
  }

  updateComponentView (response) {
    const clone = this.accountContainerTarget.cloneNode(true)
    let html = clone.innerHTML
    html = html.replace(/000/g, response.id).replace(/description - IBAN/g, this.accountPresenter(response))

    this.bankAccountListTarget.insertAdjacentHTML('afterbegin', html)
    this.accountTarget.firstElementChild.click()
  }

  accountPresenter (response) {
    if (response.description) {
      return `${response.description} - ${response.iban.match(/.{1,4}/g).join(' ')}`
    } else {
      return response.iban.match(/.{1,4}/g).join(' ')
    }
  }

  clearTargets () {
    this.submitErrorsTarget.innerHTML = ''
    this.descriptionTarget.value = ''
    this.ibanTarget.value = ''
    this.bicTarget.value = ''
    this.fileTarget.value = ''

    this.collapseTarget.click()
  }
}
