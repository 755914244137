import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['collapse']

  connect () {
    const pathname = window.location.pathname

    $('a[href="' + pathname + '"].Sidebar__Link').addClass('Sidebar__Link--active')
    $('a[href="' + pathname + '"].Sidebar__Link').parents('li').addClass('open')
    $('a[href="' + pathname + '"].Sidebar__Link').closest('.collapse').addClass('show')
    $('a[href="' + pathname + '"].Sidebar__Link').closest('.collapse').prev().removeClass('collapsed')
    $('a[href="' + pathname + '"].Sidebar__Link .Sidebar__Link__Text.Sidebar__Link__SubMenu').removeClass('Sidebar__Link__Text').addClass('fw-bold')
  }
}
