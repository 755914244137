import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tableHeading', 'tableRow']

  navigationTo (event) {
    const url = event.params.url
    if (!url) return

    const openInNewTab = event.currentTarget.dataset.openInNewTabValue === 'true'

    if (openInNewTab) {
      window.open(url, '_blank')
    } else {
      window.location.href = url
    }
  }
}
