import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    if (window.location.href.indexOf('#onboarding_tour') !== -1 && window.innerWidth > 992) {
      const modalElement = document.querySelector('#modal-onboarding-contract-tour-end')
      const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
      modal.show()
    }
  }

  async handleClickCloseModal (e) {
    const modalElement = document.querySelector('#modal-onboarding-contract-tour-end')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.hide()
  }
}
