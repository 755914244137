import { Controller } from 'stimulus'

export default class extends Controller {
  loading (e) {
    e.currentTarget.innerHTML = 'Chargement <i class="ms-2 fa-solid fa-spinner fa-spin-pulse"></i>'
    e.currentTarget.setAttribute('disabled', 'disabled')
    e.currentTarget.classList.add('disabled')
    e.currentTarget.closest('form').submit()
  }
}
