import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'
export default class extends Controller {
  static targets = ['submitButton']
  static values = { previousAffiliation: Boolean, previousPartner: Boolean, previousNumberChild: Number }

  connect () {
    this.initiatePreviousValues()
    this.checkSubmitButton()
  }

  clickCardSimple (e) {
    const cardClicked = e.currentTarget
    const inputClicked = cardClicked.children[0]
    const previousCardsChecked = document.querySelector('.CardRadio__Input:checked')
    const inputChildCounter = document.querySelector(`[for='${inputClicked.name}'] #counter`)

    try {
      if (inputClicked === previousCardsChecked) {
        if (!document.querySelector(`[for='${previousCardsChecked.name}'] #counter`)) {
          this.changeValueInput(inputClicked)
        }
      } else {
        this.removePreviousCardsChecked(previousCardsChecked)
        this.changeValueInput(inputClicked)
      }

      if (inputChildCounter) {
        inputChildCounter.classList.remove('d-none')
      }

      this.udpateNewPrice(inputClicked, inputChildCounter)
    } finally {
      // Always check submit button whether there is an error or not.
      this.checkSubmitButton()
    }
  }

  async udpateNewPrice (inputClicked, inputChildCounter) {
    const employeePriceSelector = document.querySelector(`[for='${inputClicked.name}'] [data-select='employeeCost']`)
    if (inputChildCounter && employeePriceSelector) {
      const response = await this.computeNewPrice(inputClicked)
      this.updateAccordeonRecapsText(response.employee_price, response.user_pro_price)
      return
    }

    const userProPriceSelector = document.querySelector(`[for='${inputClicked.name}'] [data-select='userProCost']`)
    if (employeePriceSelector && userProPriceSelector) {
      const employeePrice = employeePriceSelector.textContent
      const userProPrice = userProPriceSelector.textContent

      this.updateAccordeonRecapsText(employeePrice, userProPrice)
    }
  }

  changeValueInput (input) {
    if (input.value === 'true') {
      input.value = false
      input.checked = false
    } else {
      input.value = true
      input.checked = true
    }
  }

  removePreviousCardsChecked (previousCardsChecked) {
    if (previousCardsChecked) {
      this.changeValueInput(previousCardsChecked)
      const previousChildCounter = document.querySelector(`[for='${previousCardsChecked.name}'] #counter`)
      if (previousChildCounter) {
        previousChildCounter.classList.add('d-none')
        this.removeCounterDatas(previousCardsChecked)
        if (document.querySelector(`[for='${previousCardsChecked.name}'] [data-select='employeeCost']`)) {
          this.computeNewPrice(previousCardsChecked)
        }
      }
    }
  }

  checkSubmitButton () {
    const CardsChecked = document.querySelector('.CardRadio__Input:checked')
    if (CardsChecked) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  removeCounterDatas (previousCardsChecked) {
    document.querySelector(`[for='${previousCardsChecked.name}'] [data-select='labelFieldCounter']`).textContent = '1 enfant'
    document.querySelector(`[for='${previousCardsChecked.name}'] [data-select='inputFieldCounter']`).value = 1
  }

  async computeNewPrice (card) {
    const numberOfChild = this.getNumberOfChild(card)

    const response = await getQuery(`/espace-salarie/affiliations-collective/prix-cotisation-formule?cotisation_structure=${card.name}&number_of_child=${numberOfChild}`)

    this.updateCardPriceText(card, response)

    return response
  }

  getNumberOfChild (card) {
    const inputFieldChild = document.querySelector(`[for='${card.name}'] [data-select='inputFieldCounter']`)
    if (inputFieldChild) {
      return inputFieldChild.value
    } else {
      return 0
    }
  }

  updateCardPriceText (card, response) {
    document.querySelector(`[for='${card.name}'] [data-select='employeeCost']`).textContent = response.employee_price
    document.querySelector(`[for='${card.name}'] [data-select='userProCost']`).textContent = response.user_pro_price
  }

  initiatePreviousValues () {
    if (this.hasPreviousAffiliationValue && this.previousAffiliationValue) {
      if (this.hasPreviousPartnerValue && this.hasPreviousNumberChildValue) {
        const inputToInitialize = this.getInputToInitialize()
        this.changeValueInput(inputToInitialize)
        this.computeNewPrice(inputToInitialize)
        this.udpateNewPrice(inputToInitialize, document.querySelector(`[for='${inputToInitialize.name}'] #counter`))
      }
    }
  }

  getInputToInitialize () {
    if (this.conditionCotisationWithPartner()) {
      return document.querySelector('.CardRadio__Input[name="salary_and_partner"]')
    } else if (this.conditionCotisationWithChilds()) {
      this.initiateCounterChilds('salarie_and_child')
      return document.querySelector('.CardRadio__Input[name="salarie_and_child"]')
    } else if (this.conditionCotisationWithFamily()) {
      this.initiateCounterChilds('family')
      return document.querySelector('.CardRadio__Input[name="family"]')
    } else {
      return document.querySelector('.CardRadio__Input[name="salarie"]')
    }
  }

  conditionCotisationWithPartner () {
    return (this.previousPartnerValue && this.previousNumberChildValue === 0)
  }

  conditionCotisationWithChilds () {
    return (!this.previousPartnerValue && this.previousNumberChildValue > 0)
  }

  conditionCotisationWithFamily () {
    return (this.previousPartnerValue && this.previousNumberChildValue > 0)
  }

  initiateCounterChilds (choiceFormule) {
    document.querySelector(`[for='${choiceFormule}'] [data-select='labelFieldCounter']`).textContent = `${this.previousNumberChildValue} ${this.pluralizeChilds()} `
    document.querySelector(`[for='${choiceFormule}'] #counter`).classList.remove('d-none')
    document.querySelector(`[for='${choiceFormule}'] [data-select='inputFieldCounter']`).value = this.previousNumberChildValue
  }

  pluralizeChilds () {
    if (this.previousNumberChildValue > 1) {
      return 'enfants'
    } else {
      return 'enfant'
    }
  }

  updateAccordeonRecapsText (employeePrice, userProPrice) {
    const accordeonRecapEmployeeCostElements = document.querySelectorAll('.AccordeonRecap [data-select=\'employeeCost\']')
    accordeonRecapEmployeeCostElements.forEach((el) => {
      el.textContent = employeePrice
    })

    const accordeonRecapUserProCostElements = document.querySelectorAll('.AccordeonRecap [data-select=\'userProCost\']')
    accordeonRecapUserProCostElements.forEach((el) => {
      el.textContent = userProPrice
    })
  }
}
