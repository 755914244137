import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    name: String,
    openOnLoad: Boolean
  }

  connect () {
    if (this.openOnLoadValue) {
      const myModal = new bootstrap.Modal(`#${this.nameValue}`) // eslint-disable-line
      myModal.show()
    }
  }
}
