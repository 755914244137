import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  modal (e) {
    const modalElement = document.querySelector('#universal-modal-lg-company-administration')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement)  // eslint-disable-line
    modal.show()
  }

  cancel () {
    $('[data-check="checkbox"]').prop('checked', false)
    $('[data-check="radio"]').prop('checked', true)
  }
}
