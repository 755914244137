import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'

export default class extends Controller {
  static targets = ['calendar']

  static values = {
    licenseKey: String,
    eventsJson: Array,
    url: String
  }

  decodeLicenseKey () {
    return decodeURIComponent(escape(window.atob(this.licenseKeyValue)))
  }

  connect () {
    this.loadCalendar()
  }

  loadCalendar () {
    this.getInfo = (info) => {
      $.get(`${this.urlValue}/${info.event.id}`)
      info.el.style.borderColor = info.event.color
    }
    const calendar = this.employeeCalendar()
    calendar.setOption('locale', 'fr')
    calendar.render()
  }

  employeeCalendar () {
    return new Calendar(this.calendarTarget, {
      schedulerLicenseKey: `${this.decodeLicenseKey()}`,
      plugins: [dayGridPlugin],
      timeZone: 'locale',
      initialView: 'dayGridMonth',
      firstDay: 1,
      headerToolbar: {
        left: '',
        center: 'title',
        right: 'prev,next'
      },
      buttonText: {
        today: 'Aujourd\'hui'
      },
      eventClick: this.getInfo,
      eventDisplay: 'block',
      displayEventTime: false,
      events: this.eventsJsonValue,
      eventDidMount: function (info) {
        $(info.el).tooltip({ title: info.event.title })
        $(`${info.el}.start-afternoon-50percent`).parents('.fc-daygrid-day-events').css('margin-left', '50%')
        $(`${info.el}.end-morning-50percent`).parents('.fc-daygrid-day-events').css('margin-right', '50%')
      }
    })
  }
}
