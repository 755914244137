import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['question', 'response']

  connect (event) {
    const [question] = this.questionTargets.filter(item => item.dataset.id)
    const [response] = this.responseTargets.filter(item => item.dataset.id)
    question.querySelector('img').classList.toggle('open')
    response.classList.toggle('d-none')
  }

  toggleQuestion (event) {
    const [question] = this.questionTargets.filter(item => item.dataset.id === event.currentTarget.dataset.id)
    const [response] = this.responseTargets.filter(item => item.dataset.id === event.currentTarget.dataset.id)

    question.querySelector('img').classList.toggle('open')

    response.classList.toggle('d-none')
  }
}
