import { Controller } from 'stimulus'
import { putQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['toggle']

  static values = { url: String }

  async toggleFeature ({ currentTarget: { checked, value } }) {
    try {
      this.toggleTarget.disabled = true
      await putQuery(this.urlValue, JSON.stringify({ [value]: checked }))
      this.toggleTarget.disabled = false
    } catch (e) {
      this.toggleTarget.disabled = false
      this.toggleTarget.checked = !this.toggleTarget.checked
      throw new Error(e)
    }
  }
}
