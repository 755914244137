import { Controller } from 'stimulus'
// import { dataTable, load } from "@google-web-components/google-chart/loader.js"
import moment from 'moment'
import { PIE_OPTIONS, COLUMN_OPTIONS, BAR_OPTIONS } from './consumption_options'

export default class extends Controller {
  static targets = ['pieChart', 'columnChart', 'restToPayChart', 'userProHiddenField', 'annualContributionsVsReimbursements', 'monthlyContributionsVsReimbursements', 'internalContractHiddenField', 'pieChartBeneficiaries', 'pieChartBaseVsOptions']

  connect () {
    google.charts.load('current', { packages: ['corechart'] })
    // google take a reference to the function as an argument so i need
    // to bind 'this' (here the stimulus controller) to the function
    // https://discuss.hotwired.dev/t/integrating-google-charts/745
    google.charts.setOnLoadCallback(this.drawPieChart.bind(this))
    google.charts.setOnLoadCallback(this.drawColumnChart.bind(this))
    google.charts.setOnLoadCallback(this.drawBarCharts.bind(this))
    google.charts.setOnLoadCallback(this.drawPieChartBeneficiaries.bind(this))
    google.charts.setOnLoadCallback(this.drawPieChartBaseVsOptions.bind(this))
    google.charts.setOnLoadCallback(this.drawBarChartsAnnualContributionsVsReimbursements.bind(this))
    google.charts.setOnLoadCallback(this.drawColumnChartMonthlyContributionsVsReimbursements.bind(this))
    window.addEventListener('resize', () => {
      this.drawPieChart()
      this.drawPieChartBeneficiaries()
      this.drawColumnChart()
      this.drawBarCharts()
      this.drawPieChartBaseVsOptions()
      this.drawBarChartsAnnualContributionsVsReimbursements()
      this.drawColumnChartMonthlyContributionsVsReimbursements()
    })
    this.initializeJQueryFunctions()
  }

  drawPieChart () {
    if (!this.hasPieChartTarget) {
      return
    }
    const data = JSON.parse(this.pieChartTarget.dataset.categories)
    if (data.length < 2) {
      return
    }
    const pieData = new google.visualization.arrayToDataTable(data) // eslint-disable-line
    const formatter = this.formatter('€')
    formatter.format(pieData, 1)
    const pieChart = new google.visualization.PieChart(this.pieChartTarget)
    pieChart.draw(pieData, PIE_OPTIONS)
  }

  drawPieChartBaseVsOptions () {
    if (!this.hasPieChartBaseVsOptionsTarget) {
      return
    }
    const data = JSON.parse(this.pieChartBaseVsOptionsTarget.dataset.categories)
    const pieData = new google.visualization.arrayToDataTable(data) // eslint-disable-line
    const formatter = this.formatter('€')
    formatter.format(pieData, 1)
    const pieChart = new google.visualization.PieChart(this.pieChartBaseVsOptionsTarget)
    pieChart.draw(pieData, PIE_OPTIONS)
  }

  drawPieChartBeneficiaries () {
    if (!this.hasPieChartBeneficiariesTarget) {
      return
    }
    const data = JSON.parse(this.pieChartBeneficiariesTarget.dataset.categories)
    const pieData = new google.visualization.arrayToDataTable(data) // eslint-disable-line
    const formatter = this.formatter('€')
    formatter.format(pieData, 1)
    const pieChart = new google.visualization.PieChart(this.pieChartBeneficiariesTarget)
    pieChart.draw(pieData, PIE_OPTIONS)
  }

  drawColumnChart () {
    if (!this.hasColumnChartTarget) {
      return
    }
    const data = JSON.parse(this.columnChartTarget.dataset.categories)
    // function to check if every month data is equal to zero, if yes we don't draw the chart
    if (this.verifyMonthlyData(data)) {
      return
    }
    const columnData = google.visualization.arrayToDataTable(data)
    const columnChart = new google.visualization.ColumnChart(
      this.columnChartTarget
    )
    const view = new google.visualization.DataView(columnData)
    view.setColumns([
      0,
      1,
      2,
      3,
      { // Computes a "grand total" by summing the values from columns 1 and 2
        calc: (table, row) => `${Math.round(table.getValue(row, 1) + table.getValue(row, 2))} €`,
        type: 'string',
        role: 'annotation'
      }])
    const formatter = this.formatter(' €')
    formatter.format(columnData, 1)
    formatter.format(columnData, 2)
    columnChart.draw(view, COLUMN_OPTIONS)
  }

  drawBarCharts () {
    const healthCaresRestToPayData = JSON.parse(
      this.restToPayChartTarget.dataset.categories
    )
    if (healthCaresRestToPayData.length < 1) {
      return
    }

    healthCaresRestToPayData.forEach((data, index) => {
      // insert here otherwise it is not recognized as a JS object
      data[0].push({ role: 'style' })
      const columnData = google.visualization.arrayToDataTable(data)
      const columnChart = new google.visualization.ColumnChart(
        document.getElementById(`chart_${index}`)
      )
      const view = new google.visualization.DataView(columnData)

      view.setColumns([0, 1,
        {
          calc: 'stringify',
          sourceColumn: 1,
          type: 'string',
          role: 'annotation'
        }, 2
      ])
      const formatter = this.formatter('€')
      formatter.format(columnData, 0)
      formatter.format(columnData, 1)
      columnChart.draw(view, BAR_OPTIONS)
    })
  }

  drawBarChartsAnnualContributionsVsReimbursements () {
    if (!this.hasAnnualContributionsVsReimbursementsTarget) {
      return
    }
    const data = JSON.parse(this.annualContributionsVsReimbursementsTarget.dataset.categories)
    if (data.length < 1) {
      return
    }
    const columnData = new google.visualization.arrayToDataTable(data) // eslint-disable-line
    const view = new google.visualization.DataView(columnData)
    view.setColumns([0, 1,
      {
        calc: 'stringify',
        sourceColumn: 1,
        type: 'string',
        role: 'annotation'
      }
    ])
    const formatter = this.formatter('€')
    formatter.format(columnData, 1)
    const columnChart = new google.visualization.ColumnChart(this.annualContributionsVsReimbursementsTarget)
    columnChart.draw(columnData, { ...BAR_OPTIONS, width: null, height: 300, isStacked: true })
  }

  drawColumnChartMonthlyContributionsVsReimbursements () {
    if (!this.hasMonthlyContributionsVsReimbursementsTarget) {
      return
    }
    const data = JSON.parse(this.monthlyContributionsVsReimbursementsTarget.dataset.categories)
    // function to check if every month data is equal to zero, if yes we don't draw the chart
    if (this.verifyMonthlyData(data)) {
      return
    }
    const columnData = google.visualization.arrayToDataTable(data)
    const columnChart = new google.visualization.ColumnChart(
      this.monthlyContributionsVsReimbursementsTarget
    )
    const view = new google.visualization.DataView(columnData)
    view.setColumns([
      0,
      1,
      2
    ])
    const formatter = this.formatter(' €')
    formatter.format(columnData, 1)
    formatter.format(columnData, 2)
    columnChart.draw(view, { ...COLUMN_OPTIONS, colors: ['#6D092A', '#E8A23B'], legend: { position: 'top', alignment: 'end' } })
  }

  formatter (suffix) {
    return new google.visualization.NumberFormat({
      suffix: ` ${suffix}`,
      decimalSymbol: ',',
      groupingSymbol: ' '
    })
  }

  verifyMonthlyData (data) {
    const lastElementIsZero = (currentArray) => currentArray[4] === 0
    const checkData = [...data]
    checkData.shift()
    return checkData.every(lastElementIsZero)
  }

  addDataAndSubmitForm (datePickerForm) {
    if ($('[data-select="select2-contract"]').val()) {
      $('[data-select="select2-contract"]').val().forEach(v => {
        datePickerForm.insertAdjacentHTML(
          'beforeend',
        `<input type="hidden" data-internal-contracts--consumption-target="internalContractHiddenField" name="internal_contract_ids[]" value=${v} />`
        )
      })
    }
    datePickerForm.submit()
  }

  initializeJQueryFunctions () {
    const that = this
    function verifyDaterange (daterange) {
      if (daterange.trim() === '') {
        return true
      }
      const dates = daterange.split(' - ')
      const verifyDate = (date) => {
        const splitDate = date.split('/')
        const dateToVerify = `${splitDate[2]}${splitDate[1]}${splitDate[0]}`
        return moment(dateToVerify, 'YYYYMMDD').isValid()
      }
      return dates.every(verifyDate)
    }

    const form = document.querySelector('.new-company-administration-form')
    $('[data-select="select2-contract"]').multiselect({
      enableFiltering: true,
      filterPlaceholder: 'Rechercher',
      includeSelectAllOption: true,
      selectAllText: 'Tous les contrats',
      includeFilterClearBtn: false,
      enableCaseInsensitiveFiltering: true,
      dropUp: false,
      nonSelectedText: 'Vos contrats',
      widthSynchronizationMode: 'always',
      buttonWidth: '100%',
      maxHeight: 200,
      nSelectedText: 'contrats',
      allSelectedText: 'Tous les contrats'
    })
    $('[data-select="select2-contract"]').on('change', function (e) {
      form.submit()
    })
    $(function () {
      const datePickerForm = document.querySelector('.date-picker-form')
      $('[data-select="select2-insuree"]').select2({
        minimumResultsForSearch: Infinity,
        width: '100%'
      })

      $('[data-select="select2-insuree"]').on('select2:select', function (e) {
        that.addDataAndSubmitForm(datePickerForm)
      })

      $('.daterangepickerjs').daterangepicker({
        autoUpdateInput: false,
        autoApply: true,
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Clear'
        }
      })

      $('.daterangepickerjs').on(
        'apply.daterangepicker',
        function (ev, picker) {
          $(this).val(
            picker.startDate.format('DD/MM/YYYY') +
              ' - ' +
              picker.endDate.format('DD/MM/YYYY')
          )
          that.addDataAndSubmitForm(datePickerForm)
        }
      )

      $('.daterangepickerjs').on('focusout', function (e) {
        if (verifyDaterange(this.value)) {
          that.addDataAndSubmitForm(datePickerForm)
        } else {
          if (!($('#daterange-warning').length)) {
            $("<p class='text-danger mb-2' id='daterange-warning'><i class='fa fa-warning'></i> Le format de la date n'est pas valide.</p>").insertAfter('.new-company-administration-form')
          }
          return false
        }
      })

      $('.daterangepickerjs').on('keydown', function (e) {
        if (e.key === 'Enter') {
          if (verifyDaterange(this.value)) {
            that.addDataAndSubmitForm(datePickerForm)
          } else {
            if (!($('#daterange-warning').length)) {
              $("<p class='text-danger mb-2' id='daterange-warning'><i class='fa fa-warning'></i> Le format de la date n'est pas valide.</p>").insertAfter('.new-company-administration-form')
            }
            return false
          }
        }
      })

      $('.daterangepickerjs').on(
        'cancel.daterangepicker',
        function (ev, picker) {
          $(this).val('')
        }
      )
    })
  }
}
