import { Controller } from 'stimulus'
import { copyInClipboard } from 'helpers/copyInClipboard'

export default class extends Controller {
  static targets = ['inputCopy', 'label', 'labelTarget']

  copyLink () {
    copyInClipboard(this.inputCopyTarget)
    this.labelTarget.title = 'Lien copié avec succès !'
    this.labelTarget.innerText = 'Lien copié'

    setTimeout(() => {
      this.labelTarget.title = 'Copier le lien dans le presse-papier'
      this.labelTarget.innerText = 'Copier'
    }, 3000)
  }
}
