import { Controller } from 'stimulus'
import Highcharts from 'highcharts/highmaps.js'

export default class extends Controller {
  static values = {
    containerId: String,
    yAxisLabel: String,
    series: Array,
    legendBottom: Boolean,
    colors: Array
  }

  generateLegend () {
    if (this.legendBottomValue) {
      return {
        ...this.defaultLabel(),
        verticalAlign: 'bottom',
        layout: 'horizontal'
      }
    } else {
      return {
        ...this.defaultLabel(),
        align: 'right',
        verticalAlign: 'center',
        layout: 'vertical'
      }
    }
  }

  defaultLabel () {
    const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    return {
      labelFormatter: function () {
        return `<p class="mb-1 mt-0 fw-normal color-dark-purple">${this.name}</p> <span class="text-16">${formatNumber(Math.round(this.y))} ${this.unit}</span>`
      },
      itemStyle: {
        fontFamily: 'satoshi'
      },
      padding: 15,
      useHTML: true,
      itemMarginBottom: 10,
      symbolHeight: 5,
      symbolPadding: 10,
      squareSymbol: false,
      symbolWidth: 20
    }
  }

  connect () {
    Highcharts.chart(this.containerIdValue, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      colors: this.colorsValue,
      legend: this.generateLegend(),
      title: {
        text: ''
      },
      tooltip: {
        style: {
          fontFamily: 'satoshi'
        },
        pointFormat: '{point.percentage:.0f}%'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{
        colorByPoint: true,
        data: this.seriesValue
      }],
      credits: {
        enabled: false
      }
    })
  }
}
