import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['badgeNew', 'badgeActual', 'employeePrice', 'userProPrice', 'insureesRecap', 'buttonSubmit', 'inputChilds', 'checkBoxPartner', 'checkBoxChilds']
  static values = { affiliationId: String, numberOfChildActive: Number }

  connect () {
  }

  async handleClickPartnerCard (event) {
    try {
      const newPriceHash = await getQuery(this.getUrl())
      if (this.hasEmployeePriceTarget) {
        this.showNewPrice(newPriceHash)
      }
      this.insureesRecapTarget.textContent = newPriceHash.insurees_recap
      this.handleButtonSubmit()
    } catch (e) {
      throw new Error(e)
    }
  }

  async handleClickChildsCard (event) {
    if (event.currentTarget.checked) {
      this.inputChildsTarget.classList.remove('d-none')
      this.initiateCounterDatas()
    } else {
      this.inputChildsTarget.classList.add('d-none')
      this.removeCounterDatas()
    }
    try {
      const newPriceHash = await getQuery(this.getUrl())
      if (this.hasEmployeePriceTarget) {
        this.showNewPrice(newPriceHash)
      }
      this.insureesRecapTarget.textContent = newPriceHash.insurees_recap
      this.handleButtonSubmit()
    } catch (e) {
      throw new Error(e)
    }
  }

  async handleClickChildCounter (event) {
    try {
      const newPriceHash = await getQuery(this.getUrl())
      if (this.hasEmployeePriceTarget) {
        this.showNewPrice(newPriceHash)
      }
      this.insureesRecapTarget.textContent = newPriceHash.insurees_recap
      this.handleButtonSubmit()
    } catch (e) {
      throw new Error(e)
    }
  }

  getPartnerParamsValue () {
    if (this.hasCheckBoxPartnerTarget) {
      return this.checkBoxPartnerTarget.checked
    } else {
      return true
    }
  }

  getChildsParamsValue () {
    if (this.checkBoxChildsTarget.checked) {
      return parseInt(document.querySelector('[data-select="inputFieldCounter"]').value)
    } else {
      return 0
    }
  }

  getParams () {
    return `partner=${this.getPartnerParamsValue()}&childs=${this.getChildsParamsValue() + this.numberOfChildActiveValue}`
  }

  getUrl () {
    return `/espace-salarie/mon-contrat-sante/${this.affiliationIdValue}/health_mutual_insurees/nouveau-prix-addition-assuree?${this.getParams()}`
  }

  removeCounterDatas () {
    document.querySelector('[data-select="labelFieldCounter"]').textContent = '1 enfant'
    document.querySelector('[data-select="inputFieldCounter"]').value = 0
  }

  initiateCounterDatas () {
    document.querySelector('[data-select="labelFieldCounter"]').textContent = '1 enfant'
    document.querySelector('[data-select="inputFieldCounter"]').value = 1
  }

  showNewPrice (newPriceHash) {
    this.employeePriceTarget.textContent = newPriceHash.employee_price
    this.userProPriceTarget.textContent = newPriceHash.user_pro_price

    if (document.querySelectorAll('input[type=checkbox]:checked').length === 0) {
      this.badgeNewTarget.classList.add('d-none')
      this.badgeActualTarget.classList.remove('d-none')
    } else {
      this.badgeActualTarget.classList.add('d-none')
      this.badgeNewTarget.classList.remove('d-none')
    }
  }

  handleButtonSubmit () {
    if (document.querySelectorAll('input[type=checkbox]:checked').length === 0) {
      this.buttonSubmitTarget.classList.add('d-none')
    } else {
      this.buttonSubmitTarget.classList.remove('d-none')
    }
  }
}
