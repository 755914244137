import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  statusChanged () {
    this.formTarget.submit()
  }

  dateScopeChanged () {
    this.formTarget.submit()
  }
}
