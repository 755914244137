import { Controller } from 'stimulus'

export default class extends Controller {
  approved (event) {
    const id = event.params.id
    document.getElementById(id).value = 'approved'
  }

  rejected (event) {
    const id = event.params.id
    document.getElementById(id).value = 'refused'
  }
}
