import { Controller } from 'stimulus'
import { getQuery } from '../../../../javascript/helpers/requests'

export default class extends Controller {
  static targets = ['totalPrice', 'additionalPrice', 'card']
  static values = {
    contractId: Number,
    contractPrice: Number
  }

  connect () {
    const birthDateInput = document.getElementById('insuree-birth-date')
    birthDateInput.addEventListener('change', (e) => {
      const birthDate = e.target.value
      this.getPrice(birthDate)
    })
  }

  async getPrice (birthDate) {
    const url = `/espace-personnel/mutuelle-sante-tns/contrats/${this.contractIdValue}/price_for_additional_beneficiary/?birth_date=${birthDate}`
    const response = await getQuery(url)
    this.updatePrice(response.price)
  }

  updatePrice (price) {
    this.totalPriceTarget.innerText = Math.round((this.contractPriceValue + price) * 100) / 100
    this.additionalPriceTarget.innerText = price
    this.cardTarget.classList.remove('d-none')
  }
}
