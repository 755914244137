import { Controller } from 'stimulus'
import { putQuery } from 'helpers/requests.js'

export default class extends Controller {
  async handleClickCross (e) {
    try {
      putQuery('/espace-entreprise/update-onboarding-contract-tour')
    } catch (e) {
      throw new Error(e)
    }

    const modalElement = document.querySelector('#modal-onboarding-contract-tour-start')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.hide()
  }

  async handleClickStartTour (e) {
    const modalElement = document.querySelector('#modal-onboarding-contract-tour-start')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.hide()

    if (window.localStorage.tour_end === 'yes') {
      onboardingContractTour.restart()
    } else {
      onboardingContractTour.start()
      window.location = '/espace-entreprise/vos-contrats'
    }
  }
}
