import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['scrollable', 'scrollArrows']

  connect () {
    this.adjustWidth()
    window.addEventListener('resize', this.adjustWidth.bind(this))
  }

  disconnect () {
    window.removeEventListener('resize', this.adjustWidth.bind(this))
  }

  adjustWidth () {
    if (this.scrollableTarget.scrollWidth > this.scrollableTarget.clientWidth) {
      this.scrollableTarget.style.width = '100%'
      this.scrollArrowsTarget.classList.remove('d-none')
    } else {
      this.scrollableTarget.style.width = 'fit-content'
      this.scrollArrowsTarget.classList.add('d-none')
    }
  }

  scrollNext () {
    this.scrollableTarget.scrollBy({ left: this.scrollableTarget.clientWidth, behavior: 'smooth' })
  }

  scrollPrevious () {
    this.scrollableTarget.scrollBy({ left: -this.scrollableTarget.clientWidth, behavior: 'smooth' })
  }
}
