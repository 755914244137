import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = []

  connect () {
    $('[data-select="no-search"]').select2({
      minimumResultsForSearch: Infinity,
      width: '100%',
      placeholder: '',
      allowClear: true
    })
    $('.multi-law-conformities').multiselect({
      nonSelectedText: '',
      nSelectedText: ' sélections',
      buttonClass: 'multiselect dropdown-toggle btn btn-default h-35px',
      buttonWidth: '100%',
      minHeight: 40
    })
    $('.multiselect-container.dropdown-menu').children().first().remove()
    $('label.checkbox').addClass('text-16')
  }
}
