import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    if ($('[data-select="HealthMutual_input"]')[0]) {
      const initValueHealthMutual = $('[data-select="HealthMutual_input"]')[0].value
      this.changeCircleButton($(`[data-select="HealthMutual"][data-value="${initValueHealthMutual}"]`)[0])
    }

    if ($('[data-select="ProvidentInsurance_input"]')[0]) {
      const initValueProvidentInsurance = $('[data-select="ProvidentInsurance_input"]')[0].value
      this.changeCircleButton($(`[data-select="ProvidentInsurance"][data-value="${initValueProvidentInsurance}"]`)[0])
    }
  }

  handleClickHealthMutual (e) {
    const currentButton = e.currentTarget.childNodes[1]
    if (currentButton.classList.contains('btn-outline-primary')) {
      this.changeCircleButton($('[data-select="HealthMutual"]')[0])
      this.changeCircleButton($('[data-select="HealthMutual"]')[1])

      const imput = $('[data-select="HealthMutual_input"]')[0]
      imput.value = currentButton.dataset.value
    }
  }

  handleClickProvidentInsurance (e) {
    const currentButton = e.currentTarget.childNodes[1]
    if (currentButton.classList.contains('btn-outline-primary')) {
      this.changeCircleButton($('[data-select="ProvidentInsurance"]')[0])
      this.changeCircleButton($('[data-select="ProvidentInsurance"]')[1])

      const imput = $('[data-select="ProvidentInsurance_input"]')[0]
      imput.value = currentButton.dataset.value
    }
  }

  changeCircleButton (button) {
    button.classList.toggle('btn-outline-primary')
    button.classList.toggle('btn-primary')
    button.parentNode.classList.toggle('border-grey')
    button.parentNode.classList.toggle('border-primary')
  }
}
