import { Controller } from 'stimulus'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['canvas', 'instruction', 'reload', 'control', 'endScreen', 'animationWrapper', 'beginScreen', 'countdown']

  static values = {
    exercise: Object,
    state: String
  }

  startExercises () {
    this.beginScreenTarget.classList.add('d-none')
    this.beginScreenTarget.classList.remove('d-flex')
    this.toggleAnimationScreen()
    this.initializeExercises()
  }

  initializeExercises () {
    const animation = anime.timeline({
      targets: this.canvasTarget,
      easing: 'linear',
      update: (anim) => {
        this.countdownTarget.innerHTML = this.formatMinutesSeconds(anim.duration - anim.currentTime)
      },
      complete: () => {
        this.toggleEndScreen()
        this.toggleAnimationScreen()
      }
    })

    let counter = 0

    // set always initials position to small circle
    animation.add({
      scale: 0.4,
      duration: 0,
      offset: 0
    })

    while (counter !== this.exerciseValue.repetitions) {
      this.formatSteps().forEach(function (item) {
        animation.add(item)
      })
      counter += 1
    }

    // stock animation element to instance variable (access in action function)
    this.animationValue = animation
  }

  handleClickControl (e) {
    if (this.stateValue === 'play') {
      this.stateValue = 'pause'
      e.currentTarget.innerHTML = 'Reprendre <i class="ml-2 fa-regular fa-circle-play"></i>'
      this.animationValue.pause()
    } else {
      this.stateValue = 'play'
      e.currentTarget.innerHTML = 'Pause <i class="ml-2 fa-regular fa-circle-pause"></i>'
      this.animationValue.play()
    }
  }

  reload () {
    this.toggleEndScreen()
    this.toggleAnimationScreen()
    this.animationValue.restart()
  }

  toggleEndScreen () {
    this.endScreenTarget.classList.toggle('d-none')
    this.endScreenTarget.classList.toggle('d-flex')
  }

  toggleAnimationScreen () {
    this.animationWrapperTarget.classList.toggle('d-none')
    this.animationWrapperTarget.classList.toggle('d-flex')
  }

  formatMinutesSeconds (timer) {
    const minutes = Math.floor(timer / (1000 * 60))
    const seconds = Math.floor((timer % (1000 * 60)) / 1000)
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  formatSteps () {
    return this.exerciseValue.stepsArray.map((item, index, array) => {
      let parsePreviousItem = { stepKind: 'inspiration' }

      const parseItem = JSON.parse(item)

      if (array[index - 1]) {
        parsePreviousItem = JSON.parse(array[index - 1])
      }

      let value, keyword

      if (parseItem.stepKind === 'inspiration') {
        value = 2.5
        keyword = 'Inspirez par le nez'
      } else if (parseItem.stepKind === 'expiration') {
        value = 0.4
        keyword = 'Expirez par la bouche'
      } else if (parseItem.stepKind === 'holding') {
        keyword = 'Retenez votre souffle'
        if (parsePreviousItem.stepKind === 'inspiration') {
          value = 2.5
        } else if (parsePreviousItem.stepKind === 'expiration') {
          value = 0.4
        }
      }

      return {
        scale: value,
        duration: parseItem.stepDuration * 1000,
        begin: (_anim) => {
          this.instructionTarget.innerHTML = keyword
        }
      }
    })
  }
}
