import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'button']
  static values = {
    currentId: Number
  }

  connect () {
    if (this.currentIdValue) this.activateTab(this.currentIdValue)

    window.setInterval(() => {
      const [tab] = this.tabTargets.filter(item => Number(item.dataset.id) === this.currentIdValue + 1)
      if (!tab) {
        this.activateTab(1)
      } else {
        this.activateTab(this.currentIdValue + 1)
      }
    }, 3500)
  }

  handleClick (e) {
    this.activateTab(Number(e.currentTarget.dataset.id))
  }

  activateTab (id) {
    const [tab] = this.tabTargets.filter(item => Number(item.dataset.id) === id)
    const [button] = this.buttonTargets.filter(item => Number(item.dataset.id) === id)
    this.tabTargets.forEach(item => item.classList.add('d-none'))
    this.buttonTargets.forEach(item => item.classList.remove('Public_Tabs__button--active'))

    tab.classList.toggle('d-none')
    button.classList.toggle('Public_Tabs__button--active')
    this.currentIdValue = id
  }
}
