import { Controller } from 'stimulus'
export default class extends Controller {
  update (event) {
    const value = event.srcElement.value === 'true'
    const id = event.params.id
    const idSecond = event.params.idSecond

    this.updateField(value, id)

    if (typeof idSecond !== 'undefined') {
      this.updateField(value, idSecond)
    }
  }

  updateField (value, id) {
    const element = document.getElementById(id)
    const rowElement = element.closest('.row')

    if (value) {
      element.removeAttribute('disabled')
      rowElement.classList.remove('d-none')
    } else {
      element.setAttribute('disabled', true)
      rowElement.classList.add('d-none')
    }
  }
}
