import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['item']

  connect () {
    this.itemTargets.forEach((item) => { this.addGrabStyle(item) })
    Sortable.create(this.element,
      {
        onEnd: (evt) => {
          const url = evt.item.dataset.updatePositionUrl
          const position = evt.newIndex
          this.updatePosition(url, position)
        }
      })
  }

  addGrabStyle (element) { element.style.cursor = 'grab' }

  async updatePosition (url, position) {
    try {
      const res = await patchQuery(url, JSON.stringify({ item_position: position }))

      if (res.status === 'ok') {
        toastr.success('Ordre modifié')
      } else {
        throw new Error('update failed')
      }
    } catch (e) {
      toastr.error('Mise à jour impossible')
      throw new Error(e)
    }
  }
}
