import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton']
  static values = { numberOfChildToChoose: Number }

  checkValidatingClick () {
    const numberOfChildCardChecked = document.querySelectorAll(':checked')
    this.submitButtonTarget.disabled = !(numberOfChildCardChecked.length >= this.numberOfChildToChooseValue)
  }
}
