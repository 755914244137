import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['terminationDateInput']

  static values = {
    productType: String
  }

  checkBankingActorName (e) {
    if (this.productTypeValue === 'provident_insurance') {
      if (e.target.value === 'alan') {
        this.changeMinTerminationDate(this.alanTerminationDate())
        this.changeTerminationDateValue(this.alanTerminationDate())
      } else {
        this.changeMinTerminationDate(this.otherTerminationDate())
        this.changeTerminationDateValue(this.otherTerminationDate())
      }
    }
  }

  alanTerminationDate () {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth() + 2, 0)
  }

  otherTerminationDate () {
    const today = new Date()
    if ((today.getMonth() <= 9) && (today.getDate() <= 31)) {
      return new Date(today.getFullYear(), 11, 31)
    } else {
      return new Date(today.getFullYear() + 1, 11, 31)
    }
  }

  formattedDate (date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  changeMinTerminationDate (date) {
    this.terminationDateInputTarget.setAttribute('min', this.formattedDate(date))
  }

  changeTerminationDateValue (date) {
    this.terminationDateInputTarget.value = this.formattedDate(date)
  }
}
