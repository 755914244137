import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputKeyNotVisible', 'inputKeyVisible']

  handleClick (e) {
    this.inputKeyNotVisibleTarget.classList.toggle('d-none')
    this.inputKeyVisibleTarget.classList.toggle('d-none')
  }
}
