import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $(document).ready(function () {
      $('.alert').first().addClass('d-none')
      $('#universal-modal-lg-company-administration').modal()
    })
  }
}
