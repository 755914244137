import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['fileForm', 'fileInput', 'customButton']

  connect () {
    this.init()
  }

  init () {
    const fileForm = this.fileFormTarget
    const fileInput = this.fileInputTarget

    if (fileInput) {
      this.customButtonTarget.addEventListener('click', (event) => {
        event.preventDefault()
        fileInput.click()
      })

      fileInput.addEventListener('change', (_) => {
        fileForm.submit()
      })
    }
  }
}
