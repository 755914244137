import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['checkboxMutual', 'checkboxProvident', 'submitButton']

  connect () {
    this.changeDisableButtonSubmit()
  }

  changeDisableButtonSubmit (_) {
    if (this.checkboxMutualChecked() && this.checkboxProvidentChecked()) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  checkboxMutualChecked () {
    if (this.hasCheckboxMutualTarget) {
      return this.checkboxMutualTarget.checked === true
    } else {
      return true
    }
  }

  checkboxProvidentChecked () {
    if (this.hasCheckboxProvidentTarget) {
      return this.checkboxProvidentTarget.checked === true
    } else {
      return true
    }
  }
}
