import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('.platform_access').select2({
      minimumResultsForSearch: Infinity,
      width: '100%'
    })
  }
}
