function copyInClipboard (inputTarget) {
  // get the select value in input
  inputTarget.select()
  // For Mobile
  inputTarget.setSelectionRange(0, 99999)
  // Copy the text in clipboard
  document.execCommand('copy')
}

export { copyInClipboard }
