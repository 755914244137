import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['chevron']

  rotateChevron () {
    if (this.chevronTarget.classList.contains('rotate-90')) {
      this.chevronTarget.classList.remove('rotate-90')
    } else {
      this.chevronTarget.classList.add('rotate-90')
    }
  }
}
