import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import { getQuery } from 'helpers/requests'
import { currentParams, getParam, addOrUpdateParamsOfCurrentUrl } from 'helpers/paramsUrl'
import moment from 'moment'

export default class extends Controller {
  static targets = ['calendar']

  static values = {
    licenseKey: String,
    eventsJson: Array,
    employeesJson: Array,
    calendarType: String,
    displayUserPro: Object,
    daysOffByUserPro: Array
  }

  decodeLicenseKey () {
    return decodeURIComponent(escape(window.atob(this.licenseKeyValue)))
  }

  connect () {
    this.loadCalendar()
  }

  loadCalendar () {
    this.calendarTypes = {
      EmployeeTeam: 'teamCalendar',
      Manager: 'managerCalendar',
      CompanyAdministrator: 'companyAdministratorCalendar'
    }
    const calendar = this[this.calendarTypes[this.calendarTypeValue]]()
    calendar.setOption('locale', 'fr')
    calendar.render()
  }

  calendarDefaultOptions () {
    return {
      schedulerLicenseKey: `${this.decodeLicenseKey()}`,
      datesSet: function (dateInfo) {
        // change url to set actual month in params
        const date = moment(dateInfo.start).format('YYYY-MM-DD')
        addOrUpdateParamsOfCurrentUrl('startDate', date)
      },
      plugins: [resourceTimelinePlugin, interactionPlugin],
      timeZone: 'locale',
      initialView: 'resourceTimelineMonth',
      aspectRatio: 1.5,
      firstDay: 1,
      views: {
        ressourceCustomWeek: {
          type: 'resourceTimeline',
          duration: { weeks: 1 },
          slotDuration: { days: 1 },
          buttonText: 'Semaine'
        }
      },
      headerToolbar: {
        left: '',
        center: 'prev,title,next',
        right: 'ressourceCustomWeek,resourceTimelineMonth'
      },
      initialDate: getParam('startDate'),
      buttonText: {
        month: 'Mois',
        week: 'Semaine'
      },
      displayEventTime: false,
      resources: this.employeesJsonValue,
      events: [
        ...this.eventsJsonValue,
        ...this.daysOffByUserProValue
      ],
      locale: 'fr'
    }
  }

  teamCalendar () {
    return new Calendar(this.calendarTarget, {
      ...this.calendarDefaultOptions(),
      resourceAreaHeaderContent: 'Membres de mon équipe',
      resourceOrder: 'title',
      resourceLabelDidMount: function (resource) {
        document.querySelectorAll('.fc-datagrid-cell-cushion').forEach(element => {
          element.classList.add('d-flex')
          element.classList.add('align-items-center')
        })
        document.querySelectorAll('.fc-event-main').forEach(element => {
          element.style.cursor = 'auto'
        })
        const nameSpan = resource.el.querySelector('.fc-datagrid-cell-main')
        const container = document.createElement('div')
        container.setAttribute('class', 'd-flex align-items-center')
        const name = document.createElement('div')
        name.innerHTML = nameSpan.outerHTML
        nameSpan.parentNode.insertBefore(container, nameSpan)
        container.appendChild(name)
        nameSpan.classList.add('d-none')

        if (resource.resource.extendedProps.small_image_url) {
          const img = document.createElement('img')
          img.setAttribute('src', resource.resource.extendedProps.small_image_url)
          img.setAttribute('class', 'height-30px width-30px me-3 rounded')
          container.insertBefore(img, name)
        } else if (resource.resource.extendedProps.initials) {
          const initials = document.createElement('div')
          const colorClasses = ['avatar-square-cyan', 'avatar-square-goldenrod', 'avatar-square-orange', 'avatar-square-green', 'avatar-square-purple']
          const randomColorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)]
          initials.setAttribute('class', `me-3 height-30px width-30px ${randomColorClass} color-white fw-bold p-1 rounded overflow-hidden d-flex justify-content-center align-items-center`)
          initials.innerHTML = resource.resource.extendedProps.initials
          container.insertBefore(initials, name)
        }
      },
      eventDidMount: function (info) {
        $(info.el).tooltip({ title: info.event.title })
      }
    })
  }

  managerCalendar () {
    return new Calendar(this.calendarTarget, {
      ...this.calendarDefaultOptions(),
      eventClick: function (info) {
        $.get(`/espace-salarie/manager/conges-et-absences/${info.event.id}?format=js`)
        info.el.style.borderColor = info.event.color
      },
      datesSet: function (dateInfo) {
        // change url to set actual month in params
        const date = moment(dateInfo.start).format('YYYY-MM-DD')
        addOrUpdateParamsOfCurrentUrl('startDate', date)

        const startDateTime = dateInfo.startStr.match(/\d{4}-\d{2}-\d{2}/)[0]
        const endDateTime = dateInfo.endStr.match(/\d{4}-\d{2}-\d{2}/)[0]
        document.getElementById('download-excel-btn').href = `/espace-salarie/manager/conges-et-absences/planning?format=xlsx&absence_period=${startDateTime}_${endDateTime}`
      },
      resourceAreaHeaderContent: 'Employés managés',
      resourceOrder: 'title',
      refetchResourcesOnNavigate: true,
      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5]
      },
      resources: (fetchInfo, successCallback, failureCallback) => {
        const startPeriod = moment(fetchInfo.start).format('YYYY-MM-DD')
        const endPeriod = moment(fetchInfo.end).format('YYYY-MM-DD')
        const fetchUrl = `/espace-salarie/manager/conges-et-absences/planning${currentParams()}&start_period_on_calendar=${startPeriod}&end_period_on_calendar=${endPeriod}`

        this.fetchResources(successCallback, failureCallback, fetchUrl)
      },
      resourceLabelDidMount: (resource) => {
        this.resourceLabelDidMount(
          resource,
          `${window.location.origin}/espace-salarie/manager/mon-equipe/${resource.resource.id}`
        )
      },
      eventDidMount: function (info) {
        $(info.el).tooltip({ title: info.event.title })
      }
    })
  }

  companyAdministratorCalendar () {
    return new Calendar(this.calendarTarget, {
      ...this.calendarDefaultOptions(),
      eventClick: function (info) {
        $.get(`/espace-entreprise/conges-et-absences/${info.event.id}/show_without_nested`)
        info.el.style.borderColor = info.event.color
      },
      datesSet: function (dateInfo) {
        // change url to set actual month in params
        const date = moment(dateInfo.start).format('YYYY-MM-DD')
        addOrUpdateParamsOfCurrentUrl('startDate', date)

        // Change export according to calendat View
        let userProParam = window.location.search
        if (!userProParam) {
          userProParam = '?user_pro_id='
        }
        const startDateTime = dateInfo.startStr.match(/\d{4}-\d{2}-\d{2}/)[0]
        const endDateTime = dateInfo.endStr.match(/\d{4}-\d{2}-\d{2}/)[0]
        document.getElementById('download-excel-btn').href = `/espace-entreprise/conges-et-absences/planning${userProParam}&format=xlsx&absence_period=${startDateTime}_${endDateTime}`
      },
      resourceGroupField: this.displayUserProValue.need_group ? 'user_pro' : '',
      // fetch resource on click prev/next
      refetchResourcesOnNavigate: true,
      resources: (fetchInfo, successCallback, failureCallback) => {
        const startPeriod = moment(fetchInfo.start).format('YYYY-MM-DD')
        const endPeriod = moment(fetchInfo.end).format('YYYY-MM-DD')
        const fetchUrl = `/espace-entreprise/conges-et-absences/planning${currentParams()}&start_period_on_calendar=${startPeriod}&end_period_on_calendar=${endPeriod}`

        this.fetchResources(successCallback, failureCallback, fetchUrl)
      },
      resourceOrder: 'user_pro, title, work_day',
      // default Business Hours (mon....fri)
      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5]
      },
      resourceAreaColumns: [
        {
          field: 'title',
          headerContent: this.displayUserProValue.display_name
        },
        {
          field: 'work_day',
          headerContent: { html: '<span class="d-block text-start white-space-wrap">Jours travaillés</span>' },
          width: '30%'
        }],
      resourceLabelDidMount: (resource) => {
        this.resourceLabelDidMount(
          resource,
          `${window.location.origin}/espace-entreprise/salaries/${resource.resource.id}/informations-personnelles`
        )
      },
      eventDidMount: function (info) {
        $(info.el).tooltip({ title: info.event.title })
      }
    })
  }

  async fetchResources (successCallback, failureCallback, href) {
    try {
      const res = await getQuery(href)

      successCallback(res.data)
    } catch (e) {
      failureCallback(e)
    }
  }

  resourceLabelDidMount (resource, href) {
    document.querySelectorAll('.fc-datagrid-cell-cushion').forEach(element => {
      element.classList.add('d-flex')
      element.classList.add('align-items-center')
    })

    const nameSpan = resource.el.querySelector('.fc-datagrid-cell-main')
    const container = document.createElement('div')
    container.setAttribute('class', 'd-flex align-items-center')
    const link = document.createElement('a')
    link.innerHTML = nameSpan.outerHTML
    link.setAttribute('href', href)
    nameSpan.parentNode.insertBefore(container, nameSpan)
    container.appendChild(link)
    nameSpan.classList.add('d-none')
    if (resource.resource.extendedProps.small_image_url) {
      const img = document.createElement('img')
      img.setAttribute('src', resource.resource.extendedProps.small_image_url)
      img.setAttribute('class', 'height-30px width-30px me-3 rounded')
      container.insertBefore(img, link)
    } else if (resource.resource.extendedProps.initials) {
      const initials = document.createElement('div')
      const colorClasses = ['avatar-square-cyan', 'avatar-square-goldenrod', 'avatar-square-orange', 'avatar-square-green', 'avatar-square-purple']
      const randomColorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)]
      initials.setAttribute('class', `me-3 height-30px width-30px ${randomColorClass} color-white fw-bold p-1 rounded overflow-hidden d-flex justify-content-center align-items-center`)
      initials.innerHTML = resource.resource.extendedProps.initials
      container.insertBefore(initials, link)
    }
  }
}
