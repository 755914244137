import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const newTooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const oldTooltipTriggerList = [].slice.call(document.querySelectorAll('.tooltip-tag'))
    const allTooltipsTriggerList = newTooltipTriggerList.concat(oldTooltipTriggerList)

    allTooltipsTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl) // eslint-disable-line
    })
  }
}
