import { Controller } from 'stimulus'
const ocrApiBaseUrl = 'https://hoggo-ocr-api.herokuapp.com/api/v1'
const acceptedExtensions = ['pdf', 'jpeg', 'jpg', 'png']

export default class extends Controller {
  static targets = ['socialSecurityNumber', 'healthInsuranceCode']
  static values = {
    apiKey: String
  }

  connect () {
    window.addEventListener('fileDragDrop', (e) => this.handleFile(e))
  }

  async handleFile (e) {
    const file = e.detail.file
    const fileExtension = file.type.split('/').pop()
    const temporaryTokenResponse = await this.getTemporaryToken()
    if (file && acceptedExtensions.includes(fileExtension.toLowerCase()) && temporaryTokenResponse) {
      const ameliFileResponse = await this.sendFile(file, temporaryTokenResponse.temporary_token)
      this.socialSecurityNumberTarget.value = ameliFileResponse.persons_array[0].social_security_number
      this.healthInsuranceCodeTarget.value = ameliFileResponse.social_security_organism
    }
  }

  async sendFile (file, temporaryToken) {
    if (file && temporaryToken) {
      const formData = new FormData()
      formData.append('ameli_file', file)
      formData.append('temporary_token', temporaryToken)

      const response = await fetch(`${ocrApiBaseUrl}/ameli`, {
        method: 'POST',
        dataType: 'json',
        processData: false,
        contentType: false,
        headers: {
          Authorization: `Bearer ${this.decodeKey(this.apiKeyValue)}`
        },
        body: formData
      })
      return response.json()
    }
  }

  async getTemporaryToken () {
    if (this.decodeKey(this.apiKeyValue)) {
      const response = await fetch(`${ocrApiBaseUrl}/temporary_token`, {
        method: 'POST',
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.decodeKey(this.apiKeyValue)}`
        },
        body: {}
      })
      return response.json()
    }
  }

  decodeKey (key) {
    return decodeURIComponent(escape(window.atob(key)))
  }
}
