import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['backToTop', 'backToTopContainer']
  static values = {
    content: Boolean
  }

  connect () {
    this.hideCondition()

    $(window).scroll(() => {
      this.hideCondition()
    })
  }

  handleClick (_) {
    $('body, html').animate({
      scrollTop: 0
    }, 800)

    return false
  }

  setElementToHide () {
    if (this.contentValue) {
      return $(this.backToTopTarget)
    } else {
      return $(this.element)
    }
  }

  hideCondition () {
    const backToTop = this.setElementToHide()

    if ($(window).scrollTop() > 50) {
      backToTop.show()
      this.backToTopContainerTarget.classList.replace('mt-0', 'mt-4')
    } else {
      backToTop.hide()
      this.backToTopContainerTarget.classList.replace('mt-4', 'mt-0')
    }
  }
}
