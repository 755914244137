import { Controller } from 'stimulus'

export default class extends Controller {
  eventClick (event) {
    // datas from current target to object
    const eventProperties = event.currentTarget.dataset
    delete eventProperties.action

    // set the name of the event and removes it from the properties
    const amplitudeAction = eventProperties.amplitudeAction
    delete eventProperties.amplitudeAction

    amplitude.track(amplitudeAction, eventProperties)
  }

  basicEvent (event) {
    // datas from current target to object
    const eventProperties = event.currentTarget.dataset
    delete eventProperties.action

    // set the name of the event and removes it from the properties
    const amplitudeAction = eventProperties.amplitudeAction
    delete eventProperties.amplitudeAction

    amplitude.track(amplitudeAction, eventProperties)
  }
}
