import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['elementToShow', 'icon']

  toggleElement (e) {
    this.elementToShowTarget.classList.toggle('d-none')
    this.iconTarget.classList.toggle('rotate-90')
    this.iconTarget.classList.toggle('rotate-0')
    this.dispatch('toggle', { detail: { content: this.elementToShowTarget.classList } })
  }
}
