import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('#periodicity-btn-3').removeClass('btn-default text-dark-40')
    $('#periodicity-btn-3 span').removeClass('text-dark-40 fw-medium')
    $('#periodicity-btn-3').addClass('btn-outline-primary')
    $('#periodicity-btn-3 span').addClass('text-dark fw-semibold')
    $('.form-template-row').hide()
  }

  questionTemplateChange (event) {
    $('.form-template-row').hide()
    $(`#form-template-${event.currentTarget.value}`).show()
  }

  disableSelect ({ detail: { content } }) {
    if (content.value === 'd-none') {
      $('#user-pros-div').show()
      $('#team-multiselect').multiselect('deselectAll', false)
      $('#team-multiselect').multiselect('rebuild')
    } else {
      $('#user-pros-div').hide()
      $('#user-pros-div .multi-select').multiselect('deselectAll', false)
      $('#user-pros-div .multi-select').multiselect('rebuild')
    }
  }

  periodicityDaysWeekChange (event) {
    event.preventDefault()
    $('.periodicity-days-of-week-btn').removeClass('btn-outline-primary')
    $('.periodicity-days-of-week-btn span').removeClass('text-dark fw-semibold')
    $('.periodicity-days-of-week-btn').addClass('btn-default')
    $('.periodicity-days-of-week-btn span').addClass('text-dark-40 fw-medium')
    $(`#periodicity-btn-${event.currentTarget.dataset.id}`).addClass('btn-outline-primary')
    $(`#periodicity-btn-${event.currentTarget.dataset.id} span`).addClass('text-dark fw-semibold')
    $(`#periodicity-btn-${event.currentTarget.dataset.id}`).removeClass('btn-default')
    $('#periodicity-days-of-week-id').val(event.currentTarget.dataset.value)
  }

  clickRadioBtn (event) {
    const qName = $('#wellness_setting_survey_name').val()
    const qUserPros = $('#user-pro-multiselect').val()
    const qTeams = $('#team-multiselect').val()

    const uProQuery = []
    for (let index = 0; index < qUserPros.length; index++) {
      uProQuery.push(`user_pros[]=${qUserPros[index]}`)
    }
    const teamQuery = []
    for (let index = 0; index < qTeams.length; index++) {
      teamQuery.push(`teams[]=${qTeams[index]}`)
    }
    window.location.search = `&recurrence=${event.currentTarget.value}&name=${qName}&${uProQuery.join('&')}&${teamQuery.join('&')}`
  }
}
