import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    document.querySelector('#youtube-modal').addEventListener('hide.bs.modal', function (_e) {
      player.pauseVideo()
      player.seekTo(0, false)
    })
  }

  handleClick (e) {
    const modalElement = document.querySelector('#youtube-modal')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement, { keyboard: true }) // eslint-disable-line
    modal.show()

    // Play Video Player
    player.playVideo()
  }
}
