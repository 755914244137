import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    onClickUrl: String
  }

  connect () {
    const that = this
    $(this.inputTarget).devbridgeAutocomplete({
      serviceUrl: '/espace-partenaire/clients.json',
      paramName: 'pg_search_scope_bis',
      deferRequestBy: 250,
      minChars: 1,
      onSearchStart: function (query) {
        const regex = /^[0-9 ]+$/
        if (regex.test(query.pg_search_scope_bis)) {
          query.pg_search_scope_bis = query.pg_search_scope_bis.replace(/\s/g, '')
        }
      },
      transformResult: function (response) {
        const data = JSON.parse(response)
        return {
          suggestions: $.map(data, function (dataItem) {
            let socialNameWrapper = ''
            if (dataItem.social_name) {
              socialNameWrapper = dataItem.social_name
            } else {
              socialNameWrapper = dataItem.social_name
            }
            const score = 1
            return { value: dataItem.siren, data: dataItem.siren, social_name: socialNameWrapper, id: dataItem.id, siren: dataItem.siren, siret: dataItem.siret, creation_date: dataItem.company_creation_date, street: dataItem.company_address, country: 'France', score }
          }).sort(function (a, b) {
            return a.score - b.score
          })
        }
      },
      formatResult: function (suggestion, currentValue) {
        return `<a href="/espace-partenaire/clients/${suggestion.id}${that.onClickUrlValue}"><span class='name-society text-16 fw-bold'>${suggestion.social_name}</span> <span>(SIRET: ${suggestion.siret})</span> <br> <span> Cliquez pour faire une demande de devis</span></a>`
      },
      onSelect: function (suggestion) {
        $(this).val(`${suggestion.social_name} (${suggestion.data})`)
        $('#selection-name').html(`Cliquez pour faire une demande de devis pour : ${suggestion.social_name}`)
        window.location.href = `/espace-partenaire/clients/${suggestion.id}${that.onClickUrlValue}`
      }
    })
  }
}
