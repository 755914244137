import jsxElem from 'jsx-no-react' // eslint-disable-line
import moment from 'moment'
import { capitalize } from 'helpers/string'

function dateDisplayCalendar (dayMonth, halfDayString) {
  moment.locale('fr')

  return (
    <div className="background-white text-center border-radius-5 box-shadow-tile overflow-hidden w-130px">
      <div className="background-cyan px-4 py-1">
        <p className="mb-0 color-white fw-bold text-14">
          {moment(dayMonth).format('YYYY')}
        </p>
      </div>
      <div className="px-4 py-1">
        <p className="mb-0 text-14 fw-bold color-dark-purple">
          {capitalize(moment(dayMonth).format('ddd DD MMM'))}
        </p>
        <p className="mb-0 text-12 text-muted">
          {halfDayString === 'morning' ? 'Matin' : 'Après-midi'}
        </p>
      </div>
    </div>
  )
}

export { dateDisplayCalendar }
