import { Controller } from 'stimulus'
import { getQuery, postQuery } from 'helpers/requests'

export default class extends Controller {
  static values = {
    cardId: String,
    keyUrl: String,
    cardKind: String,
    revealPinUrl: String,
    checkDigitsUrl: String,
    cardStatus: String
  }

  static targets = ['input', 'loader', 'result', 'submit', 'modal', 'description', 'cardImg', 'activatedImg',
    'inputGroup', 'title', 'reminder', 'pinText', 'beneficiary', 'cvc', 'cardNumber', 'expirationDate' ]

  connect () {
    this.modalTarget.addEventListener('shown.bs.modal', () => {
      this.inputTargets[0].focus()
      if(this.isPhysicalCard) {
        document.addEventListener('keydown', (e) => {
          if ((e.key.toString().toLowerCase() == 'enter') && this.verifySubmit()) {
            this.reveal(e)
          }
        })
      }
    })

    if(this.isPhysicalCard) {
      this.inputTargets.forEach((input =>{
        this.shortcut(input, `backspace`, this.handleBackspace);
        this.shortcut(input, `arrowleft`, this.handleArrowLeft);
        this.shortcut(input, `arrowright`, this.handleArrowRight);
      }))
    }
  }

  handleInput(e) {
    this.verifySubmit()
    if(this.isPhysicalCard) {
      let nextInput = e.target.nextElementSibling;
      if (nextInput && e.target.value) {
        nextInput.focus();
        nextInput.value && nextInput.select();
      }
    }
  }

  handleShowPassword() {
    const passwordInput = this.inputTarget

    if (passwordInput.type === 'password') {
      passwordInput.setAttribute('type', 'text')
    } else {
      passwordInput.setAttribute('type', 'password')
    }
  }

  handleEnter(e) {
    if (this.verifySubmit()) {
      this.reveal(e)
    }
  }

  handleBackspace(target) {
    target.value
      ? (target.value = "")
      : target.previousElementSibling.focus();
  }

  verifySubmit() {
    const inputIsFilled = (input) => input.value !== ''
    const disabled = !this.inputTargets.every(inputIsFilled)
    this.submitTarget.disabled = disabled
    return !disabled
  }

  handleArrowLeft(target) {
    const previousInput = target.previousElementSibling;
    return !previousInput ? undefined : previousInput.focus();
  }

  handleArrowRight(target) {
    const nextInput = target.nextElementSibling;
    return !nextInput ? undefined : nextInput.focus();
  }

  shortcut(element, key, handle) {
    element.addEventListener(`keydown`, (e) => {
      return e.key.toString().toLowerCase() == key && handle(element);
    });
  }

  cardStatusValueChanged (current, old) {
    if(current === old) return;

    this.submitTarget.innerHTML = current == 'active' ? this.isPhysicalCard ? 'Voir le code PIN' :  'Voir mes infos' : 'Activer ma carte'
  }

  async reveal (e) {
    try {
      this.toggleLoader()
      this.submitTarget.disabled = true;
      this.displayResult('Vérification en cours...')
      const fourDigitsResponse = await getQuery(`${this.checkDigitsUrlValue}?last_digits=${encodeURIComponent(this.inputTargets.map(e => e.value).join(''))}`)
      if (!fourDigitsResponse['result']) {
        if(this.isPhysicalCard) {
          this.displayResult('Les chiffres ne correspondent pas, veuillez réessayer.', 'error')
        }else {
          this.displayResult('Le mot de passe saisi est incorrect, veuillez réessayer.', 'error')
        }
        return
      }

      this.displayResult('Récupération des données...')
      const aesKey = generateAesKey()
      const encryptedKey = await this.getEncryptedKey(aesKey)

      if(this.cardStatusValue == 'inactive')
        this.displayResult('Activation de la carte en cours...')
      else if (this.cardStatusValue === 'active') {
        if(this.isPhysicalCard) {
          this.displayResult('Récupération du PIN en cours...')
        } else {
          this.displayResult('Récupération des informations de la carte en cours...')
        }
      }

      const { encrypted_pin_block, encrypted_data, token, result } = await getQuery(`${this.revealPinUrlValue}?encrypted_key=${encodeURIComponent(encryptedKey)}`)

      this.pinTextTarget.classList.remove('d-none')

      if(this.isVirtualCard) {
        var decryptedPinBlock = bytes_to_string(AES_CBC.decrypt(hex_to_bytes(encrypted_data), hex_to_bytes(aesKey), false, hex_to_bytes("00000000000000000000000000000000")));
        var paymentInstrumentInfo = JSON.parse(decryptedPinBlock.replaceAll('\x10',''));

        this.beneficiaryTarget.classList.remove('d-none')
        this.cardNumberTarget.classList.remove('d-none')
        this.cardNumberTarget.innerHTML = `Numéro de la carte : <strong>${paymentInstrumentInfo.pan.match(/.{1,4}/g).join(' ')}</strong>`
        this.cvcTarget.classList.remove('d-none')
        this.cvcTarget.innerHTML = `CVC : <strong>${paymentInstrumentInfo.cvc}</strong>`
        this.expirationDateTarget.classList.remove('d-none')
        this.expirationDateTarget.innerHTML = `Expiration : <strong>${paymentInstrumentInfo.expiration.month}/${paymentInstrumentInfo.expiration.year}</strong>`
        // alert( "Card number " + paymentInstrumentInfo.pan + "\nExipry date: " + paymentInstrumentInfo.expiration.year +"/" + paymentInstrumentInfo.expiration.month +"\nCvc: "+ paymentInstrumentInfo.cvc);
      } else {
        const decryptedPinBlock = bytes_to_hex(AES_ECB.decrypt(hex_to_bytes(encrypted_pin_block), hex_to_bytes(aesKey)))
        const pin = extractPin(decryptedPinBlock, token, aesKey)
        this.displayPin(pin)
      }

        if(result) this.cardStatusValue = result;
        this.submitTarget.classList.add('d-none')
        this.descriptionTarget.classList.add('d-none')
        this.titleTarget.innerHTML = `${result === 'active' ? "C'est tout bon \n! Votre SideCard est active !" : '' }`
        this.cardImgTarget.classList.add('d-none')
        //
        this.activatedImgTarget.classList.remove('d-none', 'mb-3')
        this.reminderTarget.classList.remove('d-none')
        if(this.isVirtualCard) {
          this.inputTargets[0].parentElement.classList.add('d-none')
        }
        this.displayResult('')

        if(result === 'active'){
          const button = document.getElementById(`side_card_status_${this.cardIdValue}`)
          button.innerHTML = 'Active'
          button.classList.remove('text-primary', 'bg-primary-10')
          button.classList.add('text-success', 'background-green-fond-tag')
          this.modalTarget.addEventListener('hide.bs.modal', () => {
            location.reload()
          })
        }

    } catch (e) {
      console.error(e)
      this.displayResult('Une erreur est survenue', 'error')
    } finally {
      this.toggleLoader()
      this.submitTarget.disabled = false;
    }
  }

  toggleLoader () {
    this.loaderTarget.classList.toggle('d-none')
    this.loaderTarget.classList.toggle('d-block')
  }

  displayPin (pin) {
    this.inputTargets.forEach((input, index) => {
      input.classList.add('SideCardPin__InputRevealed')
      input.disabled = true
      input.value = pin[index]
    })
  }

  displayResult(message, type = 'loading') {
    this.resultTarget.innerHTML = ''

    this.resultTarget.classList.remove('d-none')
    this.resultTarget.classList.add('d-block')
    if(type === 'error') {
      this.resultTarget.classList.remove('text-success')
      this.resultTarget.classList.add('text-danger')
      this.resultTarget.innerHTML = '<i class="fa fa-xmark-large"></i> '
    }else if (type === 'success'){
      this.resultTarget.classList.add('text-success')
      this.resultTarget.classList.remove('text-danger')
      this.resultTarget.classList.remove('text-info')
    } else {
      this.resultTarget.classList.remove('text-danger')
      this.resultTarget.classList.remove('text-success')
    }
    this.resultTarget.innerHTML+= message
  }

  async getEncryptedKey (aesKey) {
    const base64EncodedPublicKey = (await getQuery(this.keyUrlValue))?.pin_public_key
    const publicKey = new ASN1().parsePublicKey(base64EncodedPublicKey)
    const rsaEncryptor = new RSA_PKCS1_v1_5(publicKey)

    return bytes_to_hex(rsaEncryptor.encrypt(hex_to_bytes(aesKey)))
  }

  get isPhysicalCard() {
    return this.cardKindValue === 'physical'
  }
  get isVirtualCard() {
    return this.cardKindValue === 'virtual'
  }
}
