import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['collapse', 'show', 'hide']

  connect () {
    this.collapseTarget.addEventListener('show.bs.collapse', () => {
      this.showTarget.classList.add('d-none')
      this.hideTarget.classList.remove('d-none')
    })

    this.collapseTarget.addEventListener('hide.bs.collapse', () => {
      this.showTarget.classList.remove('d-none')
      this.hideTarget.classList.add('d-none')
    })
  }
}
