import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['youtubeModal', 'videoContainer']

  openModal (e) {
    this.videoContainerTarget.innerHTML = `
      <div class="embed-responsive">
        <iframe src="https://www.youtube.com/embed/${e.currentTarget.dataset.id}" frameborder="0" allowfullscreen style="">
        </iframe>
      </div>`
    const modal = bootstrap.Modal.getOrCreateInstance(this.youtubeModalTarget) // eslint-disable-line
    modal.show()
  }
}
