import { Controller } from 'stimulus';
import { deleteQuery, postQuery } from 'helpers/requests';
import * as toastr from 'toastr';

interface ResponseCreate {
  status: string,
  message: string,
  file?: {
    destoy_link: string,
    link: string,
    name: string,
    size: string
  }
}

interface CustomEventWithParams extends Event {
  params: {
    url: string;
  };
}

export default class extends Controller<HTMLFormElement> {
  static targets = [
    'cardList',
    'emptyCard',
    'fileInput',
    'employeeSickWorkStopDocumentTemplateIdHiddenInput',
    'subtitleContentWithoutFile',
    'subtitleContentWithFile'
  ];

  private maxFileSize: number = 5; // 5 Mo

  declare cardListTarget: HTMLInputElement;
  declare emptyCardTarget: HTMLInputElement;
  declare fileInputTarget: HTMLInputElement;
  declare employeeSickWorkStopDocumentTemplateIdHiddenInputTarget: HTMLInputElement;
  declare subtitleContentWithoutFileTarget: HTMLInputElement;
  declare subtitleContentWithFileTarget: HTMLInputElement;

  createDocument (event: Event) {
    const fileInput = (event.target as HTMLElement).closest('form')!.querySelector('input[name="file"]') as HTMLInputElement;
    const file = fileInput.files![0];
    this.sendFile(event, file);
  }

  drop (event: DragEvent) {
    const { files } = event.dataTransfer;
    const file = files[0];
    this.sendFile(event, file);
  }

  private sendFile(event: Event, file: File) {
    if (file.size > this.maxFileSizeToOctet) {
      this.errorFileSize(event);
    } else if (file.type === 'application/zip') {
      this.errorFileType(event)
    } else {
      this.createQuery(event, file);
    }
  }

  private get maxFileSizeToOctet(): number {
    return this.maxFileSize * 1048576;
  }

  deleteDocument(event: CustomEventWithParams) {
    const url = event.params.url;
    const target = event.target as HTMLElement;
    this.deleteQuery(url);

    const card = target.closest('.uploaded-img-zone') as HTMLElement;
    this.hiddenCard(card);
    this.hiddenCardList();
    this.updateSubtitle();
  }

  private errorFileSize(event: Event) {
    toastr.error(`Le fichier est supèrieur à ${this.maxFileSize} Mo`);
    const form = (event.target as HTMLElement).closest('form') as HTMLFormElement;
    this.resetForm(form);
  }

  private errorFileType(event: Event) {
    toastr.error(`Le fichier ne doit pas être de type ZIP.`);
    const form = (event.target as HTMLElement).closest('form') as HTMLFormElement;
    this.resetForm(form);
  }

  private async createQuery(event: Event, file: File) {
    const form = (event.target as HTMLElement).closest('form');
    const url = form.action;
    const employeeSickWorkStopDocumentTemplateId = this.employeeSickWorkStopDocumentTemplateIdHiddenInputTarget.value;
    const formData = this.formData(employeeSickWorkStopDocumentTemplateId, file);

    const data = await postQuery(url, formData, true);
    const response = await data.json();
    this.addNewCard(response);
    this.updateSubtitle();
    this.resetForm(form);
    this.showAlert();
  }

  private formData(employeeSickWorkStopDocumentTemplateId: string, file: File): FormData {
    const formData = new FormData();
    formData.append('employee_sick_work_stop_document[employee_sick_work_stop_document_template_id]', employeeSickWorkStopDocumentTemplateId);
    formData.append('employee_sick_work_stop_document[file]', file);

    return formData;
  }

  private addNewCard(response: ResponseCreate) {
    this.showCardList();
    const newCard = this.newCard(response);
    this.emptyCardTarget.insertAdjacentHTML('afterend', newCard.innerHTML);
  }

  private newCard(response: ResponseCreate): HTMLElement {
    const cardClone = this.emptyCardTarget.cloneNode(true) as HTMLElement;
    (cardClone.querySelector('.file-link') as HTMLAnchorElement).href = response.file.link;
    cardClone.querySelector('.file-name').innerHTML = response.file.name;
    cardClone.querySelector('.file-size').innerHTML = response.file.size;
    cardClone.querySelector('.file-link-destroy').setAttribute('data-employee-sick-work-stop-document-templates--show--component--item-component-url-param', response.file.destoy_link);

    return cardClone
  }

  private resetForm(form: HTMLFormElement) {
    const fileInput = form.querySelector('input[name="file"]') as HTMLInputElement;
    fileInput.value = null;
  }

  private async deleteQuery(url: String) {
    await deleteQuery(url);
  }

  private updateSubtitle() {
    if (this.cardListShowCount() <= 0) {
      this.hiddenSubtitleContentWithFile();
      this.showSubtitleContentWithoutFile();
    } else {
      this.hiddenSubtitleContentWithoutFile();
      this.showSubtitleContentWithFile();
    }
  }

  private hiddenSubtitleContentWithoutFile() {
    this.hiddenSubtitleContent(this.subtitleContentWithoutFileTarget);
  }

  private showSubtitleContentWithoutFile() {
    this.showSubtitleContent(this.subtitleContentWithoutFileTarget);
  }

  private hiddenSubtitleContentWithFile() {
    this.hiddenSubtitleContent(this.subtitleContentWithFileTarget);
  }

  private showSubtitleContentWithFile() {
    this.showSubtitleContent(this.subtitleContentWithFileTarget);
  }

  private showSubtitleContent(target: HTMLInputElement) {
    target.classList.remove('d-none');
    target.classList.add('d-inline-block');
  }

  private hiddenSubtitleContent(target:HTMLInputElement) {
    target.classList.remove('d-inline-block');
    target.classList.add('d-none');
  }

  private hiddenCard(card: HTMLElement) {
    card.classList.add('d-none');
  }

  private showCardList() {
    this.cardListTarget.classList.remove('d-none');
  }

  private hiddenCardList() {
    if (this.cardListShowCount() <= 0) {
      this.cardListTarget.classList.add('d-none');
    }
  }

  private cardListShowCount() {
    const allCardList = this.cardListTarget.querySelectorAll('.card');
    const cardListHidden = this.cardListTarget.querySelectorAll('.d-none');
    return allCardList.length - cardListHidden.length;
  }

  private showAlert() {
    const alertDocument = document.getElementById('alert_document');

    if (alertDocument) {
      alertDocument.classList.remove('d-none');

      setTimeout(() => {
        alertDocument.classList.add('d-none');
      }, 15000);
    }
  }
}
