import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['card', 'container']
  static values = {
    page: Number,
    url: String
  }

  connect () {
    this.handleToggleDropDown()
  }

  async handleClickSeeMore (e) {
    function getReleasesNoteHtml (releaseNotes) {
      if (!releaseNotes) return ''

      const releaseNotesHtml = releaseNotes.map(releaseNote =>
        `
          <div class="background-white p-1 mt-1 avatar-square" data-release-notes--notifications-target="card">
            <div class="row justify-content-around">
              <div class="col-1">
                <img class="height-25px" src="/assets/icones/icn24_couronne-ac4bd46050960ef00784842a481bf8fcc799327d550e02f3dec2f71401049c28.svg">
              </div>
              <div class="col-10 p-0 pe-1 ">
                <h6 class="color-dark-purple fw-bold text-14 mb-1">
                  ${releaseNote.name}
                </h6>
                <div class="color-grey text-start mb-3">
                  <em>${releaseNote.release_date}</em>
                </div>
                <div class="color-black">
                  ${releaseNote.description}
                </div>
                <div class="d-flex flex-column">
                  ${getAttachementsLinks(releaseNote.attachment_documents)}
                </div>
              </div>
            </div>
          </div>
        `
      )

      return releaseNotesHtml.join('')
    }

    function getAttachementsLinks (attachments) {
      if (attachments === undefined) return ''

      const attachmentsHtml = attachments.map(attachment =>
          `
            <a target="_blank" href="${attachment.url}">
              <img src="/assets/icones/icn24_document.svg">${attachment.filename}
            </a>
          `
      )

      return attachmentsHtml.join('')
    }

    try {
      const url = new URL(this.urlValue)
      url.searchParams.set('page', this.pageValue)

      const result = await getQuery(url)

      this.containerTarget.insertAdjacentHTML('beforeend', getReleasesNoteHtml(result.data))

      this.pageValue += 1

      if (result.data.length < 5 || result.data[0].paginate.max_page) {
        e.target.classList.add('d-none')
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  async handleToggleDropDown (e) {
    $(document).on('click', '#notifications .dropdown-menu', function (i) {
      i.stopPropagation()
    })
  }
}
