import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['useConditionsAcceptedCheck', 'useConditionsAcceptedError']

  check (e) {
    if (this.useConditionsAcceptedCheckTarget.checked) {
      this.useConditionsAcceptedErrorTarget.classList.add('d-none')
    } else {
      e.preventDefault()
      this.useConditionsAcceptedErrorTarget.classList.remove('d-none')
    }
  }
}
