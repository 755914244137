import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['signatureIframe']
  static values = {
    documentId: String,
    signatoryId: String
  }

  connect () {
    const that = this
    window.addEventListener('message', function (e) {
      if (e.data.event === 'success') {
        that.afterSignature(e.data.signature_request_id)
        that.changePageDisplay()
      }
    })
  }

  async afterSignature (signatureRequestId) {
    const response = await getQuery(`/api/v1/authentificate_pdf/yousign_signed_document/${this.documentIdValue}/${this.signatoryIdValue}?signature_request_id=${signatureRequestId}`)
    if (response.data === 'all good') {
      this.redirectEmployee()
    }
  }

  async changePageDisplay () {
    const container = document.getElementById(`signed-container-${this.documentIdValue}`)
    if (container) {
      document.getElementById(`signed-container-${this.documentIdValue}`).style.display = 'block'
      this.signatureIframeTarget.style.display = 'none'
    }
  }

  redirectEmployee () {
    if (window.location.href.match(/espace-salarie/)) {
      window.location.href = window.location.href.split(/\/signature-portabilite-en-ligne/)[0]
    }
  }
}
