import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []
  static values = {
    textInputName: String,
    serviceUrl: String,
    idInputName: String
  }

  connect () {
    const that = this
    $(`#${that.textInputNameValue}`).devbridgeAutocomplete({
      serviceUrl: `${that.serviceUrlValue}`,
      paramName: 'name',
      deferRequestBy: 250,
      minChars: 2,
      onSearchStart: function (query) {
        $('#search_indicator').show()
      },
      transformResult: function (response) {
        const data = JSON.parse(response)
        return {
          suggestions: $.map(data, function (dataItem) {
            const score = 1
            return { value: dataItem.id.toString(), name: dataItem.name, id: dataItem.id, score }
          }).sort(function (a, b) {
            return a.score - b.score
          })
        }
      },
      formatResult: function (suggestion, currentValue) {
        return "<span class='name-society text-16 fw-bold'> " + suggestion.name + '</span>' + '<br/>' + "<span class=''> Cliquez pour choisir le gestionnaire</span></a>"
      },
      onSearchComplete: function (query, suggestions) {
        $('#search_indicator').hide()
      },
      onSelect: function (suggestion) {
        $(this).val(suggestion.name)
        $(`#${that.idInputNameValue}`).val(suggestion.id)
      }
    })
  }
}
