import { Controller } from 'stimulus'
import Splide from '@splidejs/splide'

import '@splidejs/splide/dist/css/splide.min'

export default class extends Controller {
  connect () {
    const splide = new Splide(this.element, {
      classes: {
        pagination: 'splide__pagination your-class-pagination d-none',
        page: 'splide__pagination__page your-class-page d-none'
      }
    })
    splide.mount()
  }
}
