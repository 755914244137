import { Controller } from 'stimulus'
import imageDocumentComplete from 'images/icons/icn16_document.svg'
import imageDocumentEmpty from 'images/icons/icn24_upload.svg'
import { dispatchEvent } from 'helpers/common'
import { deleteQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['input', 'name', 'image', 'imageUploaded', 'filesize', 'previousAttachment']

  connect () {
    window.addEventListener('submitSuccess', () => this.resetDocument(this.nameTarget, this.imageTarget))
  }

  preventDefault (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  openDropZoneInput () {
    this.inputTarget.click()
  }

  updateFileStatus (e) {
    this.nameTarget.classList.add('text-cyan')
    this.imageUploadedTarget.classList.remove('d-none')
    this.nameTarget.innerHTML = this.inputTarget.files[0].name
    this.filesizeTarget.innerHTML = this.fileConvertSize(this.inputTarget.files[0].size)
    this.imageTarget.src = imageDocumentComplete
    this.imageTarget.classList.add('image-cyan')
    dispatchEvent('fileDragDrop', {
      detail: {
        file: e.target.files[0]
      }
    })
    if (this.hasPreviousAttachmentTarget) {
      this.previousAttachmentTarget.classList.add('d-none')
    }
  }

  fileConvertSize (aSize) {
    aSize = Math.abs(parseInt(aSize, 10))
    const def = [[1, 'octets'], [1024, 'Ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']]
    for (let i = 0; i < def.length; i++) {
      if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1]
    }
  }

  removeDocument () {
    this.inputTarget.value = ''
    this.nameTarget.innerHTML = ''
    this.imageUploadedTarget.classList.add('d-none')
  }

  resetDocument () {
    this.nameTarget.classList.remove('text-cyan')
    this.nameTarget.innerHTML = this.constructor.t('controllers.shared.dragAndDrop.uploadDocument')
    this.imageTarget.src = imageDocumentEmpty
    this.imageTarget.classList.remove('image-cyan')
  }

  addDraggingClass () {
    this.element.classList.add('dropzone-dragging')
  }

  removeDraggingClass () {
    this.element.classList.remove('dropzone-dragging')
  }

  removePreviousDocument (event) {
    const url = event.params.url
    this.deleteRequest(url)
  }

  async deleteRequest (url) {
    try {
      const data = await deleteQuery(url)
      if (data.status === 'ok') {
        this.previousAttachmentTarget.remove()
        toastr.success('Image supprimée')
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') { toastr.error('La suppression a échoué') }
    }
  }

  drop (e) {
    const multiple = !!this.inputTarget.getAttribute('multiple')
    this.removeDraggingClass()

    const { files } = e.dataTransfer
    const dataTransfer = new DataTransfer()
    Array.prototype.forEach.call(files, file => {
      dataTransfer.items.add(file)
      if (!multiple) {
        return false
      }
      return file
    })

    this.inputTarget.files = dataTransfer.files
    const event = new Event('change')
    this.inputTarget.dispatchEvent(event)
  }
}
