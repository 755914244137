import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['insureeChoice', 'childrenCountContainer', 'childrenCountField']

  connect () {
    this.displayContainer()
  }

  displayContainer () {
    const value = this.insureeChoiceTarget.value
    if (value === 'with_partner_and_children' || value === 'with_children') {
      this.childrenCountContainerTarget.classList.remove('d-none')
      this.childrenCountFieldTarget.required = true
    } else {
      this.childrenCountContainerTarget.classList.add('d-none')
      this.childrenCountFieldTarget.value = null
      this.childrenCountFieldTarget.required = false
    }
  }
}
