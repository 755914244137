import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const fieldWithErrors = document.querySelectorAll('.new-file-input > .field_with_errors')

    if (fieldWithErrors) {
      Array.from(fieldWithErrors).forEach((fieldWithError) => {
        fieldWithError.parentElement.appendChild(fieldWithError.childNodes[0])
        fieldWithError.remove()
      })
    }
  }

  updateFileName () {
    const fileInput = document.querySelector('.new-file-upload-input')
    const fileNameArea = document.getElementById('uploaded-file-name')

    fileNameArea.innerHTML = fileInput.files[0].name
  }
}
