import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['field', 'barredEyeIcon', 'eyeIcon']

  hide () {
    this.fieldTargets.forEach(fieldTarget => (fieldTarget.type = 'password'))
    this.eyeIconTargets.forEach(eyeIconTarget => eyeIconTarget.classList.remove('d-none'))
    this.barredEyeIconTargets.forEach(barredEyeIconTarget => barredEyeIconTarget.classList.add('d-none'))
  }

  show () {
    this.fieldTargets.forEach(fieldTarget => (fieldTarget.type = 'text'))
    this.eyeIconTargets.forEach(eyeIconTarget => eyeIconTarget.classList.add('d-none'))
    this.barredEyeIconTargets.forEach(barredEyeIconTarget => barredEyeIconTarget.classList.remove('d-none'))
  }
}
