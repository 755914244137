import { Controller } from 'stimulus'
import Chart from 'highcharts/es-modules/Core/Chart/Chart.js'
import Bar from 'highcharts/es-modules/Series/Bar/BarSeries.js' // eslint-disable-line
import Highcharts from 'highcharts/highmaps.js'

export default class extends Controller {
  static targets = ['agePyramid']

  #categories = ['Moins de 30 ans', 'Entre 30 et 39 ans', 'Entre 40 et 49 ans', 'Plus de 50 ans']

  connect () {
    this.drawAgePyramidChart()
  }

  drawAgePyramidChart () {
    const myChart = new Chart('agePyramid', { // eslint-disable-line
      chart: {
        type: 'bar',
        style: {
          fontFamily: 'Avenir Next'
        }
      },
      title: {
        text: 'Pyramide des âges de l\'entreprise',
        style: {
          'font-size': '1.571428571rem',
          color: '#E8A23B',
          'font-weight': '600'
        }
      },
      xAxis: [{
        categories: this.#categories,
        reversed: false,
        labels: {
          step: 1
        }
      }, {
        opposite: true,
        reversed: false,
        categories: this.#categories,
        linkedTo: 0,
        labels: {
          step: 1
        }
      }],
      yAxis: {
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value) + '%'
          }
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      tooltip: {
        formatter: function () {
          return `Catégorie: <b>${this.series.name}, ${this.point.category}</b><br/>Part dans l'entreprise: <b>${Highcharts.numberFormat(Math.abs(this.point.y), 1)}%</b>`
        }
      },
      series: [{
        name: 'Homme',
        data: JSON.parse(this.agePyramidTarget.dataset.agePyramid).man,
        color: '#FF7B44'
      }, {
        name: 'Femme',
        data: JSON.parse(this.agePyramidTarget.dataset.agePyramid).woman,
        color: '#6D092A'
      }]
    })
  }
}
