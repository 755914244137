import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  connect () {
    this.getQueryConditionTour($('#modal-onboarding-contract-tour-start'))
  }

  async getQueryConditionTour (modal) {
    try {
      const data = await getQuery('/espace-entreprise/onboarding_contract_tour_condition')
      if (data.onboarding_contract_tour && window.innerWidth > 992) {
        const modalElement = document.querySelector(modal)
        const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
        modalInstance.show()
      } else {
        return false
      }
    } catch (e) {
      throw new Error(e)
    }
  }
}
