import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'textField',
    'templateSelect'
  ]

  handleTemplateChange (event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const templateContent = selectedOption.dataset.content || ''

    this.textFieldTarget.value = templateContent
  }
}
