import { Controller } from 'stimulus'
import Typed from 'typed.js'

interface TypedOptions  {
  strings?: string[];
  typeSpeed?: number;
  backSpeed?: number;
  backDelay?: number;
  loop?: boolean;
}

export default class extends Controller<HTMLFormElement> {
  static values = {
    strings: Array
  }

  declare stringsValue: Array<string>
  declare readonly hasStringsValue: Boolean

  connect (): void {
    if (!this.stringsValue) return

    const options: TypedOptions = {
      strings: this.stringsValue || [],
      typeSpeed: 80,
      backSpeed: 50,
      backDelay: 1100,
      loop: true
    }

    const typed = new Typed(this.element as HTMLFormElement, options) // eslint-disable-line
  }
}
