import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'card']
  static values = {
    tabId: String
  }

  connect () {
    this.changeTab(this.tabIdValue)
  }

  handleClick (e) {
    const nextId = e.currentTarget.dataset.id
    this.tabIdValue = nextId
    this.changeTab(nextId)
  }

  changeTab (id) {
    this.cardTargets.forEach(item => this.hide(item))
    const [element] = this.cardTargets.filter(item => item.dataset.id === id)
    this.show(element)

    this.tabTargets.forEach(item => item.classList.remove('PayfitTabs__Tab--active'))
    const [activeTab] = this.tabTargets.filter(item => item.dataset.id === id)
    activeTab.classList.add('PayfitTabs__Tab--active')
  }

  show (element) {
    element.classList.remove('d-none')
    element.classList.add('d-block')
  }

  hide (element) {
    element.classList.add('d-none')
    element.classList.remove('d-block')
  }
}
