import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['interviewType', 'templateInfo', 'employeeInterviewInfo', 'managerSection', 'submitButton']

  connect () {
    this.changeInfoDisplay()
    $('[data-select="select2-type"]').on('select2:select', function (e) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
    $('[data-select="select2-employee"]').on('select2:select', function (e) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  changeInfoDisplay () {
    if (this.hasTemplateInfoTarget) {
      this.templateInfoTarget.remove()
    }
    const url = this.interviewTypeTarget.selectedOptions[0].dataset.url
    const name = this.interviewTypeTarget.selectedOptions[0].dataset.name
    if (url == null) {
      this.employeeInterviewInfoTarget.classList.add('d-none')
    } else {
      this.employeeInterviewInfoTarget.classList.remove('d-none')
      this.employeeInterviewInfoTarget.insertAdjacentHTML('afterend', this.templateText(name, url))
    }
  }

  templateText (name, url) {
    return `
    <div class="form-group row" data-employee-interviews--form-target='templateInfo'>
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="fw-normal">
            Un modèle ${this.startsWithVowel(name) ? "d'" : 'de '}${name} est disponible : téléchargez-le pour l'utiliser durant votre entretien. Vous pourrez ensuite importer le compte rendu sur la page de modification de l’entretien.
          </label>
          <a href="${url}" target="_blank" class="btn btn-lg btn-outline-color-secondary"><i class="fa fa-download color-secondary"></i> Télécharger le modèle</a>
        </div>
      </div>
    </div>
    `
  }

  hideInfo () {
    this.employeeInterviewInfoTarget.classList.add('d-none')
  }

  displayInfo () {
    this.employeeInterviewInfoTarget.classList.remove('d-none')
  }

  async getManager (e) {
    const formData = new FormData()
    const employeeName = e.currentTarget.options[e.currentTarget.selectedIndex].text
    const employeeId = e.currentTarget.value
    formData.append('selected_employee_id', employeeId)
    const data = await this.postQuery('/espace-entreprise/salaries/get_manager', formData)
    if (data.manager) {
      this.displayManager(data.manager)
      this.submitButtonTarget.disabled = false
    } else {
      this.displayManagerModal(employeeName, employeeId)
      this.submitButtonTarget.disabled = true
    }
  }

  displayManager (manager) {
    const managerText = `
                          <div>${manager.first_name} ${manager.last_name}
                          <input value="${manager.id}" type="hidden" name="employee_interview[employee_manager_id]" id="employee_interview_employee_manager_id">
                        `
    this.managerSectionTarget.innerHTML = managerText
  }

  displayManagerModal (employeeName, employeeId) {
    const modalText = `
                        <div>${employeeName} n'a pas encore de manager.</div>
                        <u>
                          <a class="color-cyan" data-remote="true" href="/espace-entreprise/salaries/${employeeId}/new_manager?nested_route=false">Attribuez-lui un manager afin de programmer un entretien.</a>
                        </u>
                      `
    this.managerSectionTarget.innerHTML = modalText
  }

  async postQuery (url, body) {
    const response = await fetch(url, {
      method: 'POST',
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': this.getToken()
      },
      body
    })
    const data = await response.json()
    return data
  }

  getToken () {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  startsWithVowel (word) {
    const vowels = ('aeiouAEIOU')
    return vowels.indexOf(word[0]) !== -1
  }
}
