import { Controller } from 'stimulus'

interface CustomEvent extends Event {
  currentTarget: HTMLElement;
  params: {
    id: string;
  };
}

export default class extends Controller<HTMLFormElement> {
  static targets: string[] = ['count', 'card', 'subtitle']
  static values = { offcanvasId: String }

  declare readonly cardTargets: HTMLFormElement[]
  declare readonly countTarget: HTMLFormElement
  declare readonly subtitleTarget: HTMLFormElement
  declare offcanvasIdValue: string

  connect(): void {
    this.countTarget.textContent = `${this.activeCardsLength()}`
  }

  handleClick(event: CustomEvent): void {
    const tables: NodeListOf<HTMLElement> = document.querySelectorAll('.ComparisonFilteOffcanvas__FeatureCategoryId');
    const table: HTMLElement | undefined = Array.from(tables).find(
      (table) => table.getAttribute('data-category-id') == event.params.id
    );

    if (table) {
      if (table.classList.contains('d-none')) {
        table.classList.remove('d-none');
        event.currentTarget.classList.add('Comparisons__CategoryCard--active');
      } else {
        table.classList.add('d-none');
        event.currentTarget.classList.remove('Comparisons__CategoryCard--active');
      }
    }

    this.countTarget.textContent = `${this.activeCardsLength()}`
    if (this.activeCardsLength() === 0) {
      this.subtitleTarget.innerHTML = `Vous ne comparez aucune garantie. ${this.clickHereLink()} pour en ajouter.`
    } else if (this.activeCardsLength() === this.cardTargets.length) {
      this.subtitleTarget.innerHTML = `Vous comparez toutes les garanties des offres sélectionnées.`
    } else {
      this.subtitleTarget.innerHTML = `Vous comparez les garanties : ${this.activeCardCategoryNames()}. Envie de voir les les autres ? ${this.clickHereLink()}.`
    }
  }

  activeCards(): HTMLElement[] {
    return this.cardTargets.filter(item => item.classList.contains('Comparisons__CategoryCard--active'))
  }

  activeCardsLength(): number{
    return this.activeCards().length
  }

  clickHereLink(): string {
    return `<span class="cursor-pointer fw-semibold text-underline" data-bs-toggle="offcanvas" data-bs-target="#${this.offcanvasIdValue}">Cliquez-ici</span>`
  }

  activeCardCategoryNames(): string {
    return this.activeCards().map(item => item.textContent).join(', ')
  }
}

