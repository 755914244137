import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputFile', 'tagInformation', 'submitButton']

  validateFiles () {
    if (!this.validExtention(this.inputFileTarget.files)) {
      const extErrorMessage = `
        <div class="mt-15 text-danger">
          Notre outil accepte uniquement les fichiers avec les extensions suivantes: .xlsx et .xlsm
        </div>`

      this.tagInformationTarget.innerHTML = extErrorMessage
      this.inputFileTarget.value = ''
      this.submitButtonTarget.disabled = true
    } else {
      this.tagInformationTarget.innerHTML = `<div class="mt-15">${this.inputFileTarget.files.item(0).name}</div>`
      this.submitButtonTarget.disabled = false
    }
  }

  validExtention (files) {
    const allowedExtension = ['xlsx', 'xlsm']
    let resp = true
    let file
    let extName

    for (let i = 0; i < files.length; i++) {
      file = files.item(i)
      extName = file.name.split('.').pop()

      if (!allowedExtension.includes(extName)) {
        resp = false
      }
    }

    return resp
  }
}
