import jsxElem from 'jsx-no-react' // eslint-disable-line

function collegeErrorMessage () {
  return (
    <small className='color-red' data-employees--professional-information-target='errorMessage'>
      Veuillez choisir un collège différent du collège actuel de l&apos;employé.
    </small>
  )
}

export { collegeErrorMessage }
