import { Controller } from 'stimulus'

const ocrApiBaseUrl = 'https://hoggo-ocr-api.herokuapp.com/api/v1'
const ocrRibApiBaseUrl = 'https://hoggo-ocr-api.herokuapp.com/api/v2'
const acceptedExtensions = ['pdf', 'jpeg', 'jpg', 'png']
export default class extends Controller {
  static targets = ['ibanField', 'bicField', 'loader']

  static values = {
    ocrApiKey: String
  }

  async processUploadedFile (e) {
    try {
      this.displayLoader()
      const file = e.target.files[0]
      const temporaryTokenResponse = await this.getTemporaryToken()
      if (file && acceptedExtensions.includes(file.extension.toLowerCase()) && temporaryTokenResponse) {
        const ibanResponse = await this.sendFile(file, temporaryTokenResponse.temporary_token)
        if (ibanResponse.IBAN_prettify && this.hasIbanFieldTarget) {
          this.displayIban(ibanResponse.IBAN_prettify)
        }
        if (ibanResponse.bic && this.hasBicFieldTarget) {
          this.displayBic(ibanResponse.bic)
        }
      }
    } catch {
    } finally {
      this.hideLoader()
    }
  }

  decodeKey (key) {
    return decodeURIComponent(escape(window.atob(key)))
  }

  displayLoader () {
    if (!this.hasLoaderTarget) return

    this.loaderTarget.classList.remove('d-none')
  }

  hideLoader () {
    if (!this.hasLoaderTarget) return

    this.loaderTarget.classList.add('d-none')
  }

  async getTemporaryToken () {
    if (this.decodeKey(this.ocrApiKeyValue)) {
      try {
        const response = await fetch(`${ocrApiBaseUrl}/temporary_token`, {
          method: 'POST',
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.decodeKey(this.ocrApiKeyValue)}`
          },
          body: {}
        })
        return response.json()
      } catch {
        this.hideLoader()
      }
    }
  }

  async sendFile (file, temporaryToken) {
    if (file && temporaryToken) {
      const formData = new FormData()
      formData.append('rib_file', file)
      formData.append('temporary_token', temporaryToken)
      try {
        const response = await fetch(`${ocrRibApiBaseUrl}/rib`, {
          method: 'POST',
          dataType: 'json',
          processData: false,
          contentType: false,
          headers: {
            Authorization: `Bearer ${this.decodeKey(this.ocrApiKeyValue)}`
          },
          body: formData
        })
        return response.json()
      } catch {
        this.hideLoader()
      }
    }
  }

  displayIban (iban) {
    this.ibanFieldTarget.value = iban
    this.ibanFieldTarget.dispatchEvent(new Event('keyup'))
  }

  displayBic (bic) {
    this.bicFieldTarget.value = bic
    this.bicFieldTarget.dispatchEvent(new Event('keyup'))
  }
}
