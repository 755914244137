import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'
import { PIE_OPTIONS, LINE_OPTIONS, DEPARTURES_AND_ARRIVALS_PER_MONTH_OPTIONS } from './statistics_options'

export default class extends Controller {
  static targets = ['contractRepartition', 'genderRepartition', 'collegeRepartition', 'employeesPerMonth', 'selectYear', 'selectYearSecond', 'departuresAndArrivalsPerMonth']

  connect () {
    if (this.hasContractRepartitionTarget) {
      google.charts.load('current', { packages: ['corechart'] })
      // google take a reference to the function as an argument so i need
      // to bind 'this' (here the stimulus controller) to the function
      // https://discuss.hotwired.dev/t/integrating-google-charts/745
      google.charts.setOnLoadCallback(this.drawContractRepartitionChart.bind(this))
      google.charts.setOnLoadCallback(this.drawGenderRepartitionChart.bind(this))
      google.charts.setOnLoadCallback(this.drawCollegeRepartitionChart.bind(this))
      google.charts.setOnLoadCallback(this.createEmployeesPerMonthChart.bind(this))
      google.charts.setOnLoadCallback(this.createDeparturesAndArrivalsPerMonthChar.bind(this))

      window.addEventListener('resize', () => {
        this.drawContractRepartitionChart()
        this.drawGenderRepartitionChart()
        this.drawCollegeRepartitionChart()
        this.createEmployeesPerMonthChart()
        this.createDeparturesAndArrivalsPerMonthChar()
      })
    }
  }

  createEmployeesPerMonthChart () {
    const data = JSON.parse(this.employeesPerMonthTarget.dataset.employees)
    this.drawEmployeesPerMonthChart(data)
  }

  async updateEmployeesPerMonthChart () {
    const formData = new FormData(this.selectYearTarget)

    const periodValue = formData.get('period')
    const userProIdValue = formData.get('user_pro_id')
    const urlValue = formData.get('url')

    const url = new URL(urlValue)
    url.searchParams.set('period', periodValue)
    url.searchParams.set('user_pro_id', userProIdValue)

    const res = await getQuery(url)
    const data = res.result.employees_count_per_month
    this.drawEmployeesPerMonthChart(data)
  }

  drawEmployeesPerMonthChart (data) {
    const lineChart = new google.visualization.LineChart(this.employeesPerMonthTarget)
    const chartData = new google.visualization.arrayToDataTable(data) // eslint-disable-line
    lineChart.draw(chartData, LINE_OPTIONS)
  }

  drawDeparturesAndArrivalsPerMonthChart (data) {
    const chartData = new google.visualization.DataTable() // eslint-disable-line
    chartData.addColumn('string', 'Mois')
    chartData.addColumn('number', 'Départs')
    chartData.addColumn({ type: 'string', role: 'tooltip' })
    chartData.addColumn('number', 'Arrivées')
    chartData.addColumn({ type: 'string', role: 'tooltip' })
    chartData.addRows(data)

    const chart = new google.visualization.ColumnChart(this.departuresAndArrivalsPerMonthTarget)
    chart.draw(chartData, DEPARTURES_AND_ARRIVALS_PER_MONTH_OPTIONS)
  }

  drawRepartitionChart (data, target) {
    const chartData = new google.visualization.arrayToDataTable(data) // eslint-disable-line
    const pieChart = new google.visualization.PieChart(target)
    pieChart.draw(chartData, PIE_OPTIONS)
  }

  drawContractRepartitionChart () {
    const contractData = JSON.parse(this.contractRepartitionTarget.dataset.contracts)
    this.drawRepartitionChart(contractData, this.contractRepartitionTarget)
  }

  drawGenderRepartitionChart () {
    const genderData = JSON.parse(this.genderRepartitionTarget.dataset.genders)
    this.drawRepartitionChart(genderData, this.genderRepartitionTarget)
  }

  drawCollegeRepartitionChart () {
    const collegeData = JSON.parse(this.collegeRepartitionTarget.dataset.college)
    this.drawRepartitionChart(collegeData, this.collegeRepartitionTarget)
  }

  createDeparturesAndArrivalsPerMonthChar () {
    const data = JSON.parse(this.departuresAndArrivalsPerMonthTarget.dataset.departuresAndArrivals)
    this.drawDeparturesAndArrivalsPerMonthChart(data)
  }

  async updateDeparturesAndArrivalsPerMonthChar () {
    const formData = new FormData(this.selectYearSecondTarget)

    const periodValue = formData.get('period')
    const userProIdValue = formData.get('user_pro_id')
    const urlValue = formData.get('url')

    const url = new URL(urlValue)
    url.searchParams.set('period', periodValue)
    url.searchParams.set('user_pro_id', userProIdValue)

    const res = await getQuery(url)

    const data = res.result.departures_and_arrivals
    this.drawDeparturesAndArrivalsPerMonthChart(data)
  }
}
