import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['warningMessageOne', 'warningMessageMultiple', 'badgeNew', 'badgeActual', 'employeePrice', 'userProPrice', 'insureesRecap', 'buttonSubmit']
  static values = { affiliationId: String }

  async changeVisibleBlocks (card) {
    const cardsChecked = document.querySelectorAll('input[type=checkbox]:checked')
    this.getVisibleWarning(cardsChecked)
    try {
      const params = Array.from(document.querySelectorAll('input[type=checkbox]:checked')).map((item) => `health_mutual_insuree_ids[]=${item.value}`).join('&')
      const newPriceHash = await getQuery(`/espace-salarie/mon-contrat-sante/${this.affiliationIdValue}/health_mutual_insurees/nouveau-prix-suppression-assuree?${params}`)
      this.showNewPrice(newPriceHash, cardsChecked)
    } catch (e) {
      throw new Error(e)
    }
  }

  getVisibleWarning (cardsChecked) {
    if (cardsChecked.length === 1) {
      this.warningMessageOneTarget.classList.remove('d-none')
      this.warningMessageMultipleTarget.classList.add('d-none')
    } else if (cardsChecked.length > 1) {
      this.warningMessageOneTarget.classList.add('d-none')
      this.warningMessageMultipleTarget.classList.remove('d-none')
    } else if (cardsChecked.length === 0) {
      this.warningMessageOneTarget.classList.add('d-none')
    }
  }

  showNewPrice (newPriceHash, cardsChecked) {
    this.employeePriceTarget.textContent = newPriceHash.employee_price
    this.userProPriceTarget.textContent = newPriceHash.user_pro_price
    this.insureesRecapTarget.textContent = newPriceHash.insurees_recap
    if (cardsChecked.length === 0) {
      this.badgeActualTarget.classList.remove('d-none')
      this.badgeNewTarget.classList.add('d-none')
      this.buttonSubmitTarget.classList.add('d-none')
    } else {
      this.badgeNewTarget.classList.remove('d-none')
      this.badgeActualTarget.classList.add('d-none')
      this.buttonSubmitTarget.classList.remove('d-none')
    }
  }
}
