import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal']

  connect () {
    const modal = bootstrap.Modal.getOrCreateInstance(this.modalTarget) // eslint-disable-line
    modal.show()
  }
}
