import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['arrow']

  rotateArrow () {
    this.arrowTarget.style.transition = '0.6s cubic-bezier(0.34, 1.56, 0.64, 1)'
    if (this.arrowTarget.style.transform) {
      this.arrowTarget.style.removeProperty('transform')
    } else {
      this.arrowTarget.style.transform = 'rotate(180deg)'
    }
  }
}
