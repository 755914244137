import { BaseController } from './base_controller'
import screen from 'images/productTour/screen_employee_health_care.webp'
import screenTpCard from 'images/productTour/tp_card.webp'
import { patchQuery } from 'helpers/requests'

export default class extends BaseController {
  static targets = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6']

  generateSteps (tour) {
    let steps = []

    switch (this.typeValue) {
      case 'healthCareIndex':
        steps = this.healthCareMapping(tour)
        break
      case 'tpCard':
        steps = this.tpCardMapping(tour)
        break
      case 'garantee':
        steps = this.garanteeMapping(tour)
        break
      default:
        steps = []
        break
    }

    return steps
  }

  handleHideTour () {
    this.finishOrCancelTour()
  }

  handleCompleteTour () {
    this.finishOrCancelTour()
  }

  async finishOrCancelTour () {
    try {
      await patchQuery('/espace-salarie/profil/update_shareable', JSON.stringify({ employee: { finish_affiliation_tour: false } }))
    } catch (e) {
      throw new Error(e)
    }
  }

  tpCardMapping (tour) {
    if (!this.hasStep6Target) return []

    return [{
      title: `${this.generateStepNumber(6, 6)} Votre carte tiers payant`,
      text: `<img class="w-100" src="${screenTpCard}" /> <br/> <br/> C’est ici que vous pourrez retrouver votre carte de tiers payant lorsqu’elle sera disponible (48h après avoir complété votre affiliation). Vous pourrez également suivre la mise en place de la télétransmission. `,
      attachTo: {
        element: this.step6Target,
        on: 'right'
      },
      buttons: [
        {
          classes: this.nextButtonClasses(),
          text: 'Terminer',
          action: tour.next
        }
      ]
    }]
  }

  garanteeMapping (tour) {
    if (!this.hasStep5Target) return []

    return [{
      title: `${this.generateStepNumber(5, 6)} Vos garanties`,
      text: 'Pour accéder aux détails des garanties de votre contrat de mutuelle c’est par ici !',
      attachTo: {
        element: this.step5Target,
        on: 'bottom'
      },
      buttons: [
        {
          classes: this.nextButtonClasses(),
          text: 'Suivant',
          action: () => { window.location.href = this.nextPageUrlValue }
        }
      ]
    }]
  }

  healthCareMapping (tour) {
    if (!this.hasStep1Target && !this.hasStep2Target && !this.hasStep3Target && !this.hasStep4Target) return []

    return [{
      title: `${this.generateStepNumber(1, 6)} Suivez vos remboursements !`,
      text: `<img class="w-100" src="${screen}" /> <br/> <br/> Suivi des soins, des virements reçus, demander un remboursement ou consulter les demandes passées.`,
      attachTo: {
        element: this.step1Target,
        on: 'right'
      },
      buttons: [
        {
          classes: this.nextButtonClasses(),
          text: 'Suivant',
          action: tour.next
        }
      ]
    }, {
      title: `${this.generateStepNumber(2, 6)} Suivi et détails de vos soins`,
      text: 'Toutes les infos nécessaires au suivi de vos soins se trouvent ici ! Dates et montants, remboursements, restes à charge, etc. ',
      attachTo: {
        element: this.step2Target,
        on: 'bottom'
      },
      buttons: [
        {
          classes: this.nextButtonClasses(),
          text: 'Suivant',
          action: tour.next
        }
      ]
    }, {
      title: `${this.generateStepNumber(3, 6)} Virements`,
      text: 'Retrouvez ici tous les virements reçus de la part de SideCare suite à vos consultations',
      attachTo: {
        element: this.step3Target,
        on: 'bottom'
      },
      buttons: [
        {
          classes: this.nextButtonClasses(),
          text: 'Suivant',
          action: tour.next
        }
      ]
    }, {
      title: `${this.generateStepNumber(4, 6)} Des questions sur un remboursement ?`,
      text: 'Retrouvez ici notre numéro de téléphone. Un expert prendra en charge votre demande !',
      attachTo: {
        element: this.step4Target,
        on: 'bottom'
      },
      buttons: [
        {
          classes: this.nextButtonClasses(),
          text: 'Suivant',
          action: () => { window.location.href = this.nextPageUrlValue }
        }
      ]
    }]
  }
}
