import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['step', 'button', 'image', 'overlayButton']

  connect () {
    this.currentStep = 1
    this.showCurrentStep()
    this.updateButtonState()
    this.blurImageOnHover()
  }

  showCurrentStep () {
    this.stepTargets.forEach((step, index) => {
      if (index !== this.currentStep - 1) {
        step.classList.add('d-none')
        step.classList.remove('animate__animated', 'animate__fadeIn')
      } else {
        step.classList.remove('d-none')
        step.classList.add('animate__animated', 'animate__fadeIn')
      }
    })
  }

  updateButtonState () {
    this.buttonTargets.forEach((button, index) => {
      button.classList.toggle('selected', index === this.currentStep - 1)
    })
  }

  goToStep (event) {
    const targetStep = parseInt(event.currentTarget.dataset.step)
    if (!isNaN(targetStep)) {
      this.currentStep = targetStep
      this.showCurrentStep()
      this.updateButtonState()
    }
  }

  incrementedCurrentStep () {
    this.currentStep = this.currentStep < this.stepTargets.length ? this.currentStep + 1 : 1
  }

  decrementedCurrentStep () {
    this.currentStep = this.currentStep > 1 ? this.currentStep - 1 : this.stepTargets.length
  }

  next () {
    this.incrementedCurrentStep()
    this.showCurrentStep()
    this.updateButtonState()
  }

  previous () {
    this.decrementedCurrentStep()
    this.showCurrentStep()
    this.updateButtonState()
  }

  blurImageOnHover () {
    this.overlayButtonTarget.addEventListener('mouseover', (event) => {
      this.imageTarget.classList.add('blur')
    })
    this.overlayButtonTarget.addEventListener('mouseout', (event) => {
      this.imageTarget.classList.remove('blur')
    })
  }
}
