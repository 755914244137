import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['balanceField', 'balanceLabel', 'employeeSelect', 'absenceTypeSelect', 'employeeField', 'warning']
  static values = { role: String }

  url = (window.location.href).match(/espace-entreprise/) === null ? 'espace-salarie' : 'espace-entreprise'

  connect () {
    $('[data-select="select2-employee"]').on('select2:select', function (e) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })

    $('[data-select="no-search"]').on('select2:select', function (e) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })

    if (this.hasEmployeeFieldTarget) {
      this.employeeId = this.employeeFieldTarget.value
    } else if (this.hasEmployeeSelectTarget) {
      this.employeeId = this.employeeSelectTarget.value
    }

    this.absenceType = null
  }

  setEmployee (e) {
    this.employeeId = e.target.value
    this.getBalances()
  }

  setAbsenceType () {
    this.absenceType = this.absenceTypeSelectTarget.options[this.absenceTypeSelectTarget.selectedIndex].text
    this.getBalances()
  }

  checkAbsenceType () {
    switch (this.absenceType) {
      case 'RTT':
        this.displayWorkingTimeReductionBalance()
        break
      case 'Congé payé':
        this.displayAbsenceBalance()
        break
      case 'Absence formation en alternance':
        this.displayFormationMessage()
        break
      default:
        this.removeBalance()
    }
  }

  async getBalances () {
    let data = null
    if (this.roleValue === 'company-administrator') {
      data = await getQuery(`/${this.url}/salaries/${this.employeeId}`)
    } else if (this.roleValue === 'manager') {
      data = await getQuery(`/${this.url}/manager/mon-equipe/${this.employeeId}`)
    } else if (this.roleValue === 'employee') {
      data = await getQuery(`/${this.url}/conges-et-absences/balances`)
    }
    if (data) {
      this.absenceBalance = data.absence_balance
      this.workingTimeReductionBalance = data.working_time_reduction_balance
      this.checkAbsenceType()
    }
  }

  displayWorkingTimeReductionBalance () {
    this.balanceLabelTarget.innerHTML = 'Solde de RTT : '
    this.displayBalance(this.workingTimeReductionBalance)
  }

  displayAbsenceBalance () {
    this.balanceLabelTarget.innerHTML = 'Solde de congés payés : '
    this.displayBalance(this.absenceBalance)
  }

  displayFormationMessage () {
    if (this.roleValue !== 'employee') {
      this.balanceLabelTarget.innerHTML = "Cette absence sera présente dans la clôture mais n'entraîne pas de déduction de salaire."
      this.balanceFieldTarget.innerHTML = ''
    } else {
      this.removeBalance()
    }
  }

  displayBalance (balance) {
    if (balance != null) {
      if (balance < 0) {
        this.balanceFieldTarget.classList.add('color-red')
        this.warningTarget.classList.remove('d-none')
      } else {
        this.balanceFieldTarget.classList.remove('color-red')
        this.warningTarget.classList.add('d-none')
      }
      this.balanceFieldTarget.innerHTML = `${balance.toFixed(2).toString().replace('.', ',')}`
    } else {
      this.balanceFieldTarget.innerHTML = '-'
    }
  }

  removeBalance () {
    this.warningTarget.classList.add('d-none')
    this.balanceLabelTarget.innerHTML = ''
    this.balanceFieldTarget.innerHTML = ''
  }
}
