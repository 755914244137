import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fileExist', 'inputHidden']

  removeFile (_) {
    this.fileExistTarget.remove()
    this.inputHiddenTarget.value = '1'
    event.preventDefault()
  }
}
