import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tnsAlert', 'radio', 'nextQuestion', 'product']
  static values = {
    product: String,
    nextQuestion: Number
  }

  connect () {
    // filter radios to find default checked
    const [selected] = this.radioTargets.filter(item => item.checked === true)
    this.dipslayWarningAlert(selected.value, 'HealthMutual')
  }

  handleChange (e) {
    this.dipslayWarningAlert(e.target.value, e.params.product)
  }

  // Display alert when checked value is for TNS
  dipslayWarningAlert (value, product) {
    if (value === 'tns') {
      this.tnsAlertTargets.forEach(item => item.classList.remove('d-none'))
      this.productTargets.forEach(item => {
        if (item.dataset.productName !== product) {
          item.classList.add('d-none')
        }
      })
    } else {
      this.tnsAlertTargets.forEach(item => item.classList.add('d-none'))
      this.productTargets.forEach(item => {
        item.classList.remove('d-none')
      })
    }
  }
}
