import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['allEmployesInput', 'employeInput', 'employesCounterContainer']

  allEmployesInputHandler () {
    if (this.allEmployesInputTarget.checked) {
      this.employeInputTargets.forEach(el => { el.checked = true })
    } else {
      this.employeInputTargets.forEach(el => { el.checked = false })
    }
  }

  selectedEmployeesCounter () {
    const count = this.employeInputTargets.reduce((total, el) => el.checked ? total + 1 : total, 0)

    let message = ''
    if (count < 2) {
      message = `(${count} employé sélectionné)`
    } else {
      message = `(${count} employés sélectionnés)`
    }
    this.employesCounterContainerTargets.forEach(el => { el.innerHTML = message })
  }
}
