import { Controller } from 'stimulus'
import VanillaTilt from 'vanilla-tilt'

export default class extends Controller {
  static targets = ['card']

  connect () {
    VanillaTilt.init(this.cardTarget, {
      reverse: true,
      glare: true,
      max: 15,
      'max-glare': 0.3,
      speed: 3000
    })
  }
}
