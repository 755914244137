import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['card']

  connect () {
    setInterval(() => {
      this.handleClick()
    }, 5000)
  }

  handleClick (e) {
    const [current] = this.cardTargets.filter(item => item.classList.contains('cards-animation--cards--current'))
    const next = current.nextElementSibling

    this.cardTargets.forEach(item => item.classList.remove('cards-animation--cards--last', 'cards-animation--cards--current', 'cards-animation--cards--out', 'cards-animation--cards--next'))
    current.classList.add('cards-animation--cards--out')

    if (next) {
      next.classList.add('cards-animation--cards--current')
      this.setNextElement(next.nextElementSibling)
    } else {
      this.cardTargets[0].classList.add('cards-animation--cards--current')
      this.setNextElement(this.cardTargets[0].nextElementSibling)
    }
  }

  setNextElement (nextElement) {
    if (nextElement) {
      nextElement.classList.add('cards-animation--cards--next')
    } else {
      this.cardTargets[0].classList.add('cards-animation--cards--next')
    }
  }
}
