import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []
  static values = {
    displayAll: Boolean
  }

  connect () {
    const that = this
    if (this.displayAllValue === true) {
      $('[data-action="basic"]').prop('checked', !this.displayAllValue)
    }
    window.count_column = $('[data-style="column-comparison-base"]:not(.d-none)').length
    this.radioPersonalizeCheck()
    this.updateColumnShow()

    $('input:radio[name=base_options]').on('change', function () {
      that.updateColumnShow()
    })

    $('input[name*=filter]').each(function (index, item) {
      that.changeCheckboxFilter($(this).data('element'))
    })
  }

  updateColumnShow () {
    if ($('[data-action="custom"]').is(':checked')) return this.radioPersonalizeCheck()
    if ($('[data-action="basic"]').is(':checked')) return this.radioAllBaseCheck()
    if ($('[data-action="all"]').is(':checked')) return this.radioAllOptionsCheck()
  }

  /** Calculate column width. */
  calculate_column_size () {
    const width = 75.0 / $('[data-style="column-comparison-base"]:not(.d-none)').length
    $('[data-target="column-comparison-action"]:not(.d-none)').css('width', `${width}%`)
    $('[data-type="social-convention"]').css('width', `${width}%`)
  }

  /**
   * Add an event to all checkboxes
   * @param {string} target - The target which is the value of the data elements
   */
  changeCheckboxFilter (target) {
    const that = this

    $(`[data-element=${target}]`).on('change', function () {
      that.updateColumnShowPersonnal()
      that.updateUrlDataElement()
    })
  }

  updateColumnShowPersonnal (target) {
    $('input[name*=filter]').each(function (index, item) {
      const target = $(this).data('element')

      if (window.count_column <= 7 && document.querySelector(`input[data-element=${target}]`).checked) {
        $(`[data-action=${target}]`).each(function () {
          $(this).removeClass('d-none')
        })
      } else {
        $(`[data-action=${target}]`).each(function () {
          $(this).addClass('d-none')
        })
      }
    })
    this.VerifyNumberOfComparisons()
    this.calculate_column_size()
  }

  /** We block the comparison to a maximum of 7 simultaneous proposals. */
  VerifyNumberOfComparisons () {
    // Count the number of visible columns
    window.count_column = $('[data-style="column-comparison-base"]:not(.d-none)').length
    if (window.count_column === 7) {
      // Check if there is more than one checkbox unchecked
      if ($('[data-type="option"]:not(:checked), [data-type="base"]:not(:checked)').length !== 0) {
        this.showInfoTag()
      }
      this.disableNotCheckedInput()
    } else {
      this.hideInfoTag()
      this.enableNotCheckedInput()
    }
  }

  /** Show blue info tag. */
  showInfoTag () {
    $('.info-tag').removeClass('d-none')
  }

  /** Hide blue info tag. */
  hideInfoTag () {
    $('.info-tag').addClass('d-none')
  }

  disableNotCheckedInput () {
    $('[data-type="option"]:not(:checked) + label, [data-type="base"]:not(:checked) + label').addClass('disable')
    $('[data-type="option"]:not(:checked), [data-type="base"]:not(:checked)').attr('disabled', true)
  }

  enableNotCheckedInput () {
    $('[data-type="option"]:not(:checked) + label, [data-type="base"]:not(:checked) + label').removeClass('disable')
    $('[data-type="option"]:not(:checked), [data-type="base"]:not(:checked)').attr('disabled', false)
  }

  displayAllBases () {
    $('[data-action*="base"]').removeClass('d-none')
    $('[data-action*="option"]').addClass('d-none')
  }

  displayAllOptions () {
    $('[data-action*="base"]').addClass('d-none')
    $('[data-action*="option"]').removeClass('d-none')
  }

  openCollapseFilter () {
    $('[data-action="collapse_element"]').removeClass('hidden')
  }

  closeCollapseFilter () {
    $('[data-action="collapse_element"]').addClass('hidden')
  }

  /** when you click on the radio personalize. */
  radioPersonalizeCheck () {
    this.updateUrl('data_action', 'custom')
    this.openCollapseFilter($(this).data('element'))
    this.updateColumnShowPersonnal()
  }

  /** when you click on the radio All Bases. */
  radioAllBaseCheck () {
    this.updateUrl('data_action', 'basic')
    this.removeUrlDataElement()
    this.closeCollapseFilter()
    this.displayAllBases()
    this.calculate_column_size()
    this.hideInfoTag()
  }

  /** when you click on the radio All Options. */
  radioAllOptionsCheck () {
    this.updateUrl('data_action', 'all')
    this.removeUrlDataElement()
    this.closeCollapseFilter()
    this.displayAllOptions()
    this.calculate_column_size()
    this.hideInfoTag()
  }

  updateUrlDataElement () {
    const dataElement = []
    $('input[name*=filter]').each(function (index, item) {
      const target = $(this).data('element')

      if (document.querySelector(`input[data-element=${target}]`).checked) {
        dataElement.push(target)
      }
    })

    this.updateUrl('data_element', dataElement)
  }

  removeUrlDataElement () {
    const selector = '[data-check="copy_text"]'
    if (document.querySelector(selector) != null) {
      const copyText = document.querySelector(selector).value.trim()
      const url = new URL(copyText)
      url.searchParams.delete('data_element')
      document.querySelector(selector).value = url.href
    }
  }

  updateUrl (key, value) {
    const selector = '[data-check="copy_text"]'
    if (document.querySelector(selector) != null) {
      const copyText = document.querySelector(selector).value.trim()
      const url = new URL(copyText)
      url.searchParams.set(key, value)
      document.querySelector(selector).value = url.href
    }
  }
}
