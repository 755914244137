import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  async handleClick () {
    $('[data-check="banner"]').hide(400, 'linear')
    const response = await getQuery('/espace-entreprise/integrations/payfit/update_session')
    return response
  }
}
