import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['optone', 'opttwo', 'selectedOpt']

  connect () {
    this.applyBorderColor(this.selectedOptTarget.value)
  }

  clickRadioBtn (event) {
    const form = this.optoneTarget.closest('form')
    this.applyBorderColor(event.target.value)
    form.submit()
  }

  applyBorderColor (value) {
    if (value === 'optone') {
      this.optoneTarget.classList.add('border-primary')
      this.opttwoTarget.classList.remove('border-primary')
    } else if (value === 'opttwo') {
      this.optoneTarget.classList.remove('border-primary')
      this.opttwoTarget.classList.add('border-primary')
    } else {
      this.optoneTarget.classList.add('border-primary')
      this.opttwoTarget.classList.remove('border-primary')
    }
  }
}
