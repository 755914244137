import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['canvas']

  static values = {
    data: Array,
    maintainAspectRatio: Boolean
  }

  connect () {
    const data = {
      datasets: [{
        label: 'Taux de bien-être',
        backgroundColor: '#6D092A',
        borderWidth: 0,
        borderRadius: 5,
        barThickness: 50,
        data: this.dataValue
      }]
    }

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div')

      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.background = '#FFFFFF'
        tooltipEl.style.borderRadius = '5px'
        tooltipEl.style.height = '53px'
        tooltipEl.style.width = '200px'
        tooltipEl.style.color = 'black'
        tooltipEl.style.zIndex = 999
        tooltipEl.style.fontSize = '12px'
        tooltipEl.style.opacity = 1
        tooltipEl.style.fontWeight = 400
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.transform = 'translate(-50%, 0)'
        tooltipEl.style.transition = 'all .1s ease'
        tooltipEl.style.border = 'solid #E7E7E7'
        chart.canvas.parentNode.appendChild(tooltipEl)
      }

      return tooltipEl
    }

    const externalTooltipHandler = (context) => {
      const { chart, tooltip } = context
      const tooltipEl = getOrCreateTooltip(chart)

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set Text
      if (tooltip.body) {
        const bodyLines = tooltip.body.map(b => b.lines)

        const container = document.createElement('div')
        container.style.textAlign = 'center'

        bodyLines.forEach((body, i) => {
          const text = document.createTextNode(`${body[0].trim()}%`)
          const totalResponses = document.createTextNode(`Nombre de réponses: ${tooltip.dataPoints[0].raw.total_responses}`)
          container.appendChild(text)
          container.appendChild(document.createElement('br'))
          container.appendChild(totalResponses)
        })

        while (tooltipEl.firstChild) {
          tooltipEl.firstChild.remove()
        }

        tooltipEl.appendChild(container)
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

      tooltipEl.style.opacity = 1
      tooltipEl.style.left = `${positionX + tooltip.caretX}px`
      tooltipEl.style.top = `${positionY + tooltip.caretY - 60}px`
      tooltipEl.style.font = tooltip.options.bodyFont.string
      tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`
    }

    const myChart = new Chart( // eslint-disable-line
      this.canvasTarget,
      {
        type: 'bar',
        data,
        options: {
          maintainAspectRatio: this.maintainAspectRatioValue,
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#130F41',
                font: {
                  size: 13
                }
              },
              grid: {
                display: false,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: true
              }
            },
            y: {
              min: 0,
              max: 100,
              ticks: {
                beginAtZero: true,
                color: '#7C828A',
                stepSize: 25,
                font: {
                  size: 13
                },
                callback: (value, index, values) => `${value}%  `
              },
              grid: {
                drawBorder: false,
                lineWidth: 2,
                drawTicks: false,
                borderDash: [1, 4],
                color: (context) => '#7C828A'
              }
            }
          }
        }
      }
    )
  }
}
