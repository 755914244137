import { Controller } from 'stimulus'
import { fileInfos } from 'src/js/templates/employee_administration/support_tickets/new.jsx'

export default class extends Controller {
  static targets = ['filesInput', 'filesArea', 'fileName']

  insertFilesNames () {
    this.filesAreaTarget.innerHTML = ''
    Array.from(this.filesInputTarget.files).forEach((file, index) => {
      this.filesAreaTarget.append(fileInfos(file.name, index))
    })

    this.fileNameTargets.forEach(fileName => {
      fileName.addEventListener('click', e => {
        fileName.value = ''
        fileName.innerHTML = ''
      })
    })
  }
}
