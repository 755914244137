import { BaseController } from '../base_controller'
import Highcharts from 'highcharts/highmaps.js'

export default class extends BaseController {
  static values = {
    containerId: String,
    yAxisLabel: String,
    series: Array,
    categories: Array,
    unit: String,
    colors: Array
  }

  formatSeries () {
    return this.seriesValue.map(item => {
      return {
        ...item,
        events: {
          legendItemClick: function (e) {
            e.preventDefault()
          }
        }
      }
    })
  }

  connect () {
    Highcharts.chart(this.containerIdValue, {
      chart: {
        type: 'area'
      },
      title: {
        text: ''
      },
      yAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: this.yAxisLabelValue
        }
      },
      xAxis: {
        categories: this.categoriesValue,
        minRange: 1,
        labels: {
          rotation: 0,
          style: {
            fontFamily: 'Avenir Next'
          }
        }
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          }
        },
        area: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      ...this.generateLegendConfig(),
      tooltip: {
        style: {
          fontFamily: 'Avenir Next'
        },
        crosshairs: true,
        shared: true,
        outside: true,
        headerFormat: '<span style="font-size:12px">{point.key}</span><br>',
        pointFormat: `<span style="color: {series.color}">{series.name}</span>: <b>{point.y:.2f} ${this.unitValue}</b> <br>`
      },
      colors: this.colorsValue,
      series: this.formatSeries(),
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    })
  }
}
