import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mainAccount', 'form']
  static values = {
    url: String
  }

  submitForm () {
    this.formTarget.submit()
  }
}
