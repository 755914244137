import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    optionMap: Object
  }

  static targets = ['userProId', 'caseContainer', 'birthDate', 'birthDateContainer', 'noRadioCheck']

  connect () {
    this.displayCase(this.userProIdTarget.value)
    $('[name="employee[user_pro_id]"').on('select2:select', () => {
      this.displayCase(this.userProIdTarget.value)
    })
  }

  displayCase (selectedValue) {
    const visible = this.optionMapValue[selectedValue]
    if (visible) {
      this.caseContainerTarget.classList.remove('d-none')
    } else {
      this.noRadioCheckTarget.checked = true
      this.caseContainerTarget.classList.add('d-none')
    }
  }

  showBirthDay () {
    this.birthDateContainerTarget.classList.remove('d-none')
    this.birthDateTarget.required = true
  }

  hideBirthDay () {
    this.birthDateContainerTarget.classList.add('d-none')
    this.birthDateTarget.required = false
  }
}
