import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['radio', 'userAdminIdSelectContainer', 'userAdminIdSelect']

  handleChange (e) {
    if (e.target.value === 'transfer_to_another_user_admin') {
      this.userAdminIdSelectContainerTarget.classList.remove('d-none')
    } else {
      this.userAdminIdSelectContainerTarget.classList.add('d-none')
      this.userAdminIdSelectTarget.value = null
      const changeEvent = new Event('change')
      this.userAdminIdSelectTarget.dispatchEvent(changeEvent)
    }
  }
}
