import jsxElem from 'jsx-no-react' // eslint-disable-line

function errorElement () {
  return (
    <div className="col-12" data-subscription-steps--data-treatment-agreement-component-target='errorMessage'>
      <small className='color-red'>Vous devez valider ces conditions afin d&apos;accéder à l&apos;étape suivante. Si ce n&apos;est pas le cas, veuillez nous contacter à <a className='color-red' href="mailto:help@sidecare.com"><u>help@sidecare.com</u></a></small>
    </div>
  )
}

function modalTitle (contractType) {
  if (contractType === 'collective_contract' || contractType === 'tns_contract') {
    return collectiveContractModalTitle()
  } else if (contractType === 'civil_liability_contract') {
    return civilLiabilityContractModalTitle()
  }
}

function modalBody (contractType, agreementTypeDue) {
  return (
    <small>
        En validant ce formulaire, vous reconnaissez :
        <ul>
          {(contractType === 'collective_contract' || contractType === 'tns_contract' || contractType === 'civil_liability_contract') && needsMessage()}
          {(contractType === 'collective_contract') && (agreementTypeDue === 'true') && dueMessage()}
          {(contractType === 'collective_contract') && paymentMessage()}
          {(contractType === 'collective_contract' || contractType === 'tns_contract') && pepMessage()}
        </ul>
    </small>
  )
}

function hiddenStepField (buttonStepValue) {
  return (<input type="hidden" name="step" id="step" value={buttonStepValue} autoComplete="off"></input>)
}

function civilLiabilityContractModalTitle () {
  return (
    <span>Mentions relatives au recueil de besoin</span>
  )
}

function collectiveContractModalTitle () {
  return (
    <span>Mentions relatives au recueil de besoin et aux personnes politiquement exposées</span>
  )
}

function needsMessage () {
  return (
    <li>
      avoir reçu plusieurs devis, en adéquation avec mon recueil de besoins, accompagnés des notices d’informations et des documents d’information sur le produit d’assurance (DIPA)
    </li>
  )
}

function dueMessage () {
  return (
    <li>
      avoir été informé de l’obligation de mise en place d’une Décision Unilatérale de l’Employeur (DUE)
    </li>
  )
}

function paymentMessage () {
  return (
    <li>
      que le retard de paiement des cotisations entraîne la radiation de mon contrat, la perte de couverture et la nécessité de nouvelles formalités médicales éventuelles pour une nouvelle adhésion en matière de prévoyance
    </li>
  )
}

function pepMessage () {
  return (
    <li>
      que les bénéficiaires effectifs ou dirigeants ont cessé d’exercer depuis moins d’un an une fonction politique, juridictionnelle ou administrative importante pour le compte d’un état, d’une haute autorité ou d’une administration, et qu’ils ne sont pas des proches descendants ou ascendants de personnes ayant exercé ce type de fonction. Si vous ne répondez pas à ces conditions, veuillez nous contacter à <a href="mailto:help@sidecare.com"><u>help@sidecare.com</u></a>
    </li>
  )
}

export { errorElement, modalTitle, modalBody, hiddenStepField }
