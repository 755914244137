import { Controller } from 'stimulus'
import Typed from 'typed.js'

export default class extends Controller<HTMLFormElement> {
  static targets: string[] = ['select', 'form']

  declare readonly selectTarget: HTMLSelectElement
  declare readonly formTarget: HTMLFormElement

  connect (): void {
    this.handleChange({ currentTarget: this.selectTarget })
  }

  handleChange (e): void {
    if (e.currentTarget.value) {
      this.formTarget.classList.add('d-none')
    } else {
      this.formTarget.classList.remove('d-none')
    }
  }
}
