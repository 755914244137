import jsxElem from 'jsx-no-react' // eslint-disable-line
import moment from 'moment'
import { csrfToken } from '@rails/ujs'
import warningTriangle from 'images/icons/icn24_warning_triangle.svg'
import { dateDisplayCalendar } from 'src/js/templates/shared/dateDisplayCalendar.jsx'

function itemTemplate (data) {
  moment.locale('fr')
  let durationCount

  if (data.get_duration > 1) {
    durationCount = `${data.get_duration} Jours`
  } else {
    durationCount = `${data.get_duration} Jour`
  }

  return (
    <li
      className="EmployeeAbsenceEvents_Card no-list-style border-radius-5 px-3 py-2 mb-2 cursor-pointer"
      data-employee-absence-events--employee-administration--manager-administration--show-target="listItem"
      data-action="click->employee-absence-events--employee-administration--manager-administration--show#handleClick"
      data-url={data.show_path}
      data-id={data.id}>
      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0 color-secondary fw-bold">{data.employee.full_name}</p>
        <span className={`text-muted point-before small ${`point-color-${data.colors_validation_state}`} ms-4`}>
          {data.validation_state_label}
        </span>
      </div>
      <p className="mb-0 small text-muted">
        Du {moment(data.start_datetime).format('D MMM YYYY')} au {moment(data.end_datetime).format('D MMM YYYY')} - {durationCount}
      </p>
      <p className="mb-0 small text-muted">
        {data.employee_absence_event_category.name}
      </p>
    </li>
  )
}

function truncateString (str) {
  if (str.length > 0) {
    return str.substring(0, 110)
  } else {
    return (
      <span className="color-tertiary">
        Non Renseigné
      </span>
    )
  }
}

function attachmentDocumentsCondition (data) {
  if (data.attachment_documents.length > 0) {
    return (
      <p className="color-secondary text-16 mt-3 mb-2">
        Justificatif :
        <ul className="colored-bullet list-unstyled ps-4">
          {data.attachment_documents.map(item => {
            return (
              <li key={item.small_image_url} className="bullet-cyan color-cyan">
                <a href={item.small_image_url} className="ps-2 color-text" target="_blank" rel="noreferrer">
                  <span>{item.name}</span> </a>
              </li>
            )
          })}
        </ul>
      </p>
    )
  } else {
    return (
      <form className="mt-3" data-action="attachment_absence" encType="multipart/form-data" action="/espace-salarie/attachment_document" acceptCharset="UTF-8" method="post" data-controller="shared--form">
        <input type="hidden" name="authenticity_token" value={csrfToken()} autoComplete="off" />
        <div className="form-group new-file-input">
          <input type="hidden" name="redirection_link" id={`attachment_document_redirection_link_${data.id}`} value={`http://localhost:3000/espace-salarie/manager/conges-et-absences?id=${data.id}`} autoComplete="off" />
          <input type="file" name="attachment_document[small_image]" id={`attachment_documents_small_image_${data.id}`} className="d-none" data-action="change->shared--form#submitForm" />
          <label htmlFor={`attachment_documents_small_image_${data.id}`}>
            <span className="font-w600"><i className="fa fa-cloud-upload" style="margin-right: 4px;"></i> Ajouter un justificatif</span>
          </label>
          <input type="hidden" name="attachment_document[small_image_cache]" id={`attachment_documents_small_image_cache_${data.id}`} value="" autoComplete="off" />
          <input type="hidden" name="attachment_document[document_type]" id={`attachment_documents_document_type_${data.id}`} value="absence_event_proof" autoComplete="off" />
          <input type="hidden" name="attachment_document[uploaded]" id={`attachment_documents_uploaded_${data.id}`} value="true" autoComplete="off" />
          <input type="hidden" name="attachment_document[source]" id={`attachment_documents_source_${data.id}`} value="espace-manager" autoComplete="off" />
          <input type="hidden" name="attachment_document[attachable_type]" id={`attachment_documents_attachable_type_${data.id}`} value="EmployeeAbsenceEvent" autoComplete="ioff" />
          <input type="hidden" name="attachment_document[attachable_id]" id={`attachment_documents_attachable_id_${data.id}`} value={data.id} autoComplete="off" />
        </div>
      </form>
    )
  }
}

function validationComponent (data) {
  if (!data.validation_waiting_for_approval) return

  if (data.pay_period_not_closed) {
    return (
      <form data-action={`absence_${data.id}`} action="/espace-salarie/validation_events/create_or_update" acceptCharset="UTF-8" method="post" data-controller="validation-events--form">
        <input type="hidden" name="authenticity_token" value={csrfToken()} autoComplete="off" />
        <input type="hidden" name="redirection_link" id={`redirection_link_${data.id}`} value="/espace-salarie/manager/conges-et-absences" autoComplete="off" />
        <div className="form-group">
          <label
            className="fw-400 color-secondary"
            htmlFor="validation_event_comment_description"
          >
           Message à l&apos;employé
          </label>
          <textarea name="validation_event[comment_description]" id={`validation_event_comment_description_${data.id}`} className="form-control" rows="1"></textarea>
        </div>
        <div className="text-center">
          <button name="button" data-action="click->validation-events--form#handleClick" data-validation-events--form-status-param="approved" className="me-3 no-border cursor-pointer border-radius-5 btn btn-success pt-10px pb-10px border-radius-5px ps-15px pe-20px fw-semibold">
            <i className="fa-solid fa-circle-check me-1"></i> Valider
          </button>
          <button name="button" data-action="click->validation-events--form#handleClick" data-validation-events--form-status-param="refused" className="me-3 no-border cursor-pointer border-radius-5 btn btn-danger pt-10px pb-10px border-radius-5px ps-15px pe-20px fw-semibold">
            <i className="fas fa-times me-1"></i> Refuser
          </button>
        </div>
        <input type="hidden" name="validation_event[manager_type]" id={`validation_event_manager_type_${data.id}`} value="Employee" className="form-control" autoComplete="off" />
        <input type="hidden" name="validation_event[manager_id]" id={`validation_event_manager_id_${data.id}`} value={data.logged_in_user.id} className="form-control" autoComplete="off" />
        <input type="hidden" name="validation_event[validatable_type]" id={`validation_event_validatable_type_${data.id}`} value="EmployeeAbsenceEvent" className="form-control" autoComplete="off" />
        <input type="hidden" name="validation_event[validatable_id]" id={`validation_event_validatable_id_${data.id}`} value={data.id} className="form-control" autoComplete="off" />
        <input type="hidden" name="validation_event[validation_state]" id={`validation_event_validation_state_${data.id}`} data-validation-events--form-target="validationStateInput" value="" className="form-control" autoComplete="off" />
      </form>
    )
  } else {
    <div className="col-12 p-0 text-center">
      <div className="p-3 rounded background-light-grey">
        {data.regularisation_request &&
        <div className="row">
          <div className="col-md-12 text-start">
            <div><span className='color-orange'>Demande de régularisation enregistrée <i className="fa fa-info-circle tooltip-tag" title="La demande de régularisation sera transmise à votre gestionnaire de paie sous la forme de commentaire de la prochaine clôture."></i> :</span> <u><a href={data.show_regularisation_link}>Voir</a></u></div>
          </div>
        </div>
        }

        {!data.regularisation_request &&
          <em className='text-14'>Ce congé appartient à une période clôturée. Pour le modifier veuillez effectuer une <u><a href={data.new_regularisation_link} className="color-cyan">demande de régularisation</a></u>.</em>
        }
      </div>
    </div>
  }
}

function showLinkComponent (data) {
  if (data.validation_refused || data.validation_approved) {
    return (
      <a href={data.show_link} className="no-border d-inline-flex cursor-pointer border-radius-5 color-white box-shadow-tile background-secondary px-2rem py-2">
        Voir
      </a>
    )
  }
}

function alreadyValidateComponent (data) {
  if (data.validation_waiting_for_approval) return

  return (
    <>
      <div className="form-group text-start">
        <p className="text-dark-purple text-16 mt-3 mb-2">
          <span className="color-secondary">Message à l&apos;employé</span> : {data.last_validations_event.comment_description || 'Non défini'}
        </p>
      </div>

      <div className="text-center mt-3">
        {data.pay_period_not_closed &&
          showLinkComponent(data)
        }

        {!data.pay_period_not_closed &&
          <div className="col-12 p-0 text-center">
            <div className="p-3 rounded background-light-grey">
              {data.regularisation_request &&
              <div className="row">
                <div className="col-md-12 text-start">
                  <div>
                    <span className='color-orange'>
                      Demande de régularisation enregistrée <i className="fa fa-info-circle tooltip-tag" title="La demande de régularisation sera transmise à votre gestionnaire de paie sous la forme de commentaire de la prochaine clôture."></i> :
                    </span>
                    <u><a href={data.show_link}>Voir</a></u>
                  </div>
                </div>
              </div>
              }

              {!data.regularisation_request &&
                <em className='text-14'>Ce congé appartient à une période clôturée. Pour le modifier veuillez effectuer une <u><a href={data.new_regularisation_link} className="color-cyan">demande de régularisation</a></u>.</em>
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}

function detailTemplate (data) {
  moment.locale('fr')
  let durationCount
  const isAWorkingTimeReductionAbsence = data.employee_absence_event_category.name === 'RTT'

  if (data.get_duration > 1) {
    durationCount = `${data.get_duration} Jours`
  } else {
    durationCount = `${data.get_duration} Jour`
  }

  return (
    <>
      <div className="mb-0 mt-3 text-19 color-secondary">
        <span className="fw-bold">{data.employee.full_name}</span>
        <span> - {data.employee_absence_event_category.name } - {durationCount}</span>
      </div>

      <p className="text-muted small">
        Demande créée le {moment(data.created_at).format('DD/MM/YYYY')}
        {
          isAWorkingTimeReductionAbsence
            ? (
            <>
              <span>- Solde de RTT</span> :
              <span data-select="solde" className={`${data.employee_presenter.working_time_reduction_balance_colored} ms-1`}>
                {data.employee_presenter.working_time_reduction_balance_formated}
                {data.employee.working_time_reduction_balance && (data.get_duration > data.employee.working_time_reduction_balance) && (
                  <img src={warningTriangle} className="tooltip-tag" title="Solde insuffisant" data-select="icone-warning" data-bs-toggle="tooltip" />
                )}
              </span>
            </>
              )
            : (
            <>
              {data.validation_waiting_for_approval && (
                <>
                  <span className="fw-bold">
                    <span> - Solde de congés payés</span> :&nbsp;
                    <span data-select="solde" className={data.employee_presenter.absence_balance_colored}>
                      {data.employee_presenter.absence_balance_formated}
                      {data.employee.absence_balance && (data.get_duration > data.employee.absence_balance) && (
                        <img src={warningTriangle} className="tooltip-tag" title="Solde insuffisant" data-select="icone-warning" data-bs-toggle="tooltip" />
                      )}
                    </span>
                  </span>
                </>
              )}
            </>
              )}
      </p>

      <div className="d-flex align-items-center">
        {dateDisplayCalendar(data.start_datetime, data.half_day_start)}
        <div className="mx-4">
          <i className="fas fa-2x fa-chevron-right"></i>
        </div>
        {dateDisplayCalendar(data.end_datetime, data.half_day_end)}
      </div>

      <div className="text-dark-purple text-16 mt-4 mb-2">
        <span className="color-secondary">Description</span> : {truncateString(data.comment_description)}
      </div>

      {attachmentDocumentsCondition(data)}

      {validationComponent(data)}

      {alreadyValidateComponent(data)}
    </>
  )
}

export { itemTemplate, detailTemplate }
