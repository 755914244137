import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'
import tizySolo from 'images/icons/mascotte-orange.png'

export default class extends Controller {
  static targets = ['canvas']

  userUrl = (window.location.href).match(/espace-entreprise/) === null

  static values = {
    data: Array,
    displayLegend: Boolean,
    aspectRatio: Number
  }

  connect () {
    const labels = this.userUrl
      ? [
          'Au bord du gouffre',
          'Ça pourrait aller mieux',
          'Plutôt en forme',
          'Au top'
        ]
      : [
          'Mal',
          'Moyen',
          'Bien',
          'Super bien'
        ]
    const data = {
      labels,
      datasets: [{
        data: this.dataValue,
        backgroundColor: [
          '#FF4141',
          '#FF7227',
          '#F6C357',
          '#00C18D'
        ],
        hoverOffset: 4
      }]
    }

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div')

      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.background = 'white'
        tooltipEl.style.borderRadius = '50%'
        tooltipEl.style.height = '50px'
        tooltipEl.style.boxShadow = '0px 0px 3px #0000002A'
        tooltipEl.style.width = '50px'
        tooltipEl.style.color = '#130F41'
        tooltipEl.style.fontSize = '12px'
        tooltipEl.style.opacity = 1
        tooltipEl.style.fontWeight = 900
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.transform = 'translate(-50%, 0)'
        tooltipEl.style.transition = 'all .1s ease'
        chart.canvas.parentNode.appendChild(tooltipEl)
      }

      return tooltipEl
    }

    const externalTooltipHandler = (context) => {
      const { chart, tooltip } = context
      const tooltipEl = getOrCreateTooltip(chart)

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set Text
      if (tooltip.body) {
        const bodyLines = tooltip.body.map(b => b.lines)

        const container = document.createElement('div')
        const image = document.createElement('img')
        image.src = tizySolo
        image.style.width = '15px'
        container.appendChild(image)
        container.style.textAlign = 'center'

        bodyLines.forEach((body, i) => {
          const text = document.createTextNode(`${body[0].split(':')[1].trim()}%`)
          container.appendChild(text)
        })

        while (tooltipEl.firstChild) {
          tooltipEl.firstChild.remove()
        }

        tooltipEl.appendChild(container)
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

      tooltipEl.style.opacity = 1
      tooltipEl.style.left = `${positionX + tooltip.caretX}px`
      tooltipEl.style.top = `${positionY + tooltip.caretY}px`
      tooltipEl.style.font = tooltip.options.bodyFont.string
      tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`
    }

    let legend

    if (this.displayLegendValue) {
      legend = {
        position: 'bottom',
        onClick: null,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          color: '#5C575D',
          padding: 30
        }
      }
    } else {
      legend = {
        display: false
      }
    }

    const myChart = new Chart( // eslint-disable-line
      this.canvasTarget,
      {
        type: 'doughnut',
        data,
        options: {
          aspectRatio: this.aspectRatioValue,
          plugins: {
            legend,
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler
            }
          }
        }
      }
    )
  }
}
