import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'container', 'submit', 'textField', 'messages']

  reset () {
    this.formTarget.reset()
    this.disableSubmit()
  }

  disableSubmit () {
    if (this.textFieldTarget.value.length === 0) {
      this.submitTarget.setAttribute('disabled', 'disabled')
      this.submitTarget.classList.add('disabled')
    } else {
      this.submitTarget.removeAttribute('disabled')
      this.submitTarget.classList.remove('disabled')
    }
  }

  toggleText () {
    if (this.textFieldTarget.hasAttribute('disabled')) {
      this.textFieldTarget.removeAttribute('disabled')
    } else {
      this.textFieldTarget.setAttribute('disabled', 'disabled')
    }
  }

  hideChat () {
    this.containerTarget.classList.toggle('hide-chat')
  }
}
