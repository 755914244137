import { Controller } from 'stimulus'
import Highcharts from 'highcharts/highmaps.js'
import highcharts3D from 'highcharts/highcharts-3d'

export default class extends Controller {
  static values = {
    containerId: String,
    series: Array,
    colors: Array,
    label: String
  }

  connect () {
    highcharts3D(Highcharts)
    Highcharts.chart(this.containerIdValue, {
      colors: this.colorsValue,
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: '',
        align: 'left'
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45
        }
      },
      tooltip: {
        style: {
          fontFamily: 'Avenir Next'
        }
      },
      series: [{
        name: this.labelValue,
        data: this.seriesValue
      }],
      credits: {
        enabled: false
      }
    })
  }
}
