import { Controller } from '@hotwired/stimulus'
import { getQuery } from 'helpers/requests'
import debounce from 'debounce'

export default class extends Controller {
  static targets = ['modal', 'form', 'result', 'input', 'resultItem', 'loading', 'resultLabel', 'noResultHelp']
  static values = {
    activeItem: { type: Number, default: -1 },
    getUrl: String,
    variantLinkClassName: String
  }

  connect () {
    this.debounceCustom = debounce(this.search, 300)
    // this.search('', this)
  }

  open (e) {
    e.preventDefault()
    const modal = bootstrap.Modal.getOrCreateInstance(this.modalTarget) // eslint-disable-line

    this.clearInput()
    modal.show()
    this.inputTarget.focus()
  }

  close (e) {
    e.preventDefault()
    const modal = bootstrap.Modal.getOrCreateInstance(this.modalTarget) // eslint-disable-line

    modal.hide()
    this.inputTarget.focus()
  }

  clearInput () {
    this.inputTarget.value = ''
    this.result = []
    this.search('', this)
  }

  next (e) {
    e.preventDefault()

    if (this.resultItemTargets.length > 0) {
      const item = this.resultItemTargets[this.activeItemValue + 1]

      if (item) {
        item.focus()
        this.activeItemValue = this.resultItemTargets.indexOf(item)
      } else {
        this.resultItemTargets[0].focus()
        this.activeItemValue = 0
      }
    }
  }

  previous (e) {
    e.preventDefault()

    if (this.resultItemTargets.length > 0) {
      const item = this.resultItemTargets[this.activeItemValue - 1]
      if (item) {
        item.focus()
        this.activeItemValue = this.resultItemTargets.indexOf(item)
      } else {
        const lastElement = this.resultItemTargets[this.resultItemTargets.length - 1]
        lastElement.focus()
        this.activeItemValue = this.resultItemTargets.indexOf(lastElement)
      }
    }
  }

  submit (e) {
    e.preventDefault()

    if (this.result[0]) {
      window.location.href = this.result[0].url
    }
  }

  toggleLoading (loading) {
    if (loading) {
      this.loadingTarget.innerHTML = '<i class="fas fa-spinner fa-spin mr-2"></i>'
    } else {
      this.loadingTarget.innerHTML = '<i class="fa-light fa-magnifying-glass"></i>'
    }
  }

  handleChange (e) {
    this.debounceCustom(e.target.value || '', this)
  }

  async search (query, that) {
    // reset activeItemValue
    that.activeItemValue = -1
    that.noResultHelpTarget.classList.add('d-none')

    if (query.length === 0) {
      that.resultLabelTarget.innerHTML = 'Les plus visitées'
    } else {
      that.resultLabelTarget.innerHTML = 'Résultats'
    }

    try {
      that.toggleLoading(true)
      const response = await getQuery(`${that.getUrlValue}?query=${query.toLowerCase()}`)

      that.result = response.data.results

      if (that.result.length > 0) {
        that.resultTarget.innerHTML = that.result.slice(0, 8).map(item => `
          <li>
            <a class="CommandPaletteSearch__Link ${that.variantLinkClassNameValue} round-3 d-block fw-semibold" data-searchs--command-palette-target="resultItem" href="${item.url}">
              <i class="CommandPaletteSearch__Link__Icon overflow-hidden ${item.icon || 'fa-light fa-arrow-right'}"></i>${item.name}
              ${item.path ? `<p class="CommandPaletteSearch__Link__Path overflow-hidden mb-0 text-dark-60 text-14 fw-500">${item.path}</p>` : ''}
            </a>
          </li>`).join('')
      } else {
        that.resultLabelTarget.innerHTML = 'Pas de résultats'
        that.noResultHelpTarget.classList.remove('d-none')
        that.resultTarget.innerHTML = `
          <li class="fw-bold">Aïe ! Nous n’avons pas trouvé ce que vous cherchiez avec : ${query}</li>
        `
      }
    } catch (e) {
      throw new Error(e)
    } finally {
      that.toggleLoading(false)
    }
  }
}
