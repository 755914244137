import { Controller } from 'stimulus'
import { deleteQuery } from 'helpers/requests'
import { fileCard, alertCard,  } from './component'
import * as toastr from 'toastr'

export default class extends Controller<HTMLFormElement> {
  static targets = ['input', 'name', 'image', 'imageUploaded', 'filesize', 'fileList', 'previousAttachment', 'alert']
  static values = {
    maxFile: Number
  }

  maxFileSizeMB = 15

  declare inputTarget: HTMLInputElement;
  declare nameTarget: HTMLElement;
  declare imageTarget: HTMLImageElement;
  declare imageUploadedTarget: HTMLElement;
  declare fileListTarget: HTMLElement;
  declare previousAttachmentTarget: HTMLElement;
  declare alertTarget: HTMLElement;
  declare submitButton: HTMLInputElement;
  declare readonly hasAlertTarget: boolean
  declare readonly maxFileValue: number

  connect () {
    this.submitButton = document.querySelector('input[type="submit"]') as HTMLInputElement
  }

  preventDefault (e: Event) {
    e.preventDefault()
    e.stopPropagation()
  }

  openDropZoneInput () {
    this.inputTarget.click()
  }

  updateFileStatus (e?: Event) {
    const files = this.inputTarget.files
    this.clearFileList()

    this.fileListTarget.innerHTML = '' // Clear previous file list

    if (this.fileLimitExceeded(files)) {
      this.showAlert(`Attention : vous ne pouvez pas uploader plus de ${this.maxFileValue} fichiers`)
      return
    } else {
      this.clearAlert()
    }

    if (this.validateTotalSize(files)) {
      if (files.length === 0 && this.hasAlertTarget) {
        this.clearAlert()
      }

      Array.from(files).forEach((file, index) => {
        this.addFile(file, index)
      })
    } else {
      this.showAlert('Attention : la taille totale des fichiers ne doit pas dépasser 15 Mo')
      return
    }

    this.imageUploadedTarget.classList.remove('d-none')
  }

  private addFile (file: File, index: number) {
    const fileName: string = file.name
    if (this.fileIsTooLarge(file)) {
      this.toggleSubmitButton(true)
      this.showAlert(`Attention : le fichier ${fileName} est trop volumineux (5 Mo maximum)`)
    } else {
      this.toggleSubmitButton(false)
      this.clearAlert()
    }

    const fileSize: string = this.fileConvertSize(file.size)
    const fileItem: HTMLElement = fileCard(fileName, fileSize, index)

    this.fileListTarget.appendChild(fileItem)
  }

  private fileIsTooLarge (file: File): boolean {
    return file.size / (1024 * 1024) > 5
  }

  private clearFileList () {
    this.fileListTarget.innerHTML = ''
  }

  private fileLimitExceeded(files: FileList): boolean {
    return files.length > this.maxFileValue;
  }

  private validateTotalSize (files: FileList): boolean {
    const totalSize: number = Array.from(files).reduce((sum, file) => sum + file.size, 0)
    const totalSizeMB: number = totalSize / (1024 * 1024) // Conversion en Mo
    return totalSizeMB <= this.maxFileSizeMB
  }

  private fileConvertSize(aSize: number): string {
    aSize = Math.abs(parseInt(aSize.toString(), 10));
    const def = [[1, 'octets'], [1024, 'Ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
    for (let i = 0; i < def.length; i++) {
      if (aSize < Number(def[i][0])) return (aSize / Number(def[i - 1][0])).toFixed(2) + ' ' + def[i - 1][1];
    }
  }

  removeDocument (event: Event) {
    const target = event.target as HTMLElement
    const index = Number(target.dataset.index)
    this.removeFileAtIndex(index)

    this.updateFileStatus()
  }

  private removeFileAtIndex(index: number) {
    const files = Array.from(this.inputTarget.files || [])
    files.splice(index, 1)
    const dataTransfer = new DataTransfer()

    files.forEach(file => dataTransfer.items.add(file))
    this.inputTarget.files = dataTransfer.files
  }

  addDraggingClass () {
    this.element.classList.add('dropzone-dragging')
  }

  removeDraggingClass () {
    this.element.classList.remove('dropzone-dragging')
  }

  removePreviousDocument (event) {
    const url = event.params.url
    console.log(url)
    this.deleteRequest(url)
  }

  async deleteRequest (url: string) {
    try {
      const data = await deleteQuery(url)
      if (data.status === 'ok') {
        this.previousAttachmentTarget.remove()
        toastr.success('Fichier supprimé')
      }
    } catch (e) {
      toastr.error('La suppression a échoué, veuillez réessayer')
    }
  }

  drop (e: DragEvent) {
    e.preventDefault()
    this.removeDraggingClass()

    const files = e.dataTransfer?.files
    if (files) {
      this.inputTarget.files = files
      this.updateFileStatus(e)
    }
  }

  private showAlert (message: string) {
    const alert = alertCard(message)
    document.getElementById('dropzone').insertAdjacentElement('afterend', alert)
  }

  private clearAlert() {
    if (this.hasAlertTarget) {
      this.alertTarget.remove()
    }
  }

  private toggleSubmitButton (state: boolean) {
    if (this.submitButton) {
      this.submitButton.disabled = state
    }
  }
}
