import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = [
    'sirenAutocomplete',
    'verifyCompanyInformation',
    'noCompanyCreated',
    'socialConventionNotFoundError',
    'socialConventionSelect'
  ]

  static values = {
    socialConventionUrl: String
  }

  async connect () {
    const that = this

    // trigger change on connect when there are already a value
    if ($(this.sirenAutocompleteTarget).val()) {
      that.check_submit_visibility(that)
      that.noCompanyCreatedTarget.classList.add('d-none')
      that.verifyCompanyInformationTarget.classList.remove('d-none')
    }

    $(this.sirenAutocompleteTarget).devbridgeAutocomplete({
      serviceUrl: 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&sort=datederniertraitementetablissement&rows=30&facet=trancheeffectifsetablissement&facet=etablissementsiege&facet=statutdiffusionunitelegale&facet=unitepurgeeunitelegale&facet=sexeunitelegale&facet=trancheeffectifsunitelegale&facet=categorieentreprise&facet=libellecommuneetablissement&facet=etatadministratifetablissement&facet=nomenclatureactiviteprincipaleetablissement&facet=caractereemployeuretablissement&facet=departementetablissement&facet=regionetablissement&facet=sectionetablissement&facet=classeetablissement&facet=sectionunitelegale&facet=classeunitelegale&facet=naturejuridiqueunitelegale&refine.etatadministratifetablissement=Actif',
      paramName: 'q',
      deferRequestBy: 200,
      preventBadQueries: false,
      minChars: 4,
      width: 'auto',
      onSearchStart: function (query) {
        const regex = /^[0-9 ]+$/
        if (regex.test(query.q)) {
          query.q = query.q.replace(/\s/g, '')
        }
        $('#search_container').addClass('loading')
        $('.submit-container').hide()
      },
      transformResult: function (response) {
        const data = JSON.parse(response)

        return {
          suggestions: $.map(data.records, function (dataItem) {
            let socialNameWrapper = ''
            if (dataItem.fields.denominationunitelegale) {
              socialNameWrapper = dataItem.fields.denominationunitelegale
            } else {
              socialNameWrapper = dataItem.fields.prenomusuelunitelegale + ' ' + dataItem.fields.nomunitelegale
            }
            const score = Math.abs(that.levenstein(socialNameWrapper.toLowerCase(), data.parameters.q.toLowerCase()))
            return {
              value: String(dataItem.fields.siren), // Important because if value is not a string the autocomplete method will fail.
              data: dataItem.fields.siren,
              social_name: socialNameWrapper,
              siren: dataItem.fields.siren,
              siret: dataItem.fields.siret,
              activity: dataItem.fields.activiteprincipaleunitelegale,
              creation_date: dataItem.fields.datecreationunitelegale,
              section: dataItem.fields.classeetablissement,
              street: dataItem.fields.adresseetablissement,
              zipcode: dataItem.fields.codepostaletablissement,
              city: dataItem.fields.libellecommuneetablissement,
              legal_form_name: dataItem.fields.naturejuridiqueunitelegale,
              country: 'France',
              score
            }
          }).sort(function (a, b) {
            return a.score - b.score
          })
        }
      },
      formatResult: function (suggestion, currentValue) {
        return "<span class='name-society text-14 text-md-16 fw-bold'> " + suggestion.social_name + '</span>' + " <span class='text-12 text-md-14'> (SIRET: " + suggestion.siret + ')</span>' + '<br/>' + " <span class=' text-12 text-md-14'>" + suggestion.section + '</span>' + '<br/>' + "<span class='text-12 text-md-14'>" + suggestion.street + ', ' + suggestion.zipcode + ', ' + suggestion.city + '</span>'
      },
      onSearchComplete: function (query, suggestions) {
        $('#search_container').removeClass('loading')
        $('.submit-container').show()
      },
      onSelect: async function (suggestion) {
        try {
          // to get social convention id
          const result = await getQuery(`${that.socialConventionUrlValue}?activity_code=${suggestion.activity}`)

          if (result.data) {
            that.socialConventionNotFoundErrorTarget.classList.add('d-none')

            $('select[name="product_wizard[social_convention_id]"]').val(result.data.social_convention.id)
            // trigger change to update the UI
            $('select[name="product_wizard[social_convention_id]"]').select2({
              minimumResultsForSearch: Infinity,
              width: '100%'
            }).trigger('change')
          } else {
            that.socialConventionNotFoundErrorTarget.classList.remove('d-none')
          }

          $(this).val(suggestion.social_name)
          $('#changeButtonUserPro').removeClass('d-none')
          $('#social_name, .input-social-name').val(suggestion.social_name)
          $('#activity_code').val(suggestion.activity)
          $('#siren_field').val(suggestion.siren)
          $('#siret_field, .input-siret').val(suggestion.siret)
          $('.html-siret-exist').remove()
          $('#company_creation_date').val(suggestion.creation_date)
          $('#company_address').val(suggestion.street)
          $('#company_zipcode').val(suggestion.zipcode)
          $('#company_city').val(suggestion.city)
          $('#company_section').val(suggestion.section)
          $('#legal_form_name').val(suggestion.legal_form_name)

          that.check_submit_visibility(that)
          that.noCompanyCreatedTarget.classList.add('d-none')
          that.verifyCompanyInformationTarget.classList.remove('d-none')
          $(that.socialConventionSelectTarget).select2({
            minimumResultsForSearch: 1,
            width: '100%'
          })
        } catch (e) {
          toastr.error('Oups, il y a eu une erreur')
          throw new Error(e)
        }
      }
    })

    $('#changeButtonUserPro').click(() => {
      $(this.sirenAutocompleteTarget).val('')
      this.check_submit_visibility(this)
    })

    this.check_submit_visibility(this)

    $(this.sirenAutocompleteTarget).keyup(() => {
      this.check_submit_visibility(this)
    })
  }

  check_submit_visibility (that) {
    if ($(this.sirenAutocompleteTarget).val() && $('#company_city').val()) {
      $('.button-submit').removeClass('disabled not-allowed')
      $('[data-select="company_other_input"]').value = ''

      that.handle_other_options()
      $('.button-submit').prop('disabled', false)
      $('#changeButtonUserPro').removeClass('d-none')
    } else {
      $('.button-submit').addClass('disabled not-allowed')
      $('.button-submit').prop('disabled', true)
      $('#changeButtonUserPro').addClass('d-none')
      $('#selection-name').text('')
      $('#social_name, .input-social-name').val('')
      $('#activity_code').val('')
      $('#siren_field').val('')
      $('#siret_field, .input-siret').val('')
      $('#company_creation_date').val('')
      $('#company_address').val('')
      $('#company_zipcode').val('')
      $('#company_city').val('')
      $('#company_section').val('')
      $('#legal_form_name').val('')
      $('[data-select="other_company_group"]').removeClass('d-none')
      that.noCompanyCreatedTarget.classList.remove('d-none')
      that.verifyCompanyInformationTarget.classList.add('d-none')
    }
  }

  handle_other_options () {
    const buttonCheck = $('[data-select="other_company_option"].btn-primary')
    const buttonDiv = $('.border-primary')
    if (buttonCheck && buttonDiv) {
      buttonCheck.toggleClass('btn-outline-primary')
      buttonCheck.toggleClass('btn-primary')
      buttonDiv.toggleClass('border-primary')
      buttonDiv.toggleClass('border-grey')
    }
    $('[data-select="other_company_group"]').addClass('d-none')
  }

  levenstein (a, b) {
    if (a.length === 0) return b.length
    if (b.length === 0) return a.length
    const matrix = []
    // increment along the first column of each row
    let i
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i]
    }
    // increment each column in the first row
    let j
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j
    }
    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1]
        } else {
          matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
            Math.min(matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1)) // deletion
        }
      }
    }
    return matrix[b.length][a.length]
  }
}
