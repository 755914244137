const PIE_OPTIONS = {
  height: 300,
  titlePosition: 'none',
  colors: [
    '#6D092A',
    '#E8A23B',
    '#2E047E',
    '#27C2D1',
    '#00C18D',
    '#F6C357',
    '#FF7227',
    '#130F41',
    '#FF4141',
    '#707070',
    '#F27FCB',
    '#8F75D6',
    '#ff7e67',
    '#4393BB',
    '#35495e'
  ],
  backgroundColor: 'transparent',
  legend: { position: 'bottom' },
  chartArea: { width: '100%' },
  fontName: 'Avenir Next',
  pieSliceText: 'value',
  sliceVisibilityThreshold: 0
}

const COLUMN_OPTIONS = {
  titlePosition: 'none',
  legend: { position: 'top' },
  backgroundColor: 'transparent',
  vAxis: {
    gridlines: { color: 'transparent' },
    baselineColor: 'transparent'
  },
  colors: ['#6D092A', '#E8A23B', '#000'],
  bar: { groupWidth: '50%' },
  hAxis: { showTextEvery: 1, baselineColor: { color: 'transparent' } },
  fontName: 'Avenir Next',
  chartArea: {
    height: '70%',
    width: '100%'
  },
  height: 300,
  width: '100%',
  isStacked: true,
  annotations: {
    highContrast: true,
    alwaysOutside: true,
    textStyle: {
      fontName: 'Avenir Next',
      color: 'black',
      bold: true,
      fontSize: 10
    },
    stem: { color: 'transparent' }
  },
  seriesType: 'bars',
  series: {
    2: {
      enableInteractivity: false,
      visibleInLegend: false,
      annotations: {
        stem: {
          length: 0
        }
      }
    }
  }
}

const BAR_OPTIONS = {
  vAxis: {
    gridlines: { color: 'transparent' },
    baseline: 0,
    baselineColor: 'transparent',
    textPosition: 'none'
  },
  backgroundColor: 'transparent',
  bar: { groupWidth: '60%' },
  hAxis: { showTextEvery: 1, baselineColor: { color: 'transparent' } },
  fontName: 'Avenir Next',
  chartArea: { width: '100%' },
  width: 200,
  fontSize: 11,
  annotations: {
    alwaysOutside: true,
    stem: { color: 'none' },
    textStyle: {
      fontName: 'Avenir Next',
      auraColor: 'white',
      color: '#130F41',
      bold: true,
      fontSize: 12
    }
  }
}

export { PIE_OPTIONS, COLUMN_OPTIONS, BAR_OPTIONS }
