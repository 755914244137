import { BaseController } from '../base_controller'
import Highcharts from 'highcharts/highmaps.js'

export default class extends BaseController {
  static values = {
    containerId: String,
    series: Array,
    categories: Array,
    colors: Array
  }

  connect () {
    Highcharts.chart(this.containerIdValue, {
      colors: this.colorsValue,
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      ...this.generateLegendConfig(),
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
        }
      },
      xAxis: [{
        categories: this.categoriesValue,
        reversed: false,
        labels: {
          step: 1
        }
      }],
      yAxis: {
        title: {
          text: null
        },
        gridLineWidth: 0,
        labels: {
          formatter: function () {
            return undefined
          }
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{series.name} - {point.category} : <br>Dépenses: <b>{point.y:.2f} €</b>',
        style: {
          fontFamily: 'satoshi'
        }
      },
      series: this.seriesValue
    })
  }
}
