import jsxElem from 'jsx-no-react' // eslint-disable-line
import warningTriangle from 'images/icons/icn24_warning_triangle.svg'

function modalTitle () {
  return (
    <span>
      <img src={warningTriangle} className='filter-red me-3 mb-1' />
      Attention
    </span>
  )
}

function modalBody (contractEmployeesCount, currentEmployeesCount, internalContractId) {
  return (
    <div data-controller="internal-contracts--company-provident-insurance--subscription" data-internal-contracts--company-provident-insurance--subscription-current-employees-count-value={currentEmployeesCount} data-internal-contracts--company-provident-insurance--subscription-contract-employees-count-value={contractEmployeesCount} data-internal-contracts--company-provident-insurance--subscription-internal-contract-id-value={internalContractId}>
      <div className='row'>
        <div className='col-12'>Vous aviez déclaré {`${contractEmployeesCount === 1 ? `${contractEmployeesCount} employé concerné` : `${contractEmployeesCount} employés concernés`}`} par le contrat et en avez renseigné {currentEmployeesCount} dans la liste du personnel.</div>
        <div className='col-12'>Confirmez-vous qu&apos;il y a {`${currentEmployeesCount === 1 ? `${currentEmployeesCount} employé présent` : `${currentEmployeesCount} employés présents`}`} aujourd&apos;hui&nbsp;?</div>
      </div>
      <div className="row align-items-center justify-content-center mt-40px">
        <div className="col-md-6">
          <button className='btn btn-outline-primary w-100' data-action='click->internal-contracts--company-provident-insurance--subscription#closeModal'>Non</button>
        </div>
        <div className="col-md-6">
          <button className='btn btn-primary me-3 w-100' data-action='click->internal-contracts--company-provident-insurance--subscription#updateEmployeesCount'>Oui</button>
        </div>
      </div>
    </div>
  )
}

export { modalTitle, modalBody }
