import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const initValue = $('[data-select="timing_input"]')[0].value
    this.changeCircleButton($(`[data-value="${initValue}"]`)[0])
  }

  handleClick (e) {
    const currentButton = e.currentTarget.childNodes[1]
    if (currentButton.classList.contains('btn-outline-primary')) {
      this.changeCircleButton($('[data-select="timing"].btn-primary')[0])
      this.changeCircleButton(currentButton)

      const imput = $('[data-select="timing_input"]')[0]
      imput.value = currentButton.dataset.value
    }
  }

  changeCircleButton (button) {
    button.classList.toggle('btn-outline-primary')
    button.classList.toggle('btn-primary')
    button.parentNode.classList.toggle('border-grey')
    button.parentNode.classList.toggle('border-primary')
  }
}
