import jsxElem from 'jsx-no-react' // eslint-disable-line

function fileCard (fileName, fileSize, index) {
  return (
    <div className='uploaded-img-zone'>
      <div><i className='fa-solid fa-file-lines text-dark-40'></i></div>
      <div className='d-flex flex-column w-100 mb-0'>
        <p className='text-14 pl-3 w-100 text-dark mb-0 fw-semibold'>{fileName}</p>
        <p className='text-14 pl-3 w-100 text-dark-60 mb-0 fw-semibold'>{fileSize}</p>
      </div>
      <div className='justify-content-end align-item-center'>
        <i className='fa-solid fa-circle-xmark text-dark-20 hand-cursor' data-index={index} data-action='click->shared--multi-upload--component#removeDocument'></i>
      </div>
    </div>
  )
}

function alertCard (message) {
  return (
    <div data-shared--multi-upload--component-target="alert" className='alert alert-danger fade show mt-3'>
      <div className='d-flex flex-column flex-md-row justify-content-between align-items-md-center'>
        <div className='d-flex align-items-center'>
          <i className='fa-solid fa-exclamation-triangle text-danger mr-2'></i>
          <span>{message}</span>
        </div>
      </div>
    </div>
  )
}

export { fileCard, alertCard }
