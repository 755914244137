import { Controller } from 'stimulus'
import {
  employeeAdministratorSmallText,
  companyAdministratorSmallText,
  travelType,
  companyAdministratorAnnualProofEndDate,
  employeeAnnualProofEndDate,
  bikeSmallText, employeeMonthlyProofEndDate, companyAdministratorMonthlyProofEndDate
} from './template'

export default class extends Controller {
  static targets = ['travelTypeSection', 'travelType', 'travelTypeSmallText', 'recurrenceSection', 'recurrence',
    'recurrenceSmallText', 'proofEndDateSection', 'proofEndDate', 'proofEndDateText',
    'proofEndDateSmallText', 'amountSection', 'bikeSmallText', 'adminRecurrenceSection', 'adminProofEndDateSection',
    'proofEndDateLabel']

  USER_URL = (window.location.href).match(/espace-entreprise/) === null ? 'espace-salarie' : 'espace-entreprise'

  SMALL_TEXT_CHOICES = this.USER_URL === 'espace-salarie' ? employeeAdministratorSmallText() : companyAdministratorSmallText()
  TRAVEL_TYPES = travelType()

  connect () {
    $('[data-select="no-search"]').on('select2:select', function (e) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })

    if (this.hasRecurrenceSectionTarget) {
      this.recurrenceSectionTarget.insertAdjacentHTML('beforeend', this.SMALL_TEXT_CHOICES.monthlyRecurrenceText)
      this.changeProofEndDate()
      this.displayRecurrenceText()
    }
    if (this.hasAdminRecurrenceSectionTarget) {
      this.displayAdminProofEndDateSelect()
    }
  }

  displaySmallText () {
    if (this.travelTypeTarget.value === 'personal') {
      this.displayPersonal()
    } else if (this.travelTypeTarget.value === 'public') {
      this.displayPublic()
    } else if (this.travelTypeTarget.value === 'bike') {
      this.displayBike()
    }
  }

  hideElements () {
    if (this.hasTravelTypeSmallTextTarget) {
      this.travelTypeSmallTextTarget.remove()
    }
    if (this.hasBikeSmallTextTarget) {
      this.bikeSmallTextTarget.remove()
    }
  }

  displayBike () {
    this.hideElements()
    this.travelTypeSectionTarget.insertAdjacentHTML('beforeend', this.TRAVEL_TYPES.bike)
    this.amountSectionTarget.insertAdjacentHTML('beforeend', bikeSmallText())
  }

  displayPublic () {
    this.hideElements()
    this.travelTypeSectionTarget.insertAdjacentHTML('beforeend', this.TRAVEL_TYPES.public)
  }

  displayPersonal () {
    this.hideElements()
    this.travelTypeSectionTarget.insertAdjacentHTML('beforeend', this.TRAVEL_TYPES.personal)
  }

  displayRecurrenceText () {
    if (this.hasRecurrenceSmallTextTarget) {
      this.recurrenceSmallTextTarget.remove()
    }
    if (this.recurrenceTarget.value === 'monthly') {
      this.recurrenceSectionTarget.insertAdjacentHTML('beforeend', this.SMALL_TEXT_CHOICES.monthlyRecurrenceText)
      this.proofEndDateLabelTarget.innerHTML = 'Fin de la récurrence'
    } else if (this.recurrenceTarget.value === 'annual') {
      this.recurrenceSectionTarget.insertAdjacentHTML('beforeend', this.SMALL_TEXT_CHOICES.annualRecurrenceText)
      this.proofEndDateLabelTarget.innerHTML = 'Date de fin du justificatif'
      document.querySelector('#travel_tickets_recurrence_proof_end_date').nextElementSibling.style.width = 'inherit'
    }
    this.changeProofEndDate()
  }

  changeAdminLabel () {
    if (this.recurrenceTarget.value === 'monthly') {
      this.proofEndDateLabelTarget.innerHTML = 'Fin de la récurrence'
    } else {
      this.proofEndDateLabelTarget.innerHTML = 'Date de fin du justificatif'
    }
  }

  changeProofEndDate () {
    if (this.hasProofEndDateSmallTextTarget) {
      this.proofEndDateSmallTextTarget.remove()
    }
    const selectedDate = new Date(String(this.proofEndDateTarget.value))
    selectedDate.setDate(selectedDate.getDate() + 1)
    if (this.USER_URL === 'espace-salarie') {
      if (this.recurrenceTarget.value === 'monthly') {
        this.proofEndDateTextTarget.insertAdjacentHTML('beforeend', employeeMonthlyProofEndDate(selectedDate.toLocaleDateString('fr')))
      } else {
        this.proofEndDateTextTarget.insertAdjacentHTML('beforeend', employeeAnnualProofEndDate(selectedDate.toLocaleDateString('fr')))
      }
    } else {
      if (this.recurrenceTarget.value === 'monthly') {
        this.proofEndDateTextTarget.insertAdjacentHTML('beforeend', companyAdministratorMonthlyProofEndDate(selectedDate.toLocaleDateString('fr')))
      } else {
        this.proofEndDateTextTarget.insertAdjacentHTML('beforeend', companyAdministratorAnnualProofEndDate(selectedDate.toLocaleDateString('fr')))
      }
    }
  }
}
