import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['noAccount', 'haveAccount', 'noAccountCta', 'imgNoAccount', 'imgHaveAccount', 'haveAccountCta', 'switch']

  connect () {
    this.noAccount()
  }

  handleClick () {
    if (this.switchTarget.checked) {
      this.haveAccount()
    } else {
      this.noAccount()
    }
  }

  noAccount () {
    this.noAccountTarget.classList.add('fw-bold')
    this.haveAccountTarget.classList.remove('fw-bold')
    this.noAccountCtaTarget.classList.remove('d-none')
    this.haveAccountCtaTarget.classList.add('d-none')
    this.imgHaveAccountTarget.classList.add('d-none')
    this.imgNoAccountTarget.classList.remove('d-none')
    this.noAccountDescriptionTarget.classList.remove('d-none')
    this.haveAccountDescriptionTarget.classList.add('d-none')
  }

  haveAccount () {
    this.noAccountTarget.classList.remove('fw-bold')
    this.haveAccountTarget.classList.add('fw-bold')
    this.noAccountCtaTarget.classList.add('d-none')
    this.haveAccountCtaTarget.classList.remove('d-none')
    this.imgHaveAccountTarget.classList.remove('d-none')
    this.imgNoAccountTarget.classList.add('d-none')
    this.noAccountDescriptionTarget.classList.add('d-none')
    this.haveAccountDescriptionTarget.classList.remove('d-none')
  }

  unCheckedSwitch (_e) {
    this.switchTarget.checked = false
    this.triggerChangeEventOnElement(this.switchTarget)
  }

  checkedSwitch (_e) {
    this.switchTarget.checked = true
    this.triggerChangeEventOnElement(this.switchTarget)
  }

  triggerChangeEventOnElement (el) {
    const event = new Event('change')
    el.dispatchEvent(event)
  }
}
