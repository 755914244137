import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputHidden', 'counterCount']
  static values = {
    minimum: Number,
    max: String,
    label: String
  }

  clickMinus (_) {
    if (parseInt(this.inputHiddenTarget.value) === parseInt(this.minimumValue)) {
      return
    }

    const newValue = parseInt(this.inputHiddenTarget.value) - 1
    this.inputHiddenTarget.value = newValue
    this.changeText(newValue)
  }

  clickPlus (_) {
    if (parseInt(this.inputHiddenTarget.value) === parseInt(this.maxValue)) {
      return
    }

    const newValue = parseInt(this.inputHiddenTarget.value) + 1
    this.inputHiddenTarget.value = newValue
    this.changeText(newValue)
  }

  changeText (newValue) {
    if (newValue < 2) {
      this.counterCountTarget.textContent = `${newValue} ${this.labelValue}`
    } else {
      this.counterCountTarget.textContent = `${newValue} ${this.labelValue}s`
    }
  }
}
