import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class BaseInfiniteScrollController extends Controller {
  static values = {
    url: String,
    page: Number
  }

  initialize () {
    this.scroll = this.scroll.bind(this)
    this.pageValue = this.pageValue || 1
  }

  connect () {
    this.element.scrollTo(0, 0)
    this.element.addEventListener('scroll', this.scroll)
  }

  async scroll () {
    if (!this.scrollReachedEnd) return

    try {
      const url = new URL(this.urlValue)
      url.searchParams.set('page', this.pageValue)

      const result = await getQuery(url)

      this._fetchNewPage(result.data)

      this.pageValue += 1
    } catch (e) {
      throw new Error(e)
    }
  }

  // In the child class, declare this function to inject the right template in the html
  // exemple : javascript/controllers/employee_absence_events/infinite_scroll_controller.js
  _fetchNewPage (data) {
    throw new Error('You must implement _fetchNewPage function')
  }

  get scrollReachedEnd () {
    const { scrollHeight, scrollTop, clientHeight } = this.element
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight
    return distanceFromBottom < 20
  }
}
