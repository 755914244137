import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['card']
  static values = { index: Number }

  initialize () {
    this.showCurrentSlide()
  }

  connect () {
  }

  next () {
    this.indexValue++
    const previousButton = $(`#pbtn-${this.indexValue + 1}`)
    previousButton.removeClass('d-none')
    if ((this.indexValue + 1) > this.cardTargets.length) {
      $('#answers-form').submit()
    } else {
      this.showCurrentSlide()
    }
  }

  previous () {
    this.indexValue--
    this.showCurrentSlide()
  }

  showCurrentSlide () {
    this.cardTargets.forEach((element, index) => {
      element.hidden = index !== this.indexValue
    })
  }

  handleClick (e) {
    const currentElement = e.currentTarget
    const currentButton = $(`#nbtn-${this.indexValue}`)
    const imputNote = $(`#q-${this.indexValue}`)
    imputNote.val(currentElement.dataset.value)
    currentButton.removeAttr('disabled')

    $(`#note-container-${this.indexValue} .WellBeingSurvey_img`).removeClass('note-active')
    currentElement.classList.toggle('note-active')
    if ((this.indexValue) === this.cardTargets.length - 1) {
      currentButton.html("Suivant <i class='fa-light fa-arrow-right'></i>")
    }
  }
}
