import { Controller } from 'stimulus'

export default class extends Controller {
  async setTour (e) {
    const step = parseInt(e.target.outerHTML.match(/\d+/)[0])
    onboardingContractTour.goTo(step)
  }

  async nextButton (e) {
    onboardingContractTour.next()
  }

  async endButton (e) {
    onboardingContractTour.end()
    window.location = '/espace-entreprise/faq#onboarding_tour'
    const modalElement = document.querySelector('#modal-onboarding-contract-tour-end')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.show()
  }

  async prevButton (e) {
    onboardingContractTour.prev()
  }

  async handleClickCross (e) {
    onboardingContractTour.end()
  }

  async handleClickSeeVideos (e) {
    onboardingContractTour.end()
  }

  async handleClickBackToDashboard (e) {
    onboardingContractTour.end()
    window.location = '/espace-entreprise/bienvenue'
  }
}
