import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'timer', 'flashAlert']

  connect () {
    this._startCountdown()

    if (this.hasFlashAlertTarget) {
      setTimeout(() => {
        this.flashAlertTarget.classList.add('fade-out')
      }, 10000)
    }
  }

  _startCountdown () {
    let count = parseInt(this.timerTarget.dataset.countdown)
    this.timerTarget.textContent = `${count}s`

    const timer = setInterval(() => {
      if (count <= 0) {
        clearInterval(timer)

        this.timerTarget.classList.add('fade-out')

        setTimeout(() => {
          this.timerTarget.textContent = ''
        }, 1000)

        setTimeout(() => {
          this.linkTarget.classList.remove('disabled')
        }, 1000)
      } else {
        count--
        this.timerTarget.textContent = `${count}s`
      }
    }, 1000)
  }
}
