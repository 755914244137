import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ratingInput', 'form', 'moodSelectionSection', 'commentSection']

  fillRatingInput (value) {
    this.ratingInputTarget.value = value
  }

  pickMoodValue (e) {
    this.fillRatingInput(e.currentTarget.dataset.tizyvalue)
    this.formTarget.submit()
  }

  displayMoodOptions () {
    this.moodSelectionSectionTarget.classList.remove('d-none')
    this.commentSectionTarget.classList.add('d-none')
  }
}
