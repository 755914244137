import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('.multi_roles').multiselect({
      includeSelectAllOption: true,
      selectAllText: 'Tous les rôles',
      nonSelectedText: 'Veuillez sélectionner',
      buttonWidth: '100%'
    })
  }
}
