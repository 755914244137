import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toast']
  static values = {
    link: String
  }

  close ({ params }) {
    $.get(params.link)
  }
}
