import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    contentProgressBar: Boolean
  }

  static targets = ['dropdown', 'link', 'sidebar']

  connect () {
    if (this.contentProgressBarValue) this.activateProgressBar()

    // close all dropdowns when click inside document
    document.addEventListener('click', (e) => {
      if (!this.dropdownTargets.includes(e.target) && !this.linkTargets.includes(e.target)) {
        this.closeAllDropdowns()
      }
    })

    // Close sidebar when resize to md
    window.addEventListener('resize', () => {
      const w = document.documentElement.clientWidth

      if (w > 991 && this.sidebarTarget.classList.contains('PublicSidebar--active')) {
        this.closeSidebar()
      }
    })
  }

  toggleSidebar (_e) {
    this.sidebarTarget.classList.toggle('PublicSidebar--active')
  }

  closeSidebar (_e) {
    this.sidebarTarget.classList.remove('PublicSidebar--active')
  }

  closeAllDropdowns () {
    this.dropdownTargets.forEach(item => {
      item.classList.remove('PublicNavbar__DropdownContainer--active')
    })

    this.linkTargets.forEach(item => {
      item.classList.remove('PublicNavbar__DropdownLink--active')
    })
  }

  activateProgressBar () {
    const progressBar = document.querySelector('.progress-bar')

    window.addEventListener('scroll', function (_e) {
      const scrollPosition = window.pageYOffset
      const totalheight = document.documentElement.scrollHeight - document.documentElement.clientHeight
      progressBar.style.width = (scrollPosition / totalheight) * 100 + '%'
    })
  }
}
