import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const blockInitValue = $(`[data-value="${$('[data-select="objective_input"]')[0].value}"]`)[0]
    if (blockInitValue) {
      this.changeCircleButton(blockInitValue)
    }
  }

  handleClick (e) {
    const currentButton = e.currentTarget.childNodes[1]
    const imput = $('[data-select="objective_input"]')[0]
    if (currentButton.classList.contains('btn-outline-primary')) {
      const previousButton = $('[data-select="objective"].btn-primary')[0]
      if (previousButton) {
        this.changeCircleButton(previousButton)
      }
      this.changeCircleButton(currentButton)
      imput.value = currentButton.dataset.value
    } else {
      this.changeCircleButton(currentButton)
      imput.value = 'Other'
    }
  }

  changeCircleButton (button) {
    button.classList.toggle('btn-outline-primary')
    button.classList.toggle('btn-primary')
    button.parentNode.classList.toggle('border-grey')
    button.parentNode.classList.toggle('border-primary')
  }
}
