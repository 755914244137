import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'text']

  connect () {
    this.submitTarget.disabled = true
  }

  toggleSubmit (event) {
    const length = event.params.minLength
    this.submitTarget.disabled = this.textTarget.value.length < length
    if (this.textTarget.value.length < length) {
      this.submitTarget.classList.remove('disable')
    } else {
      this.submitTarget.classList.add('disable')
    }
  }
}
