import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'userProId', 'surveySelect']
  connect () {
    this.initializeDateRangePicker()
  }

  userProChanged () {
    this.formTarget.submit()
  }

  surveyChanged () {
    $('.daterangepickerjs').val('')
  }

  initializeDateRangePicker () {
    $('.daterangepickerjs').daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'DD/MM/YYYY',
        cancelLabel: 'Annuler'
      }
    })
    $('.daterangepickerjs').on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'))

      $('#survey-select-1').val('').change()
      $('#survey-select-2').val('').change()
    })
    $('.daterangepickerjs').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('')
    })
  }
}
