import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['navLink']
  static values = { fullUrlMode: Boolean }

  connect () {
    this.#remove_active()
    if (this.fullUrlModeValue) {
      this.navLinkTargets.forEach(navLink => {
        if (`${window.location.pathname}${window.location.search}` === navLink.getAttribute('href')) {
          navLink.classList.add('Tabs__Link--active')
        }
      })
    } else {
      this.navLinkTargets.forEach(navLink => {
        if (window.location.pathname === navLink.getAttribute('href') || window.location.pathname === navLink.getAttribute('href').split('?')[0]) {
          navLink.classList.add('Tabs__Link--active')
        }
      })
    }
  }

  selectTab (event) {
    this.#remove_active()
    event.currentTarget.classList.add('Tabs__Link--active')
  }

  #remove_active () {
    this.navLinkTargets.forEach((navLink) => {
      navLink.classList.remove('Tabs__Link--active')
    })
  }
}
