import { Controller } from 'stimulus'

export class BaseController extends Controller {
  generateLegendConfig () {
    return {
      legend: {
        enableMouseTracking: false,
        itemStyle: {
          cursor: 'auto',
          fontFamily: 'Avenir Next'
        },
        labelFormatter: function () {
          return `<p class="mb-1 mt-0 fw-normal color-dark-purple">${this.name}</p>`
        },
        padding: 15,
        useHTML: true,
        symbolHeight: 5,
        symbolPadding: 10,
        squareSymbol: false,
        symbolWidth: 15
      },
      credits: {
        enabled: false
      }
    }
  }
}
