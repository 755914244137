import { csrfToken } from '@rails/ujs'

async function deleteQuery (url) {
  const res = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  })
  const data = await res.json()
  return data
}

async function getQuery (url) {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const data = await res.json()
  return data
}

async function putQuery (url, body, { asJson } = { asJson: true }) {
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      ...(asJson && { 'Content-Type': 'application/json' }),
      'X-CSRF-Token': csrfToken()
    },
    body
  })
  const data = await res.json()
  return data
}

async function patchQuery (url, body, { asJson } = { asJson: true }) {
  const res = await fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      ...(asJson && { 'Content-Type': 'application/json' }),
      'X-CSRF-Token': csrfToken()
    },
    body
  })
  const data = await res.json()
  return data
}

async function postQuery (url, body, withFile = false) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...(!withFile && { 'Content-Type': 'application/json' }),
      'X-CSRF-Token': csrfToken()
    },
    body
  })
  return res
}

export { deleteQuery, getQuery, postQuery, putQuery, patchQuery }
