import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['internableTypeSelect', 'internableIdSelect', 'commissionRateField', 'pepSelect', 'pepDescriptionSection']

  connect () {
    if (window.location.href.match(/new/)) {
      this.setProductType()
      this.setProductId()
    }
  }

  initialize () {
    if (this.hasPepSelectTarget && this.pepSelectTarget.value === 'exposed') {
      this.displayPepDescription()
    }
  }

  camelCaseToSnakeCase (string) {
    return string[0].toLowerCase() + string.slice(1, string.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  }

  checkPepDescription (e) {
    if (e.currentTarget.value === 'exposed') {
      this.displayPepDescription()
    } else {
      this.hidePepDescription()
    }
  }

  displayPepDescription () {
    if (this.pepDescriptionSectionTarget.classList.contains('d-none')) {
      this.pepDescriptionSectionTarget.classList.remove('d-none')
    }
  }

  hidePepDescription () {
    if (!this.pepDescriptionSectionTarget.classList.contains('d-none')) {
      this.pepDescriptionSectionTarget.classList.add('d-none')
    }
  }

  setProductType () {
    if (this.internableTypeSelectTarget.value) {
      this.productType = this.camelCaseToSnakeCase(this.internableTypeSelectTarget.value)
      this.getProductCommissionRate()
    }
  }

  setProductId () {
    if (this.internableIdSelectTarget.value) {
      this.productId = this.internableIdSelectTarget.value
      this.getProductCommissionRate()
    }
  }

  async getProductCommissionRate () {
    if (this.productId && this.productType) {
      const data = await this.getQuery(this.productType, this.productId)
      this.displayProductCommissionRate(data || '')
    }
  }

  displayProductCommissionRate (rate) {
    this.commissionRateFieldTarget.value = rate
  }

  async getQuery (type, id) {
    const url = `/administration/${type}s/${id}`
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    return data
  }
}
