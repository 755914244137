import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['all', 'request']

  selectAllRequests (event) {
    const isChecked = event.target.checked

    if (isChecked) {
      this.requestTargets.forEach(div => {
        div.querySelector('input').checked = true
      })
    } else {
      this.requestTargets.forEach(div => {
        div.querySelector('input').checked = false
      })
    }
  }
}
