import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['siretInput', 'userProSection', 'contractSection', 'resultSection']

  async sendSiret (e) {
    e.preventDefault()
    const data = await this.postQuery(this.siretInputTarget.value)
    this.displayResult(data)
  }

  async postQuery (siret) {
    const token = $('meta[name=csrf-token]').attr('content')
    const res = await fetch('/administration/nouvelle-recherche-entreprise', {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': token, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': true },
      body: JSON.stringify({ siret })
    })
    const data = await res.json()
    return data
  }

  displayResult (data) {
    if (this.hasResultSectionTarget) {
      this.resultSectionTarget.remove()
    }
    if (data.error) {
      this.displayError(data.error)
    } else if (data.user_pros && data.contracts) {
      this.displayUserPros(data.user_pros)
      this.displayContracts(data.contracts)
    }
    if (data.cotisations) {
      this.displayCotisations(data.cotisations)
    }
  }

  displayError (data) {
    const resultHtml = `
      <div class="row mt-2" data-cgrm-user-pros-searches--search-target='resultSection'>
        <div class="col-md-12">
          ${JSON.stringify(data)}
        </div>
      </div>
    `
    this.element.insertAdjacentHTML('beforeend', resultHtml)
  }

  displayUserPros (data) {
    data.forEach(userPro => {
      const userProHtml = `
        <div class="row mt-2" data-cgrm-user-pros-searches--search-target='resultSection'>
          <div class="col-md-12" data-cgrm-user-pros-searches--search-target='userProSection' data-code-societe=${userPro.code_societe}>
            <strong>Infos entreprise</strong><br>
            ${JSON.stringify(userPro)}        
          </div>
        </div>
      `
      this.element.insertAdjacentHTML('beforeend', userProHtml)
    })
  }

  displayContracts (data) {
    data.forEach(contract => {
      this.userProSectionTargets.forEach(userPro => {
        if (contract.code_societe === userPro.dataset.codeSociete) {
          const contractHtml = `
            <div class="col-md-11 offset-md-1" data-cgrm-user-pros-searches--search-target='contractSection' data-code-police=${contract.code_police}>
              <strong>Contrat</strong><br>
              ${JSON.stringify(contract)}        
            </div>
          `
          userPro.insertAdjacentHTML('afterend', contractHtml)
        }
      })
    })
  }

  displayCotisations (data) {
    data.forEach(cotisation => {
      this.contractSectionTargets.forEach(contract => {
        if (cotisation.contract_code_police === contract.dataset.codePolice) {
          const cotisationHtml = `
            <div class="col-md-10 offset-md-2">
              <strong>Cotisation</strong><br>
              ${JSON.stringify(cotisation)}        
            </div>
          `
          contract.insertAdjacentHTML('afterend', cotisationHtml)
        }
      })
    })
  }
}
