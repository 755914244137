import { Controller } from 'stimulus'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static values = { url: String, user: String }

  async toggleFeature (event) {
    const currentTarget = event.currentTarget
    try {
      currentTarget.disabled = true
      await patchQuery(this.urlValue, JSON.stringify({
        [this.userValue]: {
          [currentTarget.value]: !currentTarget.checked
        }
      }))
    } catch (e) {
      throw new Error(e)
    } finally {
      currentTarget.disabled = false
    }
  }
}
