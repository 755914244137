import BaseInfiniteScrollController from 'controllers/shared/base_infinite_scroll_controller'
import { itemTemplate } from 'src/js/templates/employee_administration/employee_absence_events/index.jsx'
import { renderBeforeEnd } from 'helpers/jsx'

export default class extends BaseInfiniteScrollController {
  _fetchNewPage (data) {
    data.forEach(item => {
      renderBeforeEnd(itemTemplate(item), this.element)
    })
  }
}
