import { Controller } from 'stimulus'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['dateField']
  static values = { payload: Object, url: String }

  async submit () {
    try {
      const response = await patchQuery(this.getUrl(), JSON.stringify({
        [this.payloadValue.modelName]: {
          [this.payloadValue.name]: this.dateFieldTarget.value
        }
      }))
      this.handleResponse(response)
    } catch (e) {
      toastr.error('Une erreur est survenue, veuillez réessayez.')
    }
  }

  getUrl () {
    if (this.urlValue) {
      return this.urlValue
    }
    return this.dateFieldTarget.closest('form').action
  }

  handleResponse (response) {
    if (response.success) {
      toastr.success(response.message)
    } else {
      toastr.error(response.message)
    }
  }
}
