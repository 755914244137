import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['banner']

  async handleClick (e) {
    this.bannerTarget.classList.add('BannerPromo__Closed')
    setTimeout(() => {
      this.bannerTarget.classList.add('d-none')
    }, 400)
  }
}
