import { BaseController } from '../base_controller'
import Highcharts from 'highcharts/highmaps.js'
import HighchartsMore from 'highcharts/highcharts-more.js'

export default class extends BaseController {
  static values = {
    containerId: String,
    series: Array,
    name: String,
    colors: Array
  }

  connect () {
    HighchartsMore(Highcharts)
    Highcharts.chart(this.containerIdValue, {
      ...this.generateLegendConfig(),
      colors: this.colorsValue,
      chart: {
        type: 'packedbubble',
        height: '100%'
      },
      title: {
        text: '',
        align: ''
      },
      tooltip: {
        useHTML: true,
        pointFormat: `${this.nameValue}: <b>{point.amount}€<b>`,
        style: {
          fontFamily: 'Avenir Next'
        }
      },
      plotOptions: {
        packedbubble: {
          minSize: '20%',
          maxSize: '180%',
          dashStyle: undefined,
          zMin: 0,
          zMax: 1000,
          layoutAlgorithm: {
            splitSeries: false,
            gravitationalConstant: 0.02
          },
          marker: {
            fillOpacity: 1,
            lineWidth: 0,
            states: {
              hover: {
                enabled: false
              },
              select: {
                enabled: false
              }
            }
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: {
              textOutline: 'none',
              fontWeight: 'normal'
            }
          }
        }
      },
      series: this.seriesValue
    })
  }
}
