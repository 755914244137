import { Controller } from 'stimulus'
import { Splide } from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

import '@splidejs/splide/dist/css/splide.min'

export default class extends Controller {
  static values = {
    perPage: Number,
    speed: Number
  }

  connect () {
    const splide = new Splide(this.element, {
      autoScroll: {
        speed: this.speedValue
      },
      classes: {
        pagination: 'splide__pagination your-class-pagination d-none',
        page: 'splide__pagination__page your-class-page d-none'
      },
      perPage: this.perPageValue,
      type: 'loop',
      gap: 10,
      breakpoints: {
        640: {
          perPage: 2
        },
        768: {
          perPage: 4
        },
        1200: {
          perPage: 4
        }
      }
    })
    splide.mount({ AutoScroll })
  }
}
