import { render } from 'jsx-no-react'

function renderAndReplace (template, target) {
  target.querySelectorAll('*').forEach(n => n.remove())
  render(template, target)
}

function renderAfterEnd (template, target) {
  target.insertAdjacentElement('afterend', template)
}

function renderBeforeEnd (template, target) {
  target.insertAdjacentElement('beforeend', template)
}

function renderBeforeBegin (template, target) {
  target.insertAdjacentElement('beforebegin', template)
}

export { renderAndReplace, renderAfterEnd, renderBeforeEnd, renderBeforeBegin }
