import { Controller } from 'stimulus'
import { modalTitle, modalBody } from 'src/js/templates/internal_contracts/company_provident_insurance/subscription.jsx'
import { putQuery } from 'helpers/requests'

export default class extends Controller {
  static values = {
    currentEmployeesCount: Number,
    contractEmployeesCount: Number,
    internalContractId: Number
  }

  checkStepValidation () {
    if (this.currentEmployeesCountValue !== this.contractEmployeesCountValue) {
      this.displayModal()
    } else {
      this.validateStep()
    }
  }

  displayModal () {
    $('#universal-modal-lg-company-administration').find('.custom-title').html(modalTitle())
    $('#universal-modal-lg-company-administration').find('.custom-body').html(modalBody(this.contractEmployeesCountValue, this.currentEmployeesCountValue, this.internalContractIdValue))
    const modalElement = document.querySelector('#universal-modal-lg-company-administration')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.show()
  }

  closeModal () {
    const modalElement = document.querySelector('#universal-modal-lg-company-administration')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.toggle()
  }

  async updateEmployeesCount () {
    const data = await putQuery(`/espace-entreprise/contrats-souscrits/${this.internalContractIdValue}`, JSON.stringify({ internal_contract: { salaries_count: this.currentEmployeesCountValue }, subscription_employees_count: true }))
    if (data && data.id) {
      this.validateStep()
    } else {
      this.closeModal()
    }
  }

  validateStep () {
    window.location.href = `/espace-entreprise/contrats-souscrits/${this.internalContractIdValue}/validate_personnel_list`
  }
}
