import { Controller } from 'stimulus'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['endDate', 'signButton', 'externalInsurer']
  static values = {
    url: String
  }

  connect () {
    this.toggleSignButton()
  }

  async update () {
    try {
      const res = await patchQuery(this.urlValue, JSON.stringify({
        health_mutual_affiliation: {
          exemption_expiration_date: this.hasEndDateTarget ? this.endDateTarget.value : null,
          exemption_banking_actor_id: this.externalInsurerTarget.value
        }
      }))

      if (res) {
        this.toggleSignButton()
      } else {
        throw new Error('update failed')
      }
    } catch (e) {
      toastr.error('Mise à jour impossible')
      throw new Error(e)
    }
  }

  toggleSignButton () {
    if ((!this.hasEndDateTarget && this.externalInsurerTarget.value) || (this.endDateTarget.value && this.externalInsurerTarget.value)) {
      this.signButtonTarget.classList.remove('disabled')
    } else {
      this.signButtonTarget.classList.add('disabled')
    }
  }
}
