import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['insureeChoice', 'partnerBirthDateField', 'childrenCountField', 'partnerBirthDateContainer',
    'childrenBirthDateField', 'childrenBirthDateContainer', 'childrenCount', 'childrenTitle']

  connect () {
    this.displayContainers()
  }

  displayContainers () {
    const insureeChoice = document.getElementById('insuree-choice').value

    if (insureeChoice === 'with_partner') {
      this.partnerBirthDateContainerTarget.classList.remove('d-none')
      this.partnerBirthDateContainerTarget.required = true
      this.childrenBirthDateContainerTarget.classList.add('d-none')
      this.childrenCountFieldTarget.value = null
      this.childrenBirthDateFieldTargets.forEach((e) => (e.value = ''))
      this.childrenBirthDateFieldTargets.forEach((e) => (e.required = false))
    } else if (insureeChoice === 'with_children') {
      this.partnerBirthDateContainerTarget.classList.add('d-none')
      this.partnerBirthDateFieldTarget.value = null
      this.partnerBirthDateFieldTarget.required = false
      this.childrenBirthDateContainerTarget.classList.remove('d-none')
    } else if (insureeChoice === 'with_partner_and_children') {
      this.partnerBirthDateContainerTarget.classList.remove('d-none')
      this.childrenBirthDateContainerTarget.classList.remove('d-none')
    } else {
      this.partnerBirthDateContainerTarget.classList.add('d-none')
      this.partnerBirthDateFieldTarget.value = null
      this.childrenCountFieldTarget.value = null
      this.partnerBirthDateFieldTarget.required = false
      this.childrenBirthDateContainerTarget.classList.add('d-none')
      this.childrenBirthDateFieldTargets.forEach((e) => (e.value = ''))
      this.childrenBirthDateFieldTargets.forEach((e) => (e.required = false))
    }
  }

  addChildrenDateFields () {
    this.childrenCount = document.getElementById('children-count').value
    const currentFieldsCount = this.childrenBirthDateFieldTargets.length
    const dateField = this.childrenBirthDateFieldTarget
    this.changeChildTitle()
    if (this.childrenCount > currentFieldsCount) {
      for (let i = 0; i < (this.childrenCount - currentFieldsCount); i++) {
        const newDateField = dateField.cloneNode(false)
        newDateField.setAttribute('name', 'individual_quotation_demand[individual_quotation_demandable_attributes][birth_dates][]')
        newDateField.setAttribute('id', `individual_quotation_dmd_birth_date${i}`)
        newDateField.removeAttribute('value')
        this.childrenBirthDateFieldTargets.slice(-1)[0].after(newDateField)
      }
    } else if (this.childrenCount < currentFieldsCount) {
      const elementsToRemove = this.childrenBirthDateFieldTargets.slice(-(currentFieldsCount - this.childrenCount))
      elementsToRemove.forEach(element => {
        element.remove()
      })
    }
  }

  updateChildrenCountField () {
    this.childrenCountTarget.value = this.childrenBirthDateFieldTargets.length
  }

  changeChildTitle () {
    const childrenCount = parseInt(this.childrenCount)
    if (childrenCount > 1) {
      this.childrenTitleTarget.textContent = 'Quelles sont les dates de naissance des enfants ?'
    } else {
      this.childrenTitleTarget.textContent = 'Quelle est la date de naissance de l\' enfant ?'
    }
  }
}
