import { BaseController } from '../base_controller'
import Highcharts from 'highcharts/highmaps.js'

export default class extends BaseController {
  static values = {
    containerId: String,
    series: Array,
    categories: Array,
    colors: Array
  }

  connect () {
    Highcharts.chart(this.containerIdValue, {
      colors: this.colorsValue,
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      ...this.generateLegendConfig(),
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
        }
      },
      xAxis: [{
        categories: this.categoriesValue,
        reversed: false,
        labels: {
          step: 1
        },
        accessibility: {
          description: 'Age (male)'
        }
      }, { // mirror axis on right side
        opposite: true,
        reversed: false,
        categories: this.categoriesValue,
        linkedTo: 0,
        labels: {
          step: 1
        },
        accessibility: {
          description: 'Age (female)'
        }
      }],
      yAxis: {
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value) + '%'
          }
        },
        accessibility: {
          description: 'Percentage population',
          rangeDescription: 'Range: 0 to 5%'
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.category + '</b><br/>' + Highcharts.numberFormat(Math.abs(this.point.y), 1) + '%'
        },
        style: {
          fontFamily: 'Avenir Next'
        }
      },
      series: this.seriesValue
    })
  }
}
