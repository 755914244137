import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['templateOption']

  newOption (event) {
    this.templateOptionTarget.parentNode.insertAdjacentHTML(
      'beforeend', this.templateOptionTarget.outerHTML
    )
    const lastTemplateOption = this.templateOptionTargets.at(-1)

    this.#removeIdInput(lastTemplateOption)
    this.#removeLink(lastTemplateOption)
    this.#resetTemplateOptions(lastTemplateOption)
    this.#destroyOptionFalse(lastTemplateOption)
    this.#updateElements(lastTemplateOption)
  }

  removeCurrentOption (event) {
    const templateOption = event.target.parentNode

    this.#hiddenOption(templateOption)
    this.#destroyOptionTrue(templateOption)
  }

  removeCurrentFile (event) {
    const parentNode = event.target.parentNode
    this.#removeFile(parentNode)
  }

  purgeFileAttached (event) {
    const parentNode = event.target.parentNode
    this.#purgeFile(parentNode)
  }

  #updateElements (templateOption) {
    const elements = templateOption.querySelectorAll('input, select, label')
    elements.forEach((element) => this.#updateElement(element))
  }

  #resetTemplateOptions (templateOption) {
    this.#resetTemplateOptionInputs(templateOption)
    this.#resetTemplateOptionSelects(templateOption)
  }

  #resetTemplateOptionInputs (templateOption) {
    const inputElements = templateOption.querySelectorAll('input')
    inputElements.forEach((element) => { element.value = null })
  }

  #resetTemplateOptionSelects (templateOption) {
    const selectOptionSelected = templateOption.querySelectorAll('[selected]')
    selectOptionSelected.forEach((element) => element.removeAttribute('selected'))
  }

  #getIdValue (element) {
    const input = element.getElementsByClassName('id_input').item(0)

    if (input == null) {
      return 0
    } else {
      return Number(input.value)
    }
  }

  #newId () {
    const ids = this.templateOptionTargets.map((e) => this.#getIdValue(e))
    const maxId = ids.sort().at(-1)
    return (maxId + this.templateOptionTargets.length)
  }

  #updateElement (element) {
    const newId = this.#newId()

    if (element.tagName === 'LABEL') {
      this.#updateAttribute(element, 'for', newId)
    } else {
      this.#updateAttribute(element, 'name', newId)
      this.#updateAttribute(element, 'id', newId)
    }
  }

  #updateAttribute (element, tagName, newId) {
    const oldValue = element.getAttribute(tagName)
    const newValue = oldValue.replace(/\d+/, newId)
    element.setAttribute(tagName, newValue)
  }

  #removeIdInput (templateOption) {
    const idInput = templateOption.getElementsByClassName('id_input').item(0)
    idInput.remove()
  }

  #removeLink (templateOption) {
    const link = templateOption.querySelector('a')
    link.remove()
  }

  #hiddenOption (templateOption) {
    templateOption.classList.add('d-none')
  }

  #removeFile (templateOption) {
    const fileInput = templateOption.querySelector('input[name$="[file]"]')
    fileInput.value = null
  }

  #purgeFile (templateOption) {
    const purgeFileAttached = templateOption.querySelector('input[name$="[purge_file_attached]"]')
    purgeFileAttached.value = true
  }

  #destroyOptionTrue (templateOption) {
    this.#destroyInput(templateOption).value = true
  }

  #destroyOptionFalse (templateOption) {
    this.#destroyInput(templateOption).value = false
  }

  #destroyInput (templateOption) {
    return templateOption.getElementsByClassName('destroy_input').item(0)
  }
}
