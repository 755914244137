import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['otherEmployeesCountFields', 'employeeCadreCount', 'employeeNoCadreCount', 'employeesCount', 'averageAgeEmployees', 'messageError']

  connect () {
    const buttonSubmit = document.querySelector('button[type="submit"]')
    const otherEmployeesCountFields = this.otherEmployeesCountFieldsTarget
    if (this.employeesCountTarget.value && this.averageAgeEmployeesTarget.value) {
      buttonSubmit.disabled = false
      otherEmployeesCountFields.classList.remove('d-none')
    } else {
      buttonSubmit.disabled = true
      otherEmployeesCountFields.classList.add('d-none')
    }
  }

  handleClickSubmit (e) {
    if (this.employeeCadreCountTarget && this.employeeNoCadreCountTarget) {
      const cadreValue = this.employeeCadreCountTarget.value
      const nonCadreValue = this.employeeNoCadreCountTarget.value
      if (cadreValue || nonCadreValue) {
        const employeesCount = this.employeesCountTarget.value
        if ((parseInt(cadreValue) || 0) + (parseInt(nonCadreValue) || 0) > parseInt(employeesCount)) {
          e.preventDefault()
          this.messageErrorTarget.classList.remove('d-none')
          return false
        }
      }
      return true
    }
  }

  handleChangeRequired (e) {
    const buttonSubmit = document.querySelector('button[type="submit"]')
    const otherEmployeesCountFields = this.otherEmployeesCountFieldsTarget
    if (this.employeesCountTarget.value && this.averageAgeEmployeesTarget.value) {
      buttonSubmit.disabled = false
      otherEmployeesCountFields.classList.remove('d-none')
    } else {
      buttonSubmit.disabled = true
      otherEmployeesCountFields.classList.add('d-none')
    }
  }
}
