import { Controller } from 'stimulus'
import { postQuery, deleteQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['hearthIcon', 'hearthSpan']

  static values = {
    creationUrl: String,
    deletionUrl: String,
    practitionerId: Number,
    practitionerType: String,
    ownerType: String,
    ownerId: String,
    name: String,
    practitionerOptions: {},
    clickCounter: Number
  }

  async favourise (e) {
    e.preventDefault()

    this.clickCounterValue += 1
    if (this.clickCounterValue === 1) {
      if (this.hearthIconTarget.className.includes('fa-solid')) {
        await this.emptyHearth()
        await this.removeFromFavourite()
      } else {
        await this.fulfillHearth()
        await this.addToFavourite()
      }
      location.reload()
    }
  }

  async addToFavourite (e) {
    const formData = new FormData()

    formData.append('favourite_practitioner[owner_type]', this.ownerTypeValue)
    formData.append('favourite_practitioner[owner_id]', this.ownerIdValue)
    formData.append('favourite_practitioner[practitioner_id]', this.practitionerIdValue)
    formData.append('favourite_practitioner[practitioner_type]', this.practitionerTypeValue)
    formData.append('favourite_practitioner[name]', this.nameValue)
    formData.append('favourite_practitioner[options]', JSON.stringify(this.practitionerOptionsValue))
    try {
      await postQuery(this.creationUrlValue, formData, true)
    } catch (e) {
    }
  }

  async removeFromFavourite (e) {
    try {
      await deleteQuery(this.deletionUrlValue)
    } catch (e) {
    }
  }

  async fulfillHearth () {
    this.hearthIconTarget.classList.replace('fa-light', 'fa-solid')
    this.hearthIconTarget.classList.replace('color-grey', 'color-red')
    this.hearthSpanTarget.classList.add('disabled')
  }

  async emptyHearth (e) {
    this.hearthIconTarget.classList.replace('fa-solid', 'fa-light')
    this.hearthIconTarget.classList.replace('color-red', 'color-grey')
  }
}
