import { BaseController } from '../base_controller'
import Highcharts from 'highcharts/highmaps.js'

export default class extends BaseController {
  static values = {
    containerId: String,
    categories: Array,
    yAxisLabel: String,
    series: Array,
    unit: String,
    precision: String,
    colors: Array
  }

  connect () {
    Highcharts.chart(this.containerIdValue, {
      colors: this.colorsValue,
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.categoriesValue,
        crosshair: true
      },
      yAxis: {
        labels: {
          enabled: false
        },
        enabled: false,
        min: 0,
        title: {
          text: this.yAxisLabelValue
        }
      },
      ...this.generateLegendConfig(),
      tooltip: {
        backgroundColor: '#fff',
        borderColor: 'transparent',
        borderRadius: 10,
        borderWidth: 0,
        headerFormat: '<span style="font-size:12px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        `<td style="padding:0;"><b>{point.y:.${this.precisionValue}} ${this.unitValue}</b></td></tr>`,
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
        style: {
          fontFamily: 'Avenir Next'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: this.seriesValue
    })
  }
}
