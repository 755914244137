import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['validationStateInput']

  handleClick (e) {
    e.preventDefault()

    const { status } = e.params
    this.validationStateInputTarget.value = status

    this.element.submit()
  }
}
