import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['canvas']

  static values = {
    data: Array,
    displayLegend: Boolean,
    aspectRatio: Number
  }

  connect () {
    const data = {
      datasets: [{
        data: this.dataValue,
        backgroundColor: [
          '#6D092A',
          '#FFFFFF'
        ],
        hoverBackgroundColor: [
          '#6D092A',
          '#FFFFFF'
        ],
        hoverBorderWidth: 0,
        hoverOffset: 0
      }]
    }
    const myChart = new Chart( // eslint-disable-line
      this.canvasTarget,
      {
        type: 'doughnut',
        data,
        options: {
          aspectRatio: this.aspectRatioValue,
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        }
      }
    )
  }
}
