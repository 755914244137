import Rails from '@rails/ujs'
import 'controllers'
import '../components'

import 'trix'
import '@rails/actiontext'
// import '@rails/actioncable'
import '@hotwired/turbo-rails'
import * as ActionCable from '@rails/actioncable'

ActionCable.logger.enabled = true

Turbo.session.drive = false // eslint-disable-line

Rails.start()
