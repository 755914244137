import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('.alert').first().addClass('d-none')

    const modalElement = document.querySelector('#universal-modal-lg-company-administration')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.show()
  }
}
