import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['product']

  connect () {
    const products = this.productTargets.filter(item => item.querySelector('select').value.length > 0)
    this.calculateCol(12 / (products.length === 0 ? 2 : products.length))
    if (products.length > 2) {
      products.slice(2).forEach(item => item.classList.toggle('d-none'))
    }
  }

  addProduct (e) {
    e.preventDefault()
    const hideProduct = this.productTargets.filter(item => item.classList.contains('d-none'))
    switch (hideProduct.length) {
      case 1:
        this.calculateCol(3)
        this.toggleProduct(hideProduct[0])
        break
      case 2:
        this.calculateCol(4)
        this.toggleProduct(hideProduct[0])
        break
      default:
    }
  }

  toggleProduct (element) {
    element.classList.toggle('d-none')
  }

  calculateCol (colNumber) {
    this.productTargets.forEach(item => {
      item.classList.remove('col-md-6', 'col-md-4', 'col-md-3', 'col')
      item.classList.add(`col-md-${colNumber}`)
    })
  }
}
