import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class BaseShowController extends Controller {
  static targets = ['listItem', 'detailTemplateContainer']

  static values = {
    filter: String,
    defaultUrl: String
  }

  connect () {
    let initialItem
    const id = this.getParamsId()
    // if there are an id in URl, load the correct absence
    if (id) {
      [initialItem] = this.listItemTargets.filter((item) => item.dataset.id === id)
      if (initialItem) {
        this.loadElement(this.defaultUrlValue, initialItem)
      } else {
        this.setIdToUrl(this.listItemTargets[0].dataset.id)
        this.loadElement(this.defaultUrlValue, this.listItemTargets[0])
      }
    } else {
      // default load
      this.loadElement(this.defaultUrlValue, this.listItemTargets[0])
    }
  }

  async loadElement (url, target) {
    try {
      const data = await getQuery(url)

      this.listItemTargets.forEach((item) => item.classList.remove('EmployeeAbsenceEvents_Card--active'))
      target.classList.add('EmployeeAbsenceEvents_Card--active')

      this.injectTemplate(data)
    } catch (e) {
      throw new Error(e)
    }
  }

  // In the child class, declare this function to inject the right template in the html
  // exemple : javascript/controllers/employee_absence_events/infinite_scroll_controller.js
  injectTemplate (data) {
    throw new Error('You must implement injectTemplate function')
  }

  getParamsId () {
    return new URLSearchParams(window.location.search).get('id')
  }

  setIdToUrl (id) {
    const url = new URL(window.location.href)
    url.searchParams.set('id', id)
    history.pushState(null, null, url.toString())
  }

  handleClick (e) {
    event.preventDefault()

    const { url, id } = e.currentTarget.dataset

    if (id === this.getParamsId()) return

    this.loadElement(url, e.currentTarget)
    this.setIdToUrl(id)
  }
}
