import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const that = this
    this.careOfBeneficiariesChoiceImpact()
    this.cotisationStructureChoiceImpact()
    $('input:radio[name="internal_contract[care_of_beneficiaries]"]').on('change', function () {
      that.careOfBeneficiariesChoiceImpact()
      that.cotisationStructureChoiceImpact()
    })
    $('#internal_contract_cotisation_structure_enum').on('change', function () {
      that.cotisationStructureChoiceImpact()
      that.careOfBeneficiariesChoiceImpact()
    })
    $('#internal_contract_health_mutual_employer_contribution').on('change', function () {
      that.updateRangeNumber(this.value)
    })
  }

  updateRangeNumber (val) {
    document.getElementById('rangeNumber').innerHTML = val + '%'
  };

  careOfBeneficiariesChoiceImpact () {
    if ($('#internal_contract_care_of_beneficiaries_true').is(':checked')) {
      $('#internal_contract_employee_payment_method_bulletin_de_paie_recommandé').prop('checked', true)
      $('#internal_contract_employee_payment_method_bulletin_de_paie_recommandé').addClass('disabled')
      $('label[for=internal_contract_employee_payment_method_compte_en_banque_du_salarié]').hide()
    } else {
      $('#internal_contract_employee_payment_method_bulletin_de_paie_recommandé').removeClass('disabled')
      $('#internal_contract_employee_payment_method_bulletin_de_paie_recommandé').prop('checked', false)
      $('label[for=internal_contract_employee_payment_method_compte_en_banque_du_salarié]').show()
    }
  }

  cotisationStructureChoiceImpact () {
    const cotisationStructure = $('#internal_contract_cotisation_structure_enum')
    if (cotisationStructure.val() === 'isole_family' || cotisationStructure.val() === 'isole_duo_family' || cotisationStructure.val() === 'unique') {
      $('#internal_contract_employee_payment_method_bulletin_de_paie_recommandé').prop('checked', true)
      $('#internal_contract_employee_payment_method_bulletin_de_paie_recommandé').addClass('disabled')
      $('#internal_contract_options_employee_payment_method_compte_en_banque_du_salarié').hide()
      $('.internal_contract_options_employee_payment_method_compte_en_banque_du_salarié').hide()
      if (cotisationStructure.val() === 'unique') {
        $('#internal_contract_care_of_beneficiaries_true').prop('checked', true)
        $('#internal_contract_care_of_beneficiaries_false').prop('checked', false)
        $('#internal_contract_care_of_beneficiaries_false').hide()
        $('.internal_contract_care_of_beneficiaries_false').hide()
      } else {
        $('#internal_contract_care_of_beneficiaries_false').show()
        $('.internal_contract_care_of_beneficiaries_false').show()
      }
    }
  }
}
