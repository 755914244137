function currentParams () {
  const params = window.location.search
  return params.length > 0 ? params : '?'
}

function addOrUpdateParamsOfCurrentUrl (name, value) {
  const url = new URL(window.location)
  url.searchParams.set(name, value)

  window.history.pushState({}, '', url)
}

function getParam (name) {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get(name)
}

export { getParam, currentParams, addOrUpdateParamsOfCurrentUrl }
