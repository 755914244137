import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.selectAllTargets()
  }

  selectAllTargets () {
    $('.select-options').on('change', function () {
      if (this.checked) {
        $(`#${$(this).attr('data-target')} option`).each(function () {
          $(this).attr('selected', 'selected')
        })
      } else {
        $(`#${$(this).attr('data-target')} option`).each(function () {
          $(this).removeAttr('selected')
        })
      }
    })
  }
}
