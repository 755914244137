import { Controller } from 'stimulus'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static values = { url: String }

  async toggleFeature (event) {
    const currentTarget = event.currentTarget
    const body = {
      status: currentTarget.checked
    }
    try {
      currentTarget.disabled = true
      await patchQuery(this.urlValue, JSON.stringify(body))
    } catch (e) {
      throw new Error(e)
    } finally {
      currentTarget.disabled = false
    }
  }
}
