import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'button', 'errorMessage']
  static values = {
    counter: Number
  }

  connect () {
    this.clonedForm = this.formTarget.cloneNode(true)
    this.clonedForm.classList.remove('d-none')
  }

  addDocumentForm () {
    if (this.formTarget.classList.contains('d-none')) {
      this.formTarget.classList.remove('d-none')
      return
    }
    if (this.formTargets[this.formTargets.length - 1].querySelector('.dropzone-input').files.length === 0) {
      return
    }

    this.insertNewDragAndDropZone()
    this.checkDocLimit()
  }

  insertNewDragAndDropZone () {
    let newClone = this.clonedForm.cloneNode(true)

    // change name of drag and drop zone
    const newCloneInput = newClone.querySelector('.dropzone-input')
    let newName = newCloneInput.name.replace(/[0-9]/g, this.counterValue)
    newCloneInput.setAttribute('name', newName)
    this.formTargets[this.formTargets.length - 1].after(newClone)

    // change name of doc type input
    const newCloneDocTypeInput = newClone.querySelector('.doc-type-input')
    newName = newCloneDocTypeInput.name.replace(/[0-9]/g, this.counterValue)
    newCloneDocTypeInput.setAttribute('name', newName)
    this.formTargets[this.formTargets.length - 1].after(newClone)

    newClone = ''
    this.counterValue++
  }

  checkDocLimit () {
    if (this.formTargets.length >= 9) {
      this.buttonTarget.classList.add('d-none')
      this.buttonTarget.insertAdjacentHTML('afterend',
        '<p>La limite est de 10 fichiers par envoi, si vous souhaitez transmettre des documents supplémentaires, nous vous invitons à faire une nouvelle demande.</p>'
      )
    }
  }
}
