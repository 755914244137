import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.init()
  }

  init () {
    $('[data-select="select2-multiple-default"]').select2({
      placeholder: '',
      width: '100%',
      closeOnSelect: false,
      language: {
        noResults: function (params) {
          return 'Aucun résultat'
        }
      }
    })
  }
}
