import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['navLink']
  static values = { fullUrlMode: Boolean }

  connect () {
    if (this.fullUrlModeValue) {
      this.navLinkTargets.forEach(navLink => {
        if (`${window.location.pathname}${window.location.search}` === navLink.getAttribute('href')) {
          navLink.classList.add('Tabs__Link--active')
        }
      })
    } else {
      this.navLinkTargets.forEach(navLink => {
        if (window.location.pathname === navLink.getAttribute('href') || window.location.pathname === navLink.getAttribute('href').split('?')[0]) {
          navLink.classList.add('Tabs__Link--active')
        }
      })
    }
  }
}
