import { Controller } from 'stimulus'
import Chart from 'highcharts/es-modules/Core/Chart/Chart.js'
import Column from 'highcharts/es-modules/Series/Column/ColumnSeries.js' // eslint-disable-line
import Highcharts from 'highcharts/highmaps.js'

export default class extends Controller {
  static values = {
    categories: Array,
    series: Array
  }

  connect () {
    this.drawChart()
  }

  drawChart () {
    const myChart = new Chart('evolutionSickLeaveAbsencesBarChart', { // eslint-disable-line

      chart: {
        type: 'column',
        style: {
          fontFamily: 'Avenir Next'
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: '',
        style: {
          'font-size': '1.571428571rem',
          color: '#2E047E',
          'font-weight': '600'
        }
      },
      xAxis: {
        categories: this.categoriesValue
      },
      yAxis: {
        min: 0,
        step: 1,
        title: {
          text: null
        }
      },
      tooltip: {
        style: {
          color: '#ffffff'
        },
        borderColor: '#130F41',
        borderRadius: 10,
        borderWidth: 3,
        outside: true,
        formatter: function () {
          return `Nombre d'arrêts : <b>${Highcharts.numberFormat(Math.abs(this.point.y), 0)}</b><br/>Nombre de jours: <b>${this.point.days}</b>`
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: '#6D092A',
        data: this.seriesValue
      }]
    })
  }
}
