function employeeAdministratorSmallText () {
  return {
    annualRecurrenceText: `
      <p id="monthly-message" class="mt-3 small mb-0" data-travel-tickets-recurrences--form-target='recurrenceSmallText'>
        Vous devrez fournir votre justificatif de transport une fois par an à votre employeur.
      </p>
    `,
    monthlyRecurrenceText: `
      <p id="monthly-message" class="mt-3 small mb-0" data-travel-tickets-recurrences--form-target='recurrenceSmallText'>
        Vous devrez fournir votre justificatif de transport à votre employeur chaque mois.
      </p>
    `
  }
}

function companyAdministratorSmallText () {
  return {
    annualRecurrenceText: `
      <p id="monthly-message" class="mt-3 small" data-travel-tickets-recurrences--form-target='recurrenceSmallText'>
        L'employé devra fournir son justificatif de transport une fois par an.
      </p>
    `,
    monthlyRecurrenceText: `
      <p id="monthly-message" class="mt-3 small" data-travel-tickets-recurrences--form-target='recurrenceSmallText'>
        L'employé devra fournir son justificatif de transport chaque mois.
      </p>
    `
  }
}

function travelType () {
  return {
    bike: `
      <div class="mt-3" data-travel-tickets-recurrences--form-target='bikeSmallText'>
        <small>Destinée aux salariés qui pédalent entre leur domicile et leur lieu de travail. Facultative, l'indemnité vélo est fixée à 0,25 € par kilomètre parcouru et est exonérée de charges sociales dans la limite de 200 € annuels.</small>
      </div>
    `,
    personal: `
      <div class="mt-3" data-travel-tickets-recurrences--form-target='travelTypeSmallText'>
        <small>Il peut s'agir par exemple de frais de carburant ou d'alimentation électrique. La participation de l'employeur à ces frais est facultative.</small>
      </div>
    `,
    public: `
      <div class="mt-3" data-travel-tickets-recurrences--form-target='travelTypeSmallText'>
        <small class="form-text text-muted mt-3">
          <span>L'employeur a l'obligation de participer à 50 % aux :</span>    
          <ul class="mb-1">
            <li>titres d'abonnement aux transports publics de personnes ;</li>
            <li>titres de services publics de location de vélo.</li>
          </ul>
          <span>Cela concerne également les stagiaires, et les salariés à temps partiel sans prorata aux jours travaillés.</span>    
        </small>
      </div>
    `
  }
}

function employeeAnnualProofEndDate (date) {
  return `<p class="mt-3 small" data-travel-tickets-recurrences--form-target='proofEndDateSmallText'>Votre prochain justificatif sera demandé le ${date}.</p>`
}

function companyAdministratorAnnualProofEndDate (date) {
  return `<p class="mt-3 small" data-travel-tickets-recurrences--form-target='proofEndDateSmallText'>Le prochain justificatif sera demandé à votre employé le ${date}.</p>`
}

function employeeMonthlyProofEndDate (date) {
  return `<p class="mt-3 small" data-travel-tickets-recurrences--form-target='proofEndDateSmallText'>Votre titre de transport récurrent prendra fin le ${date}.</p>`
}
function companyAdministratorMonthlyProofEndDate (date) {
  return `<p class="mt-3 small" data-travel-tickets-recurrences--form-target='proofEndDateSmallText'>Ce titre de transport récurrent prendra fin le ${date}.</p>`
}

function bikeSmallText () {
  return `
    <div class="background-white card-style-v2 container-block-style p-4 background-pattens-blue mt-3 shadow-none" data-travel-tickets-recurrences--form-target='travelTypeSmallText'>
      <div class="h-100 ">
        <div class="color-dark-purple">
          Besoin d'aide pour estimer le montant de l'indemnité vélo ? <a class="color-cyan" target="_blank" href="https://www.indemnitevelo.fr/">Cliquez ici !</a>
        </div>
      </div>
    </div>`
}

export {
  employeeAdministratorSmallText, companyAdministratorSmallText, travelType, employeeAnnualProofEndDate,
  companyAdministratorAnnualProofEndDate, bikeSmallText, employeeMonthlyProofEndDate, companyAdministratorMonthlyProofEndDate
}
