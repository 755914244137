import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'
import { modalTitle, modalBody } from 'src/js/templates/company_administration/employees/form.jsx'

export default class extends Controller {
  static targets = ['emailField', 'form', 'startDateField']

  async checkExistingEmployees (e) {
    if (this.formTarget.checkValidity()) {
      e.preventDefault()
      const data = await this.getInactiveEmployees(this.emailFieldTarget.value)
      if (data && data.length > 0) {
        this.displayModal(this.emailFieldTarget.value, data[0].id, this.startDateFieldTarget.value)
      } else {
        const input = document.createElement('input')
        input.setAttribute('name', e.target.name)
        input.setAttribute('value', e.target.value)
        input.setAttribute('type', 'hidden')

        this.formTarget.appendChild(input)
        this.formTarget.submit()
      }
    }
  }

  async getInactiveEmployees (email) {
    const response = await getQuery(`/espace-entreprise/salaries/reintegrer-un-employe-etape-1?employee_email=${email}`)
    return response
  }

  displayModal (email, id, startDate) {
    $('#universal-modal-lg-company-administration').find('.custom-title').html(modalTitle())
    $('#universal-modal-lg-company-administration').find('.custom-body').html(modalBody(email, id, startDate))
    const modalElement = document.querySelector('#universal-modal-lg-company-administration')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement) // eslint-disable-line
    modal.show()
  }
}
