import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    dataset: Array
  }

  connect () {
    google.charts.load('current', { packages: ['corechart'] })
    google.charts.setOnLoadCallback(this.drawChart.bind(this, this.datasetValue))
  }

  drawChart (data) {
    const visualizationData = google.visualization.arrayToDataTable(data)
    const chart = new google.visualization.PieChart(this.element)
    const formatter = new google.visualization.NumberFormat(this.constructor.formatterOptions)
    formatter.format(visualizationData, 1)
    chart.draw(visualizationData, this.constructor.options)
  }

  static get options () {
    return {
      titlePosition: 'none',
      pieHole: 0.3,
      colors: ['#6D092A', '#E8A23B', '#FF7B44'],
      backgroundColor: 'transparent',
      legend: { position: 'right', alignment: 'center' },
      fontName: 'Avenir Next'
    }
  }

  static get formatterOptions () {
    return {
      suffix: ' €',
      decimalSymbol: ',',
      groupingSymbol: ' '
    }
  }
}
