import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'container',
    'submit',
    'textField',
    'attachment',
    'fileUploaded',
    'messages',
    'templateSelect',
    'errorMessage'
  ]

  connect () {
    if (this.hasTemplateSelectTarget) {
      $(this.templateSelectTarget).select2({
        placeholder: 'Modèles de messages',
        width: '100%',
        allowClear: true,
        language: {
          noResults: () => 'Aucun résultat'
        }
      }).on('change', this.handleTemplateChange.bind(this))
    }

    this.textFieldTarget.addEventListener('input', this.disableSubmit.bind(this))
    this.formTarget.addEventListener('submit', this.handleSubmit.bind(this))
    this.disableSubmit()
  }

  reset () {
    this.formTarget.reset()
    this.textFieldTarget.value = ''
    this.hideErrorMessage()
    this.disableSubmit()
  }

  handleTemplateChange (event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const templateContent = selectedOption.dataset.content || ''
    this.textFieldTarget.value = templateContent
    this.disableSubmit()
  }

  disableSubmit () {
    const isEmpty = this.textFieldTarget.value.trim() === ''
    this.submitTarget.disabled = isEmpty

    if (isEmpty) {
      this.showErrorMessage()
    } else {
      this.hideErrorMessage()
    }
  }

  showErrorMessage () {
    this.errorMessageTarget.style.display = 'block'
    this.submitTarget.disabled = true
  }

  hideErrorMessage () {
    this.errorMessageTarget.style.display = 'none'
    this.submitTarget.disabled = false
  }

  handleSubmit (event) {
    if (this.textFieldTarget.value.trim() === '') {
      event.preventDefault()
      this.showErrorMessage()
    }
  }

  updateFile (event) {
    const fileName = event.target.files[0].name
    this.attachmentTarget.insertAdjacentHTML(
      'beforebegin',
      `<i class="fa fa-file me-3 tooltip-tag" title="${fileName}" data-feed-messages--component-target="fileUploaded"></i>`
    )
    this.attachmentTarget.classList.add('d-none')
  }
}
