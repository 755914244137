import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static values = { refreshInterval: Number, url: String, needRefresh: Boolean }
  static targets = ['operationStatusTag', 'documentsAlert']

  connect () {
    if (this.hasRefreshIntervalValue && this.hasNeedRefreshValue && this.needRefreshValue) {
      this.#startRefreshing()
    }
  }

  #startRefreshing () {
    setInterval(() => {
      this.#load()
    }, this.refreshIntervalValue)
  }

  async #load () {
    const employeeSickWorkStop = await this.#getEmployeeSickWorkStop()

    if (employeeSickWorkStop.status === 'success') {
      this.#updateStatusTag(employeeSickWorkStop)
      this.#updateSendButton(employeeSickWorkStop)
      this.#updateDocumentAlert(employeeSickWorkStop)
    }
  }

  async #getEmployeeSickWorkStop () {
    const response = await getQuery(this.urlValue)
    return response
  }

  #updateStatusTag (employeeSickWorkStop) {
    if (employeeSickWorkStop.operation_status === 'sent') {
      this.#updateStatusTagSuccess(employeeSickWorkStop.operation_status_human)
    } else if (['dispatch_in_preparation', 'prolongation_in_preparation'].includes(employeeSickWorkStop.operation_status)) {
      this.#updateStatusTagDefault("En cours d'envoi")
    } else if (employeeSickWorkStop.operation_status === 'complete') {
      this.#updateStatusTagSuccessLight(employeeSickWorkStop.operation_status_human)
    } else if (employeeSickWorkStop.operation_status === 'incomplete') {
      this.#updateStatusTagDanger(employeeSickWorkStop.operation_status_human)
    }
  }

  #updateSendButton (employeeSickWorkStop) {
    if (employeeSickWorkStop.can_send_email) {
      this.sendButton.classList.remove('d-none')
      this.sendButtonDisabled.classList.add('d-none')
    } else {
      this.sendButton.classList.add('d-none')
      this.sendButtonDisabled.classList.remove('d-none')
    }
  }

  #updateStatusTagSuccessLight (content) {
    this.#removeAllStatusClassColor()
    this.operationStatusTagTarget.firstElementChild.classList.add('bg-success-10', 'text-success')
    this.operationStatusTagTarget.firstElementChild.innerHTML = content
  }

  #updateStatusTagSuccess (content) {
    this.#removeAllStatusClassColor()
    this.operationStatusTagTarget.firstElementChild.classList.add('bg-success', 'text-white')
    this.operationStatusTagTarget.firstElementChild.innerHTML = content
  }

  #updateStatusTagDanger (content) {
    this.#removeAllStatusClassColor()
    this.operationStatusTagTarget.firstElementChild.classList.add('bg-danger-10', 'text-danger')
    this.operationStatusTagTarget.firstElementChild.innerHTML = content
  }

  #updateStatusTagDefault (content) {
    this.#removeAllStatusClassColor()
    this.operationStatusTagTarget.firstElementChild.classList.add('bg-dark-5', 'text-dark')
    this.operationStatusTagTarget.firstElementChild.innerHTML = content
  }

  #removeAllStatusClassColor () {
    this.operationStatusTagTarget.firstElementChild.classList.remove('bg-danger-10', 'text-danger', 'bg-success-10', 'text-success', 'bg-success', 'text-white', 'bg-dark-5', 'text-dark')
  }

  #updateDocumentAlert (employeeSickWorkStop) {
    this.alertContent.innerHTML = employeeSickWorkStop.alert_message
    this.#updateAlertIcon(employeeSickWorkStop.alert_status)
    this.#updateAlertBackground(employeeSickWorkStop.alert_status)
  }

  #updateAlertBackground (alertStatus) {
    this.alertCard.classList.remove('alert-danger', 'alert-success', 'bg-warning-20', 'bg-dark-5')

    switch (alertStatus) {
      case 'incomplete':
        this.alertCard.classList.add('bg-warning-20')
        break
      case 'penality':
        this.alertCard.classList.add('alert-danger')
        break
      case 'sent':
      case 'prolonged':
      case 'complete':
        this.alertCard.classList.add('alert-success')
        break
      default:
        this.alertCard.classList.add('bg-dark-5')
    }
  }

  #updateAlertIcon (alertStatus) {
    switch (alertStatus) {
      case 'penality':
        this.alertIcon.className = 'fa-solid fa-triangle-exclamation text-danger me-3'
        break
      case 'incomplete':
        this.alertIcon.className = 'fa-solid fa-triangle-exclamation text-warning me-3'
        break
      case 'sent':
      case 'prolonged':
      case 'complete':
        this.alertIcon.className = 'fa-solid fa-circle-check text-success me-3'
        break
      case 'dispatch_in_preparation':
      case 'prolongation_in_preparation':
        this.alertIcon.className = 'fa-solid fa-spinner fa-spin text-dark-40 me-3'
        break
      default:
        this.alertIcon.className = 'fa-solid fa-clock text-dark-40 me-3'
    }
  }

  get alertCard () {
    return this.documentsAlertTarget.querySelector('.alert')
  }

  get alertIcon () {
    return this.documentsAlertTarget.getElementsByTagName('i')[0]
  }

  get alertContent () {
    return this.documentsAlertTarget.querySelector('.alert-content')
  }

  get sendButton () {
    return document.getElementById('send_button')
  }

  get sendButtonDisabled () {
    return document.getElementById('send_button_disabled')
  }
}
