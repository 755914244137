import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  connect () {
    const fileField = this.formTarget.querySelector('.dropzone-input')
    fileField.setAttribute('name', 'individual_internal_contract[attachment_documents_attributes][1][new_small_image]')
    fileField.setAttribute('id', 'individual_internal_contract_attachment_documents_attributes_1_new_small_image')

    const docTypeField = this.formTarget.querySelector('.doc-type-input')
    docTypeField.setAttribute('name', 'individual_internal_contract[attachment_documents_attributes][1][document_type]')
    docTypeField.setAttribute('id', 'individual_internal_contract_attachment_documents_attributes_1_document_type')
  }
}
