import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link']

  connect () {
    const pathname = window.location.pathname

    const element = this.element.querySelector(`a[href="${pathname}"]`)

    $(element).parent().siblings().removeClass('AppLayout__AdministrationSidebar__Link--active')
    $(element).parent().siblings().find('.AppLayout__AdministrationSidebar__Link').removeClass('AppLayout__AdministrationSidebar__Link--active')
    $(element).parent().addClass('AppLayout__AdministrationSidebar__Link--active')
    $(element).parents('.AppLayout__AdministrationSidebar__Link').addClass('AppLayout__AdministrationSidebar__Link--active')
  }
}
