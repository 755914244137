import { Controller } from 'stimulus'
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static targets = ['slide', 'caroussel', 'splidetrack']
  static values = {
    padding: Object,
    margin: Object,
    type: String,
    perPage: Number,
    trackClasses: String
  }

  connect () {
    const options = {
      speed: 900,
      padding: this.paddingValue,
      margin: this.marginValue,
      type: this.typeValue,
      perPage: this.perPageValue,
      classes: {
        pagination: 'splide__pagination d-none',
        page: 'splide__pagination__page'
      }
    }

    if (this.slideTargets.length === 1) {
      options.arrows = false
    } else {
      options.arrows = true
    }
    this.splide = new Splide(this.element, options)

    this.splide.mount()
    this.handleDisplay()
  }

  async handleClick (e) {
    const bannerElement = e.target.closest('.BannerAnnouncement')
    const slideElement = e.target.closest('.splide__slide')

    slideElement.classList.add('d-none')
    bannerElement.remove()
    this.splide.go('>')
    if (this.slideTargets.length === 0) {
      this.carousselTarget.classList.add('d-none')
    }
    this.handleDisplay()
    try {
      await patchQuery('/api/v1/hidden_banners/update', JSON.stringify({
        hidden_banner_id: bannerElement.dataset.bannerId,
        user_type: bannerElement.dataset.userType
      }))
    } catch (e) {
      toastr.error('Mise à jour impossible')
    }
  }

  handleDisplay () {
    const slideElements = this.slideTargets
    if (slideElements.length === 1) {
      const arrows = document.querySelectorAll('.splide__arrow')
      arrows.forEach(arrow => arrow.classList.add('d-none'))
      const splide = this.splidetrackTarget
      splide.style.paddingLeft = '0'
      splide.style.paddingRight = '0'
      splide.style.marginLeft = '0'
      splide.style.marginRight = '0'
    } else {
      if (this.trackClassesValue) { this.splidetrackTarget.classList.add(this.trackClassesValue) }
    }
  }
}
