import { Controller } from 'stimulus'
import Highcharts from 'highcharts/highmaps.js'
import HighchartsMore from 'highcharts/highcharts-more.src'

export default class extends Controller {
  static values = {
    containerId: String,
    series: Array
  }

  connect () {
    HighchartsMore(Highcharts)

    Highcharts.chart(this.containerIdValue, {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%'
      },

      title: {
        text: ''
      },

      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },

      // the value axis
      yAxis: {
        min: 0,
        max: Math.max(140, ...this.seriesValue),
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          distance: 20,
          style: {
            fontSize: '14px'
          }
        },
        lineWidth: 0,
        plotBands: [{
          from: 0,
          className: 'test',
          to: 95,
          color: '#3AC26E',
          thickness: 60
        }, {
          from: 95,
          to: 120,
          color: '#FFA656',
          thickness: 60
        }, {
          from: 120,
          to: Math.max(140, ...this.seriesValue),
          color: '#FB6161',
          thickness: 60
        }]
      },
      series: [{
        name: 'S/P',
        data: this.seriesValue,
        tooltip: {
          enabled: false
        },
        dataLabels: {
          format: '{y}% de votre S/P consommé',
          borderWidth: 0,
          color: (
            Highcharts.defaultOptions.title &&
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || '#333333',
          style: {
            fontSize: '16px'
          }
        },
        dial: {
          radius: '70%',
          backgroundColor: '#130F41',
          baseWidth: 12,
          baseLength: '20%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: '#130F41',
          radius: 6
        }
      }],
      credits: {
        enabled: false
      }
    })
  }
}
