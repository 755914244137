import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']

  connect () {
    $(this.selectTarget).multiselect({
      includeSelectAllOption: true,
      selectAllText: 'Toutes les entreprises',
      includeFilterClearBtn: false,
      dropUp: false,
      nonSelectedText: '',
      widthSynchronizationMode: 'always',
      buttonWidth: '100%',
      buttonHeight: '100%',
      maxHeight: 200,
      nSelectedText: 'entreprises',
      allSelectedText: 'Toutes',
      numberDisplayed: 1
    })
  }
}
