import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const modalElement = document.querySelector('#modal-welcome-password-company-administrator')
    const modal = bootstrap.Modal.getOrCreateInstance(modalElement, { backdrop: 'static' }) // eslint-disable-line
    modal.show()
  }

  passwordVisualize (_event) {
    const passwordInput = document.getElementById('company_administrator_password')

    if (passwordInput.type === 'password') {
      passwordInput.setAttribute('type', 'text')
    } else {
      passwordInput.setAttribute('type', 'password')
    }
  }
}
