import jsxElem from 'jsx-no-react' // eslint-disable-line
import trash from 'images/icons/icone_supprimer.svg'
import moment from 'moment'
import { csrfToken } from '@rails/ujs'
import { dateDisplayCalendar } from 'src/js/templates/shared/dateDisplayCalendar.jsx'

function deleteForm (data) {
  if (!data.in_the_future || !data.validation_waiting_for_approval) return

  return (
    <form className="edit_employee_absence_event" id={`edit_employee_absence_event_${data.id}`} action={`/espace-salarie/conges-et-absences/${data.id}`} acceptCharset="UTF-8" method="post">
      <input type="hidden" name="_method" value="patch" autoComplete="off" />
      <input type="hidden" name="authenticity_token" value={csrfToken()} autoComplete="off"/>
      <input type="hidden" name="redirection_link" id="redirection_link" value="/espace-salarie/conges-et-absences" autoComplete="off" />
      <input value="canceled" id={`employee_absence_event_${data.id}[validation_state]`} autoComplete="off" type="hidden" name="employee_absence_event[validation_state]"/>
      {data.is_active &&
      <button name="button" type="submit" className="no-border background-transparent">
        <p className="color-red mt-3 mb-0 text-17 text-underline align-middle">
          <img className="me-2" alt="Icône de suppression" src={trash}/> Annuler la demande
        </p>
      </button>
      }
    </form>
  )
}

function editLink (data) {
  if (!data.employee.is_active) return
  if (!data.in_the_future || !data.validation_waiting_for_approval) return

  return (
    <a className="color-secondary cursor-pointer" style="position: absolute; right: 20px; top: 20px;" data-remote="true" href={data.edit_link}>
      <i className="fa-light fa-edit"></i>
    </a>
  )
}

function itemTemplate (data) {
  moment.locale('fr')
  let durationCount

  if (data.get_duration > 1) {
    durationCount = `${data.get_duration} Jours`
  } else {
    durationCount = `${data.get_duration} Jour`
  }

  return (
    <li className="EmployeeAbsenceEvents_Card no-list-style border-radius-5 px-3 py-2 mb-2 cursor-pointer hover-color" data-employee-absence-events--employee-administration--show-target="listItem" data-action="click->employee-absence-events--employee-administration--show#handleClick" data-url={`/espace-salarie/conges-et-absences/${data.id}`} data-id={data.id}>
      <p className="mb-0 color-secondary">{data.employee_absence_event_category.name} - {durationCount}</p>
      <p className="mb-0 text-muted">
        Du {moment(data.start_datetime).format('D MMM YYYY')} au {moment(data.end_datetime).format('D MMM YYYY')}
      </p>
      <p className="mb-0 small text-muted">
        <span className={`point-before ${`point-color-${data.colors_validation_state}`} ms-4`}>
          {data.validation_state_label}
        </span>
      </p>
    </li>
  )
}

function detailTemplate (data) {
  moment.locale('fr')
  const isAWorkingTimeReductionAbsence = data.employee_absence_event_category.name === 'RTT'
  return (
    <div>
      {editLink(data)}

      <p className="text-muted small">Demande créée le {moment(data.created_at).format('DD/MM/YYYY')}
        {data.validation_state_human &&
          <>
            <span> - Solde de {isAWorkingTimeReductionAbsence ? 'RTT' : 'congés payés'}</span> :
            <span className={isAWorkingTimeReductionAbsence ? data.employee_presenter.working_time_reduction_balance_colored : data.employee_presenter.absence_balance_colored}>
              &nbsp;{isAWorkingTimeReductionAbsence ? data.employee_presenter.working_time_reduction_balance_formated : data.employee_presenter.absence_balance_formated}
            </span>
          </>
        }
      </p>

      <div className="d-flex align-items-center">
        {dateDisplayCalendar(data.start_datetime, data.half_day_start)}
        <div className="mx-4">
          <i className="fas fa-2x fa-chevron-right"></i>
        </div>
        {dateDisplayCalendar(data.end_datetime, data.half_day_end)}
      </div>

      <p className="text-dark-purple text-16 mt-4 mb-2">
        <span className="color-secondary">Description : </span>{data.comment_description.length > 0 ? data.comment_description : 'Non renseigné' }
      </p>

      {data.last_validation_event &&
      <p className="text-dark-purple text-16 mt-4 mb-2">
        <span className="color-secondary">Commentaire manager</span> : {data.last_validation_events.comment_description.length === 0 ? 'Non renseigné' : data.last_validations_event.comment_description}
      </p>
      }

      {data.attachment_documents.length > 0 &&
        <p className="color-secondary text-16 mt-3 mb-2">
          Justificatif :
          <ul className="colored-bullet list-unstyled ps-4">
            {data.attachment_documents.map(item =>
            <li key={item.small_image_url} className="bullet-cyan color-cyan">
              <a className="color-text ps-2" target="_blank" href={item.small_image_url} rel="noreferrer">
                <span>{item.name}</span>
              </a>
            </li>
            )}
          </ul>
        </p>
      }

      {deleteForm(data)}
    </div>
  )
}

export { detailTemplate, itemTemplate }
