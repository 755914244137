import { Controller } from 'stimulus'
export default class extends Controller {
  connect () {
    this.init()
  }

  init () {
    $('.daterangepickerjs').daterangepicker({
      autoUpdateInput: false,
      autoApply: true,
      locale: {
        format: 'DD/MM/YYYY'
      }
    })
    $('.daterangepickerjs').on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'))
    })
    $('.daterangepickerjs').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('')
    })
  }
}
