import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'

export default class extends Controller {
  static values = {
    employeesUrl: String,
    providentDay: String
  }

  static targets = ['employeeId', 'userProId', 'lastEventEndDateForm', 'form', 'lastEventStartDateForm', 'startMorning', 'startAfternoon', 'endMorning', 'endAfternoon']

  changeUserPro (event) {
    this.#getEmployees()
  }

  buttonSubmitNotEligible (event) {
    if (this.providentDay <= this.lastEventEndDate) {
      this.#openModal('alert_employee_sick_work_stops_enable')
    } else {
      this.#openModal('alert_employee_sick_work_stops_remember')
    }
  }

  buttonSubmitEligible (event) {
    if (this.providentDay > this.lastEventEndDate) {
      this.#openModal('alert_employee_sick_work_stops_disable')
    } else {
      this.formTarget.submit()
    }
  }

  async #getEmployees () {
    const response = await getQuery(`${this.employeesUrlValue}?user_pro=${this.userProIdTarget.value}`)
    this.#updateEmployeeOptions(response)
  }

  #updateEmployeeOptions (employees) {
    const select = this.employeeIdTarget
    this.#removeAllOptions(select)

    for (let i = 0, j = employees.length; i < j; i++) {
      const employee = employees[i]
      const text = `${employee.first_name} ${employee.last_name}`
      const value = employee.id
      this.#addOptions(select, text, value)
    }
  }

  get providentDay () {
    const providentDayValue = this.providentDayValue

    return this.#setDate(providentDayValue)
  }

  get lastEventEndDate () {
    const lastValue = this.lastEventEndDateFormTarget.value

    return this.#setDate(lastValue)
  }

  get lastEventStartDate () {
    const lastValue = this.lastEventStartDateFormTarget.value

    return this.#setDate(lastValue)
  }

  #openModal (id) {
    this.#setEndTimeModal()
    this.#setStartTimeModal()
    const myModal = new bootstrap.Modal(`#${id}`) // eslint-disable-line
    myModal.show()
  }

  #setStartTimeModal () {
    this.#setTimeModal('startTime', this.lastEventStartDateFormTarget.value, this.startMorningTarget.checked)
  }

  #setEndTimeModal () {
    this.#setTimeModal('endTime', this.lastEventEndDateFormTarget.value, this.endMorningTarget.checked)
  }

  #setTimeModal (id, dateString, isMorning) {
    const timeId = document.getElementById(id)
    timeId.innerHTML = this.#fullDateString(dateString, isMorning)
  }

  #fullDateString (dateString, isMorning) {
    const [year, month, day] = dateString.split('-')

    return `${day}/${month}/${year} ${this.#meridienLocal(isMorning)}`
  }

  #meridienLocal (isMorning) {
    if (isMorning) {
      return 'Matin'
    } else {
      return 'Après-midi'
    }
  }

  #addOptions (select, text, value) {
    const newOption = new Option(text, value)
    select.add(newOption, undefined)
  }

  #removeAllOptions (select) {
    while (select.options.length > 0) {
      select.remove(0)
    }
  }

  #setDate (dateString) {
    const [year, month, day] = dateString.split('-')

    const d = new Date()
    d.setFullYear(year, month, day)

    return d
  }
}
