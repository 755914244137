import { Controller } from 'stimulus'
import { patchQuery } from 'helpers/requests'

export default class extends Controller {
  static values = {
    url: String
  }

  async updateWorkingDays (e) {
    try {
      const target = e.currentTarget
      const res = await patchQuery(this.urlValue, JSON.stringify({
        employee: {
          [target.dataset.value]: target.classList.value.includes('active') ? 0 : 1
        }
      }))

      if (res) {
        this.changeEmployeeBubbleColor(target)
      } else {
        throw new Error('update failed')
      }
    } catch (e) {
      toastr.error('Mise à jour impossible')
      throw new Error(e)
    }
  }

  changeEmployeeBubbleColor (target) {
    target.classList.toggle('active')
  }
}
