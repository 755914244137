const PIE_OPTIONS = {
  height: 300,
  titlePosition: 'none',
  colors: [
    '#FF7B44',
    '#6D092A',
    '#FFE7BF',
    '#E8A23B',
    '#00C18D',
    '#27C2D1',
    '#2E047E',
    '#F6C357',
    '#FF7227',
    '#130F41',
    '#FF4141',
    '#707070',
    '#F27FCB',
    '#8F75D6',
    '#ff7e67',
    '#4393BB',
    '#35495e'
  ],
  backgroundColor: 'transparent',
  legend: { position: 'bottom' },
  chartArea: { width: '100%' },
  fontName: 'Avenir Next',
  sliceVisibilityThreshold: 0
}

const LINE_OPTIONS = {
  colors: ['#FF7B44'],
  legend: { position: 'none' },
  background: 'transparent',
  fontSize: 10,
  vAxis: {
    title: 'Salariés'
  },
  height: 300
}

const DEPARTURES_AND_ARRIVALS_PER_MONTH_OPTIONS = {
  height: 300,
  fontSize: 10,
  background: 'transparent',
  colors: [
    '#FF7B44',
    '#6D092A'
  ],
  vAxis: {
    title: "Nombre d'employés"
  }
}

export { PIE_OPTIONS, LINE_OPTIONS, DEPARTURES_AND_ARRIVALS_PER_MONTH_OPTIONS }
