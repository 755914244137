import { Controller } from 'stimulus'
import YouTubePlayer from 'youtube-player'

export default class extends Controller {
  static targets = ['modal', 'player']
  static values = {
    videoId: String,
    playerId: String
  }

  connect () {
    this.player = YouTubePlayer(this.playerIdValue, {
      height: '315',
      width: '560',
      videoId: this.videoIdValue
    })

    this.modalTarget.addEventListener('hide.bs.modal', event => {
      this.player.pauseVideo()
      this.player.seekTo(0, false)
    })
  }

  openModal () {
    const modal = bootstrap.Modal.getOrCreateInstance(this.modalTarget, { keyboard: true }) // eslint-disable-line
    modal.show()

    // Play Video Player
    this.player.playVideo()
  }
}
