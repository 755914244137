import { Controller } from 'stimulus'
import Shepherd from 'shepherd.js'
import { offset } from '@floating-ui/dom'

import 'shepherd.js/dist/css/shepherd.css'

export class BaseController extends Controller {
  static values = {
    enable: Boolean,
    type: String,
    nextPageUrl: String
  }

  // interface, descendents classes need to implement this
  generateSteps (tour) {
    throw new Error('Not Implemented Error')
  }

  handleHideTour () {
  }

  handleCompleteTour () {
  }

  connect () {
    if (!this.enableValue) return

    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'ProductTour shadow-md',
        cancelIcon: {
          enabled: true
        }
      }
    })

    const steps = this.generateSteps(tour)

    steps.forEach(step => {
      tour.addStep({
        ...this.floatingUiOffset(),
        ...this.overlayConfig(),
        ...step
      })
    })

    tour.start()

    tour.on('cancel', () => {
      this.handleHideTour()
    })

    tour.on('complete', () => {
      this.handleCompleteTour()
    })
  }

  nextButtonClasses () {
    return 'btn btn-primary btn-sm'
  }

  // add padding and border radius on square hghtlight target
  overlayConfig () {
    return {
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 4
    }
  }

  // the number on the step
  generateStepNumber (step, total) {
    return `<span class="shepherd-steps mb-2"><span class="text-success text-16">•</span> ${step}/${total}</span>`
  }

  // Add offset with the target element
  floatingUiOffset () {
    return {
      floatingUIOptions: {
        middleware: [offset(20)]
      }
    }
  }
}
